import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { BRAND_URLS } from "../../constants";
import { toast } from 'sonner';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};



export const brandListAPI = createAsyncThunk("brandListAPI", async ({page , search}) => {
  try {
    const response = await instance.get(`${BRAND_URLS.LIST(page , search)}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const brandListDeleteAPI = createAsyncThunk(
  "brandListDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${BRAND_URLS.DELETE(data.id)}`);
      toast.success('Brand deleted successfully!');
      return response.data;
    } catch (error) {
      console.log("Error", error);
      toast.error('Failed to delete Brand.');
      return error;
    }
  }
);


export const brandGetByIdAPI = createAsyncThunk(
  "brandGetByIdAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(BRAND_URLS.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);



export const brandUpdateAPI = createAsyncThunk(
  "brandUpdateAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        BRAND_URLS.UPDATE(data.id),
        data.formData
      );
      toast.success('Brand updated successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to update brand.');
      return rejectWithValue(err);
    }
  }
);


export const brandCreateAPI = createAsyncThunk(
  "brandCreateAPI",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${BRAND_URLS.CREATE}`, formdata);
      // console.log(response);
      toast.success('Brand created successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to create Brand.');
      return rejectWithValue(err);
    }
  }
);


const brandList = createSlice({
  name: "brandList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state?.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active : action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(brandListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(brandListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
    });
    
    builder.addCase(brandListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { updateStatus } = brandList.actions;
export default brandList.reducer;
