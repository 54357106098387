import {
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  Switch,
} from "@mui/material";
import React, { useEffect } from "react";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { styles } from "../Create/styles";
import { styled } from "@mui/system";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ageGroupCreateFormSchema } from "../../../../Schemas";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  ageGroupEditAPI,
  ageGroupGetById,
} from "../../../../Redux/slice/ageGroupList";

const RequiredIndicator = styled("span")({
  color: "red",
  marginRight: "2px",
});
const AgeGroupEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const initialValues = {
    label: "",
    description: "",
    min: 0,
    max: 0,
    priority: 0,
    active: true,
  };

  const handleSubmit = async (values) => {
    try {
      const finalResponse = await dispatch(
        ageGroupEditAPI({
          id: searchParams.get("id"),
          formdata: values,
        })
      );
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(
    initialValues,
    ageGroupCreateFormSchema,
    handleSubmit
  );

  const formFields = [
    {
      label: "Label",
      placeHolder: "Enter Your Label",
      name: "label",
      type: "text",
    },
    {
      label: "Description",
      placeHolder: "Age Description",
      name: "description",
      type: "text",
    },
    {
      label: "minimum age",
      placeHolder: "minimum age",
      name: "min",
      type: "number",
    },
    {
      label: "maximum age",
      placeHolder: "maximum age",
      name: "max",
      type: "number",
    },
    {
      label: "Priority",
      placeHolder: "eg. 123",
      name: "priority",
      type: "number",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(
        ageGroupGetById({ id: searchParams.get("id") })
      );
      data = data?.payload?.data?.data;
      formik.setValues({
        label: data?.label || "",
        description: data?.description || "",
        min: data?.min || 0,
        max: data?.max || 0,
        priority: data?.priority || 0,
      });
    };

    fetchData();
  }, [dispatch, searchParams]);

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Edit Age Group</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
              i
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.editGenderFormMainBox}>
        {formFields.map((field, index) => {
          return (
            <Box key={index} sx={{ marginTop: "30px" }}>
              <Typography sx={styles.editGenderFormLabel}>
                <RequiredIndicator>*</RequiredIndicator>
                {field.label}
              </Typography>
              <TextField
                sx={styles.editGenderFormTextField}
                placeholder={field.placeHolder}
                name={field.name}
                type={field.type}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              />
              {formik.errors[field.name] && formik.touched[field.name] ? (
                <Typography
                  sx={{ color: "red", fontSize: "12px", paddingX: "2px" }}
                >
                  {formik.errors[field.name]}
                </Typography>
              ) : null}
            </Box>
          );
        })}

        <Box sx={styles.editGenderFormButtonMainBox}>
          <Button
            variant="contained"
            sx={styles.editGenderFormUpdateButton}
            onClick={formik.handleSubmit}
          >
            <BorderColorOutlinedIcon
              sx={styles.editGenderFormUpdateButtonIcon}
            />
            Update
          </Button>
          <Button
            variant="contained"
            sx={styles.editGenderFormResetButton}
            onClick={() => console.log("Reset clicked")}
          >
            <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
            Reset
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AgeGroupEditPage;
