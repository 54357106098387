export const initialEditProfileFormikValues = {
    name: "",
    fullName: "",
    phoneNumber: "",
    email: "",
    description: "",
    sports: [
        {
            id: null,
            label: "",
        },
    ],
    addresses: [
        {
            address: {
                _id: "",
                address: "",
                pinCode: "",
                city: "",
            },
            _id: "",
            coachingPeriods: {
                days: ["All Days"],
                weeks: ["All Weeks"],
                months: ["All Months"]
            },
            coachingType: "",
            timeSlots: [],
            ageGroup: [],
            genderYouTeach: [],
        },
    ],
    groupCoachingPriceRange: {
        min: null,
        max: null,
    },
    privateCoachingPriceRange: {
        min: null,
        max: null,
    },
    coaches: [
        {
            id: null,
            label: "",
        },
    ],
};