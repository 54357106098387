"use client";
import { Box } from "@mui/material";
import React from "react";
import Banner from "../../../Assets/Images/AcademyProfileImage.jpeg";
import CoachDescription from "../../../Components/CoachProfileComponents/coachDescription";
import AvailableAt from "../../../Components/CoachProfileComponents/availableAt";
import Timings from "../../../Components/CoachProfileComponents/timings";
import AvailableSports from "../../../Components/CoachProfileComponents/availableSports";
import Pricing from "../../../Components/CoachProfileComponents/pricing";
import Academies from "../../../Components/CoachProfileComponents/academies";
import Faqs from "../../../Components/CoachProfileComponents/faqs";
import Testimonials from "../../../Components/AcademyProfileComponents/testimonials";
import LastBottom from "../../../Components/CoachProfileComponents/lastBottom";
function ProfileWeb() {
  return (
    <Box bgcolor={"#fff"}>
      <Box sx={{ width: "100%" }}>
        <img
          fill
          src={Banner}
          alt="banner"
          style={{ objectFit: "cover", width: "100%", height: "541x" }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ marginTop: "55px" }}>
          <LastBottom />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "69%",
            marginTop: "46px",
          }}
        >
          <CoachDescription profileName={"Acadmy Name"} />
          <AvailableAt />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Timings />
            <AvailableSports />
          </Box>
          <Pricing />
          <Academies />
          <Faqs />
          <Testimonials />
        </Box>
      </Box>
    </Box>
  );
}

export default ProfileWeb;
