import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  ButtonGroup,
} from "@mui/material";
import { styles } from "./styles";
import CustomButtonComponent from "../../../../../Components/CustomButton";
import DownArrowIcon from "../../../../../Assets/Icons/Drawer/DownArrow.svg";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import AddIcon from "@mui/icons-material/Add";
import OnlinePayoutFormField from "./onlinePayoutFormField";
import CustomTimelineComponent from "../../../../../Components/CustomTimeline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { createPayoutAPI } from "../../../../../Redux/slice/createPayout";
import { useDispatch } from "react-redux";
import CustomFormikHook from "../../../../../Hooks/CustomFormikHook";
import { payoutFormSchema } from "../../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

const OnlinePayoutDetails = ({ handleNext }) => {
  const dispatch = useDispatch();
  const initialValues = {
    paymentType: "FLAT",
    currency: "INR",
    marketplacePayout: "",
    minOrderText: "",
    minOrderAmount: "",
  };
  

  const handleChange = () => {
    handleNext();
  };
  const formik = CustomFormikHook(initialValues, payoutFormSchema);

  

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await formik.validateForm();
      formik.handleSubmit();
      const finalResponse = await dispatch(createPayoutAPI(formik.values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
     
      <Box sx={styles.createFormBox}>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              width: "33%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "20px",
            }}
          >
            <OnlinePayoutFormField
              label="paymentType"
              TextFieldRequired={false}
            />
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Payment Type Group"
            >
              <Button sx={{ backgroundColor: "#3CB0AD", color: "#ffffff" }}>
                FLAT
              </Button>
              <Button sx={{ backgroundColor: "#ffffff", color: "#000000" }}>
                PERCENTAGE
              </Button>
            </ButtonGroup>
          </Box>
          <Box
            sx={{
              marginLeft: "20px",
              width: "33%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "20px",
            }}
          >
            <OnlinePayoutFormField label="Currency" TextFieldRequired={false} />
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Currency Group"
            >
              <Button sx={{ backgroundColor: "#3CB0AD", color: "#ffffff" }}>
                INR
              </Button>
              <Button sx={{ backgroundColor: "#ffffff", color: "#000000" }}>
                USD
              </Button>
            </ButtonGroup>
          </Box>
          <Box sx={{ marginLeft: "20px", width: "33%" }}>
            <OnlinePayoutFormField
              label="Marketplace Payout"
              name={"marketplacePayout"}
              placeholder={"Enter Market Place Payout"}
              handleChange={formik.handleChange}
              value={formik.values.marketplacePayout}
              errors={formik.errors.marketplacePayout}
              touched={formik.touched.marketplacePayout}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "50%" }}>
            <OnlinePayoutFormField
              label="Min. Order Text"
              name={"minOrderText"}
              placeholder={"Enter Market Place Payout"}
              handleChange={formik.handleChange}
              value={formik.values.minOrderText}
              errors={formik.errors.minOrderText}
              touched={formik.touched.minOrderText}
            />
          </Box>
          <Box sx={{ marginLeft: "20px", width: "50%" }}>
            <OnlinePayoutFormField
              label="Min. Order Amount"
              name={"minOrderAmount"}
              placeholder={"Enter Market Place Payout"}
              handleChange={formik.handleChange}
              value={formik.values.minOrderAmount}
              errors={formik.errors.minOrderAmount}
              touched={formik.touched.minOrderAmount}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", marginTop: "40px" }}
          >
            <CustomButtonComponent sx={styles.customButton1}>
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "500",
                  fontSize: 20,
                }}
              >
                Targeting <ArrowBackIosIcon sx={{ mr: "5px", fontSize: 13 }} />{" "}
                Prev
              </Typography>
            </CustomButtonComponent>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
              marginTop: "40px",
            }}
          >
            <CustomButtonComponent sx={styles.customButton2}>
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "500",
                  fontSize: 20,
                }}
                onClick={() => {
                  handleChange();
                }}
              >
                Next <ArrowForwardIosIcon sx={{ mr: "5px", fontSize: 13 }} />{" "}
                Tracking
              </Typography>
            </CustomButtonComponent>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{ display: "flex", alignItems: "center", marginTop: "40px" }}
          >
            <CustomButtonComponent
              variant="contained"
              sx={styles.formUpdateButton}
              onClick={handleSubmit}
            >
              <BorderColorOutlinedIcon sx={styles.formUpdateButtonIcon} />
              Update
            </CustomButtonComponent>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
              marginTop: "40px",
            }}
          >
            <CustomButtonComponent
              variant="contained"
              sx={styles.formUpdateButton}
            >
              <BorderColorOutlinedIcon sx={styles.formUpdateButtonIcon} />
              Update Url
            </CustomButtonComponent>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
              marginTop: "40px",
            }}
          >
            <CustomButtonComponent
              variant="contained"
              sx={styles.formResetButton}
             
            >
              <RefreshOutlinedIcon sx={styles.formResetButtonIcon} />
              Reset
            </CustomButtonComponent>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default OnlinePayoutDetails;
