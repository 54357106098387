import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { USER_URLS } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const usersListAPI = createAsyncThunk(
  "usersListAPI",
  async ({ page }) => {
    const response = await instance.get(USER_URLS.LIST(page));
    return response.data;
  }
);

export const usersSearchAPI = createAsyncThunk(
  "usersSearchAPI",
  async ({ search, page }) => {
    const response = await instance.get(USER_URLS.SEARCH(search, page));
    return response.data;
  }
);

const usersList = createSlice({
  name: "usersList",
  initialState,
  reducers: {
    deleteUser: (state, action) => {
      state.data.data = state.data.data.map((user) => {
        return user._id === action.payload.id ? { ...user, deleted: true, active: false } : user;
      });
    },
    updateUserStatus: (state, action) => {
      state.data.data = state.data.data.map((user) =>
        user._id === action.payload.id
          ? { ...user, active: action.payload.status }
          : user
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(usersListAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(usersListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      console.log(action.payload);
    });
    builder.addCase(usersListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    builder.addCase(usersSearchAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      console.log(action.payload);
    });
  },
});

export const { deleteUser, updateUserStatus } = usersList.actions;
export default usersList.reducer;
