import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TotalPagesThree from "./totalPagesThree";
import TotalPagesFour from "./totalPagesFour";
import TotalPagesMoreThanFour from "./totalPagesMoreThanFour";
import TotalPagesTwo from "./totalPagesTwo";
import Button from "@mui/material/Button";
import { useSearchParams } from "react-router-dom";

const CustomPagination = ({
  type,
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  const [visitedPages, setVisitedPages] = useState([currentPage]);
  const [urlSearchParams, setURLSearchParams] = useSearchParams();

  useEffect(() => {
    setCurrentPage(parseInt(urlSearchParams.get("page")) || 1);
  }, [urlSearchParams]);

  const setSearchParams = (page) => {
    const params = {};
    urlSearchParams.forEach((value, key) => {
      params[key] = value;
    });
    params.page = page;
    setURLSearchParams(params);
    console.log({ urlSearchParams: params });
  };

  const handlePrevPage = () => {
    if (currentPage === 1) return;
    const newPage = currentPage - 1;
    setCurrentPage(newPage);
    setSearchParams(newPage);
    if (!visitedPages.includes(newPage)) {
      setVisitedPages([...visitedPages, newPage]);
    }
  };

  const handleNextPage = () => {
    if (currentPage === totalPages) return;
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    setSearchParams(newPage);
    if (!visitedPages.includes(newPage)) {
      setVisitedPages([...visitedPages, newPage]);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSearchParams(page);
    if (!visitedPages.includes(page)) {
      setVisitedPages([...visitedPages, page]);
    }
  };

  const pageComponent = () => {
    switch (totalPages) {
      case 1:
        return (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {[1].map((page) => (
                <Button
                  key={page}
                  sx={{
                    color: currentPage === page ? "#4a00e0" : "#000",
                    border:
                      currentPage === page
                        ? "1px solid #4a00e0"
                        : "1px solid #d3d3d3",
                    fontFamily: "Avenir",
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    minWidth: "32px",
                    padding: "8px",
                    margin: "0 4px",
                    "&:hover": { border: "1px solid #4a00e0" },
                  }}
                >
                  {page}
                </Button>
              ))}
            </div>
          </>
        );
      case 2:
        return (
          <TotalPagesTwo
            currentPage={currentPage}
            visitedPages={visitedPages}
            handlePageChange={handlePageChange}
          />
        );
      case 3:
        return (
          <TotalPagesThree
            currentPage={currentPage}
            visitedPages={visitedPages}
            handlePageChange={handlePageChange}
          />
        );
      case 4:
        return (
          <TotalPagesFour
            currentPage={currentPage}
            visitedPages={visitedPages}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
          />
        );
      default:
        return (
          <TotalPagesMoreThanFour
            currentPage={currentPage}
            visitedPages={visitedPages}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
          />
        );
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IconButton
        onClick={handlePrevPage}
        disabled={currentPage === 1}
        sx={{
          color: currentPage === 1 ? "#d3d3d3" : "#000",
          backgroundColor: currentPage === 1 ? "#f0f0f0" : "transparent",
          borderRadius: "8px",
          padding: "4px",
          "&:hover": { backgroundColor: "#f0f0f0" },
        }}
      >
        <ArrowBackIcon />
      </IconButton>
      {pageComponent()}
      <IconButton
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        sx={{
          color: currentPage === totalPages ? "#d3d3d3" : "#000",
          backgroundColor:
            currentPage === totalPages ? "#f0f0f0" : "transparent",
          borderRadius: "8px",
          padding: "4px",
          "&:hover": { backgroundColor: "#f0f0f0" },
        }}
      >
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
};

export default CustomPagination;
