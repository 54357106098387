import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { ACADEMY_URLS } from "../../constants";


const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  coaches: [],
  superAdmin: null
};

export const acadmyAPI = createAsyncThunk("acadmyAPI", async ({ page }) => {
  try {
    const response = await instance.get(ACADEMY_URLS.LIST(page));
    return response.data;
  } catch (error) {
      console.log("Error", error);
      return error;
  }
});

export const getCoachesAPI = createAsyncThunk("getCoachesAPI", async (data) => {
  try {
   
  const response = await instance.get(ACADEMY_URLS.GET_COACHES(data.slug, data.id));
  // console.log(response.data);
  return response.data;
  } catch (error) {
    console.log("Error", error) ; 
    return error;
 }
  
});

export const acadmySearchAPI = createAsyncThunk("acadmySearchAPI", async ({ search, page }) => {
  try {
    const response = await instance.get(ACADEMY_URLS.SEARCH(search, page));
  return response.data;
  } catch (error) {
    console.log("Error", error) ; 
    return error;
  }
});

export const acadmyFilterAPI = createAsyncThunk("acadmyFilterAPI", async (data) => {
  try {
    const response = await instance.post(ACADEMY_URLS.FILTER , data) ;
    console.log("filter" , response.data);
    return response.data;
  
  } catch (error) {
    console.log("Error", error) ; 
    return error;
  }
});



export const academyStatusUpdateAPI = createAsyncThunk(
  "coachStatusUpdateAPI",
  async (data) => {
    try {
      const response = await instance.put(ACADEMY_URLS.STATUS(data.slug, data.id), data.body);
    return response.data;
    } catch (error) {
      console.log("Error", error) ; 
    return error;
    }
    
  }
)

export const academyDeleteAPI = createAsyncThunk(
  "coachStatusUpdateAPI",
  async (data) => {
    try {
      const response = await instance.delete(ACADEMY_URLS.DELETE(data.slug, data.id), data.body);
    return response.data;
    } catch (error) {
      console.log("Error", error) ; 
      return error;
    }
   
   
  }
)

export const changeSuperAdminRoleAPI = createAsyncThunk("changeSuperAdminRoleAPI", async (data) => {
  try {
    const response = await instance.put(ACADEMY_URLS.CHANGE_SUPER_ADMIN_ROLE(data.slug, data.id), data.body);
    return response.data;
  } catch (error) {
    console.log("Error", error) ; 
    return error;
  }
 
});

export const changeCoachRoleAPI = createAsyncThunk("changeCoachRoleAPI", async (data) => {
  try {
    const response = await instance.put(ACADEMY_URLS.CHANGE_COACH_ROLE(data.slug, data.id), data.body);
  return response.data;
  } catch (error) {
    console.log("Error", error) ; 
    return error;
  }
});



const acadmy = createSlice({

  name: "acadmy",
  initialState,
  reducers: {
    deleteAcademy: (state, action) => {
      console.log(action.payload.slug);
      let dataArray = [...state.data.data];
      // state.data.data = dataArray.filter((academy) => academy._slug !== action.payload.slug);
      state.data.data = dataArray.map((academy) =>
        academy.slug === action.payload.slug
          ? { ...academy, deleted: true, active: false }
          : academy
      );
    },
    updateAcademyStatus: (state, action) => {
      let dataArray = [...state.data.data];
      state.data.data = dataArray.map((academy) =>
        academy.slug === action.payload.slug
          ? { ...academy, active: action.payload.status }
          : academy
      );
    },
    changeCoachRole: (state, action) => {
      let dataArray = [...state.coaches];
      state.coaches = dataArray.map((c) =>
        c.coach._id === action.payload.id
          ? { ...c, isAdmin: action.payload.isAdmin }
          : c
      );
    },
    superAdminToCoachOrAdminRole: (state, action) => {
      state.coaches = [
        ...state.coaches,
        {
          coach: state.superAdmin,
          isAdmin: action.payload.isAdmin,
        },
      ]
      state.superAdmin = null;
    },
    toSuperAdminRole: (state, action) => {
      let dataArray = [...state.coaches];
      state.superAdmin = dataArray.find((c) => c.coach._id === action.payload.id).coach;
      state.coaches = dataArray.filter((c) => c.coach._id !== action.payload.id);
    }
  },

  extraReducers: (builder) => {
    builder.addCase(acadmyAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(acadmyAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      console.log(action.payload);

    });
    builder.addCase(acadmyAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    builder.addCase(acadmySearchAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getCoachesAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.coaches = action.payload?.data?.coaches;
      state.superAdmin = action.payload?.data?.superAdmin;
    });
    builder.addCase(acadmyFilterAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      console.log("request" , action.payload);
    });
  },
});

export const { deleteAcademy, updateAcademyStatus, changeCoachRole, superAdminToCoachOrAdminRole, toSuperAdminRole } = acadmy.actions;
export default acadmy.reducer;
