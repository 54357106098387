import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomPagination from "../../../../Components/CustomPagination";
import DataInsertIntoTable from "../../../../Components/CustomTableComponent/dataInsertIntoTable";
import { styles, tableHead } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../Assets/Icons/Drawer/editIcon.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  timeSlotDeleteAPI,
  timeSlotListAPI,
  updateStatus,
} from "../../../../Redux/slice/timeSlotList";
import { downloadCSV } from "../../../../utils/utils";
import { timeSlotStatusUpdate } from "../../../../Redux/slice/timeSlotList";

const TimeSlotListPage = () => {
  const [timeSlots, setTimeSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [keyOfSort, setKeyOfSort] = useState("priority");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const head = tableHead;
  const timeSlotData = useSelector((state) => state?.timeSlotList?.data);
  const handleExport = () => {
    const csvData = timeSlotData?.map((row) => ({
      id: row?._id,
      label: row?.label,
      slotfrom: row?.slot?.from,
      slotto: row?.slot?.to,
      createdAt: row?.createdAt,
      priority: row?.priority,
      updatedAt: row?.updatedAt,
    }));
    downloadCSV(csvData, "timeSlotData");
  };
  
  useEffect(() => {
    const handleStatus = (id) => (event) => {
      console.log(event?.target?.checked);
      dispatch(
        timeSlotStatusUpdate({ id, body: { active: event?.target?.checked } })
      );
      dispatch(updateStatus({ id, status: event?.target?.checked }));
    };
    if (timeSlotData) {
      let arr = timeSlotData?.map((timeSlot) => {
        return {
          actions: [
            {
              content: (
                <Switch
                  checked={timeSlot?.active}
                  onChange={handleStatus(timeSlot?._id)}
                />
              ),
            },
            {
              content: (
                <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />
              ),
            },
            { type: "ButtonWithImg", content: editIcon },
          ],
          id: [{ type: "text", content: timeSlot?._id }],
          label: [{ type: "text", content: timeSlot?.label }],
          slotfrom: [{ type: "text", content: timeSlot?.slot?.from }],
          slotto: [{ type: "text", content: timeSlot?.slot?.to }],
          priority: [{ type: "text", content: timeSlot?.priority }],
          createdAt: [{ type: "text", content: timeSlot?.createdAt }],
          updatedAt: [{ type: "text", content: timeSlot?.updatedAt }],
        };
      });
      setTimeSlots(arr);
      setLoading(false);
    }
  }, [timeSlotData]);
  useEffect(() => {
    dispatch(timeSlotListAPI());
  }, [dispatch]);
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (!timeSlots || timeSlots.length === 0) {
    return (
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "red",
          fontFamily: "Avenir",
          fontSize: "40px",
          alignItems: "center",
        }}
      >
        No data available
      </Typography>
    );
  }
  const sortedBody = timeSlots.slice().sort((a, b) => {
    const nameA = a[keyOfSort].content;
    const nameB = b[keyOfSort].content;

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const totalItems = sortedBody.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedBody.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = async () => {
    try {
      let arr1 = [...timeSlots];
      arr1 = arr1.filter(
        (Object) => !selectedItems.includes(String(Object?.id[0].content))
      );
      setTimeSlots(arr1);
      for (let id of selectedItems) {
        dispatch(timeSlotDeleteAPI({ id }));
      }
      selectedItems([]);
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>timeSlots</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox} onClick={handleExport}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.pagesDetailMainBox}>
          <Typography>
            <span style={{ color: "#171A1F", fontWeight: "700" }}>
              {timeSlots.length}
            </span>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>
              in total
            </span>
          </Typography>

          <Typography sx={{ marginLeft: "70px" }}>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
            <span style={styles.pagesDetailNumber}>{startIndex + 1}</span>
            <span style={styles.pagesDetailText}>to</span>
            <span style={styles.pagesDetailNumber}>
              {endIndex < totalItems ? endIndex : totalItems}
            </span>
            <span style={styles.pagesDetailText}>of</span>
            <span style={styles.pagesDetailNumber}>{timeSlots.length}</span>
            <span style={styles.pagesDetailText}>results</span>
          </Typography>
          <Box mt={2} textAlign="center" ml="auto">
            {selectedItems.length > 0 ? (
              <IconButton
                onClick={handleDelete}
                aria-label="delete"
                sx={{ color: "red" }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          pl: "6px",
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <DataInsertIntoTable
          checkData={selectedItems}
          setCheckData={setSelectedItems}
          headingOfTable={head}
          bodyOfTable={currentItems}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
          timeSlot={true}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>
    </>
  );
};

export default TimeSlotListPage;
