import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import { styles } from "../Create/styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomSizeSchema} from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import EditFormField from "../../CustomFormFields/EditFormField";
import { sizeGetByIdAPI, sizeUpdateAPI } from "../../../../Redux/slice/sizeEcom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { subCategoryAllAPI } from "../../../../Redux/slice/subCategoryEcomList";

const SizeEcomEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const sizeTypeOptions = ["Regular", "Wide", "Narrow", "Extra Wide"];
  const nameOptions = ["SMALL", "MEDIUM", "LARGE", "EXTRA LARGE", "XXL", "XXXL", "XXXXL", "XXXXXL"];

  const initialValues = {
    size: "",
    name: "",
    subcategoryId : "" ,
  };

  const subCatData = useSelector((state) => state.subCategoryList.data);

  const handleSubmit = async (values) => {
    try {
      const finalResponse = await dispatch(
        sizeUpdateAPI({
          id: searchParams.get("id"),
          formData: values,
        })
      );
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  // const handleChange = (event) => {
  //   setSubId(event.target.value);
  //   formik.setFieldValue("subCategoryId", event.target.value);
  // }; 

  const formik = CustomFormikHook(initialValues, ecomSizeSchema, handleSubmit);

  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(sizeGetByIdAPI({ id: searchParams.get("id") }));
      data = data?.payload?.data?.data;
      formik.setValues({
        sizeFor: data?.sizeFor || "",
        size: data?.size || "",
        sizeType: data?.sizeType || "",
        name: data?.name || "",
        subcategoryId: data?.subcategoryId || "",
      });
    };

    fetchData();
    dispatch(subCategoryAllAPI());
  }, [dispatch, searchParams]);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Edit Size</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>

      <Box>
            <Box sx={{ minWidth: 120, marginTop: "20px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">SubCategory</InputLabel>
                <Select
                name="subcategoryId"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formik.values.subcategoryId}
                  label="Sports Category"
                  onChange={formik.handleChange}
                >
                  {subCatData?.map((category) => (
                    <MenuItem key={category?._id} value={category?._id}>
                      {category?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

        <EditFormField
          label={"Name"}
          name="name"
          placeholder={"Enter name "}
          handleChange={formik.handleChange}
          value={formik.values.name}
          errors={formik.errors.name}
          touched={formik.touched.name}
        />

        <EditFormField
          label={"Size"}
          name="size"
          placeholder={"Enter Size"}
          handleChange={formik.handleChange}
          value={formik.values.size}
          errors={formik.errors.size}
          touched={formik.touched.size}
        />

        
{/* 
<Box>
<Box sx={{ minWidth: 120, marginTop: "20px" }}>        
<FormControl fullWidth>
  <InputLabel id="size-type-label">Size Type</InputLabel>
  <Select
    labelId="size-type-label"
    id="size-type"
    name="sizeType"
    value={formik.values.sizeType}
    onChange={formik.handleChange}
    error={formik.touched.sizeType && Boolean(formik.errors.sizeType)}
  >
    {sizeTypeOptions.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </Select>
  {formik.touched.sizeType && formik.errors.sizeType && (
    <Typography color="error">{formik.errors.sizeType}</Typography>
  )}
</FormControl>
  </Box>
</Box>


<Box>
<Box sx={{ minWidth: 120, marginTop: "20px" }}>
<FormControl fullWidth>
  <InputLabel id="name-label">Name</InputLabel>
  <Select
    labelId="name-label"
    id="name"
    name="name"
    value={formik.values.name}
    onChange={formik.handleChange}
    error={formik.touched.name && Boolean(formik.errors.name)}
  >
    {nameOptions.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </Select>
  {formik.touched.name && formik.errors.name && (
    <Typography color="error">{formik.errors.name}</Typography>
  )}
</FormControl>
</Box>
 </Box> */}


        <Box sx={{ marginTop: "38px" }}></Box> 

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Update
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default SizeEcomEditPage;
