import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import InputError from "./inputError";
import { genderListAPI } from "../../Redux/slice/genderList";
import { useDispatch } from "react-redux";

function SelectGenderUTeach({ formik, addressIndex }) {
  const [genderList, setGenderList] = useState([]);
  const dispatch = useDispatch();

  const isChecked = (genderId) => {
    return formik.values.addresses[addressIndex].genderYouTeach.includes(
      genderId
    );
  };

  const handleGenderGroup = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      const values = [
        ...formik.values.addresses[addressIndex].genderYouTeach,
        name,
      ];
      formik.setFieldValue(`addresses[${addressIndex}].genderYouTeach`, values);
    } else {
      const values = formik.values.addresses[addressIndex].genderYouTeach.filter(
        (gender) => name !== gender
      );
      formik.setFieldValue(`addresses[${addressIndex}].genderYouTeach`, values);
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await dispatch(genderListAPI()).unwrap();
        setGenderList(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [dispatch]);

  return (
    <Box sx={{ marginTop: "45px", marginBottom: "24px" }}>
      <Typography
        sx={{
          fontWeight: 600,
        }}
      >
        Select Gender you teach
      </Typography>
      <Typography sx={{ marginBottom: "24px" }}>
        *You can select multiple genders
      </Typography>
      <FormGroup>
        {genderList?.map((genderG, i) => (
          <FormControlLabel
            key={i}
            onChange={handleGenderGroup}
            label={genderG.name?.toLowerCase()}
            sx={{ textTransform: "capitalize" }}
            control={
              <Checkbox
                name={genderG._id}
                checked={isChecked(genderG._id)}
                sx={{
                  "&.Mui-checked": {
                    color: "#242424",
                  },
                }}
              />
            }
          />
        ))}
      </FormGroup>
      {(formik.touched.addresses?.length > 0 &&
        formik.errors.addresses?.length > 0 &&
        formik.touched.addresses[addressIndex]?.genderYouTeach &&
        formik.errors.addresses[addressIndex]?.genderYouTeach) ||
        (formik.values.addresses[addressIndex]?.genderYouTeach.length === 0 && (
          <InputError error={"* This field is mandatory"} />
        ))}
    </Box>
  );
}

export default SelectGenderUTeach;
