import React, { useEffect } from "react";
import { Box, Typography, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import { styles } from "./styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ReviewCreateFormSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { reviewUpdateAPI, reviewGetByIdAPI } from "../../../../Redux/slice/reviewEcom";
import EditFormField from "../../CustomFormFields/EditFormField";
import { productListAPI } from "../../../../Redux/slice/productEcom";
import UserSearchDropdown from "../Create/UserSearchDropdown"; // Ensure the correct import path

const ReviewEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const products = useSelector((state) => state.productList.data);

   
  
  const initialValues = {
    productId: "",
    userId: "",
    score: "",
    review: "",
  };

  useEffect(() => {
    dispatch(productListAPI());
  }, [dispatch]);

  useEffect(() => {
    const reviewId = searchParams.get("id");
    dispatch(reviewGetByIdAPI({ id: reviewId })).then((action) => {
      console.log(action.payload);
      if (action.payload) {

        formik.setValues({
          productId: action?.payload?.data?.data?.productId || "",
          userId: action?.payload?.data?.data?.userId|| "",
          score: action?.payload?.data?.data?.score || "",
          review: action?.payload?.data?.data?.review || "",
        });
      }
    });
  }, [dispatch, searchParams]);

  const handleSubmit = async (values) => {
    try {
      await dispatch(reviewUpdateAPI({ id: searchParams.get("id"), formdata: values }));
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ReviewCreateFormSchema, handleSubmit);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Edit Review</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
      <Box sx={{ width: "300px" }}>
      <Typography sx={{ fontSize: "14px", lineHeight: "24px" }}>
          Product <span style={{ color: "red" }}>*</span>
        </Typography>
      </Box>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Select
            labelId="product-select-label"
            id="product-select"
            name="productId"
            value={formik.values.productId}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.productId && formik.touched.productId)}
          >
            {products?.map((product) => (
              <MenuItem key={product._id} value={product._id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <UserSearchDropdown
          formik={formik}
        />

        <EditFormField
          label={"Score"}
          name="score"
          placeholder={"Enter score"}
          handleChange={formik.handleChange}
          value={formik.values.score}
          errors={formik.errors.score}
          touched={formik.touched.score}
        />

        <EditFormField
          label={"Review"}
          name="review"
          placeholder={"Enter review"}
          handleChange={formik.handleChange}
          value={formik.values.review}
          errors={formik.errors.review}
          touched={formik.touched.review}
        />

        <Box sx={{ marginTop: "38px" }}></Box>

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Update
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default ReviewEditPage;
