import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTH_URL, LOGIN_URL } from "../constants";
import instance from "../API/BaseUrl";
import { toast } from "sonner";

const initialState = {
  currentUser: null,
  loading: false,
  error: null,
};

export const loginAPI = createAsyncThunk(
  "login",
  async (credentials, { rejectWithValue }) => {
    credentials = {
      ...credentials,
      name: "CRMS ADMIN",
      provider: "EMAIL",
      userType: "CRMS_ADMIN",
      platform: "CRMS",
      image: "",
    };
    try {
      const response = await instance.post(`${LOGIN_URL}`, credentials);
      console.log({ loginResponse: response });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const authenticateAPI = createAsyncThunk(
  "auth",
  async (data , { rejectWithValue }) => {
    try {
      const response = await instance.get(`${AUTH_URL}`);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {

    logout: (state) => {

     state.currentUser = null;
     state.error = null;
     state.loading = false;
     localStorage.removeItem("accessToken");
    },
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(loginAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload?.data;
        localStorage.setItem(
          "accessToken",
          JSON.stringify(action.payload?.data?.accessToken)
        );
        toast.success("Login Successfully");
      })
      .addCase(loginAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error("Invalid Credentials");
      }) 

      .addCase(authenticateAPI.rejected, (state, action) => {
        state.currentUser = null;
        state.error = action.payload;
        state.loading = false;
        localStorage.removeItem("accessToken");
      });
  },
});

export const { logout } = loginSlice.actions;

export default loginSlice.reducer;
