import React, { useEffect } from "react";
import { Box, Typography, Switch, IconButton } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "../Create/styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { createPinSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { pinEditAPI, pinGetById } from "../../../../Redux/slice/pinList";
import EditPinCodeFormField from './editPinFormField'

const PinCodeEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const initialValues = {
    city: "",
    circleName: "",
    regionName: "",
    divisionName: "",
    pincode: "",
    district: "",
    stateName: "",
    lat : "" , 
    lng : "" , 
    priority: 0,
    active: true,
  };

  const handleSubmit = async (values) => {  
    try {
       
        const finalResponse = await dispatch(
          pinEditAPI({
            id: searchParams.get("id"),
            formdata: values,
          })
        );
        console.log(finalResponse);
      } catch (error) {
        console.error(error);
      }
    };

  const formik = CustomFormikHook(initialValues, createPinSchema , handleSubmit);

  

  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(pinGetById({ id: searchParams.get("id") }));
      data = data?.payload?.data;
      formik.setValues({
        city: data?.city || "",
        circleName: data?.circleName || "",
        regionName: data?.regionName || "",
        divisionName: data?.divisionName || "",
        pincode: data?.pincode || "",
        district: data?.district || "",
        stateName: data?.stateName || "",
        priority: data?.priority || 0,
        lng : data?.lng || 23 , 
        lat : data?.lat || 73 , 
        active: true,
      });
    };

    fetchData();
  }, [dispatch, searchParams]);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Edit Pin Code</Typography>
          <Box sx={styles.filterButtonContainer}>
            <CustomButtonComponent sx={styles.filterButton}>
              <Typography sx={styles.filterButtonText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </CustomButtonComponent>
            <CustomButtonComponent sx={styles.exportButton}>
              <VerticalAlignBottomIcon />
              <Typography sx={styles.exportButtonText}>Export</Typography>
            </CustomButtonComponent>
            <IconButton sx={styles.addButton}>
              <AddIcon sx={styles.addButtonIcon} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <EditPinCodeFormField
          label={"City"}
          name="city"
          placeholder={"Enter City"}
          handleChange={formik.handleChange}
          value={formik.values.city}
          errors={formik.errors.city}
          touched={formik.touched.city}
        />
        <EditPinCodeFormField
          label={"Circle Name"}
          name="circleName"
          placeholder={"Enter Circle Name"}
          handleChange={formik.handleChange}
          value={formik.values.circleName}
          errors={formik.errors.circleName}
          touched={formik.touched.circleName}
        />
        <EditPinCodeFormField
          label={"Region Name"}
          name="regionName"
          placeholder={"Enter Region Name"}
          handleChange={formik.handleChange}
          value={formik.values.regionName}
          errors={formik.errors.regionName}
          touched={formik.touched.regionName}
        />
        <EditPinCodeFormField
          label={"Division Name"}
          name="divisionName"
          placeholder={"Enter Division Name"}
          handleChange={formik.handleChange}
          value={formik.values.divisionName}
          errors={formik.errors.divisionName}
          touched={formik.touched.divisionName}
        />
        <EditPinCodeFormField
          label={"Pincode"}
          name="pincode"
          placeholder={"Enter Pincode"}
          handleChange={formik.handleChange}
          value={formik.values.pincode}
          errors={formik.errors.pincode}
          touched={formik.touched.pincode}
        />
        <EditPinCodeFormField
          label={"District"}
          name="district"
          placeholder={"Enter District"}
          handleChange={formik.handleChange}
          value={formik.values.district}
          errors={formik.errors.district}
          touched={formik.touched.district}
        />
        <EditPinCodeFormField
          label={"State Name"}
          name="stateName"
          placeholder={"Enter State Name"}
          handleChange={formik.handleChange}
          value={formik.values.stateName}
          errors={formik.errors.stateName}
          touched={formik.touched.stateName}
        />
        <EditPinCodeFormField
          label={"State Name"}
          name="stateName"
          placeholder={"Enter State Name"}
          handleChange={formik.handleChange}
          value={formik.values.stateName}
          errors={formik.errors.stateName}
          touched={formik.touched.stateName}
        />
        <EditPinCodeFormField
          label={"Longitude"}
          name="lng"
          placeholder={"Enter the longitude"}
          handleChange={formik.handleChange}
          value={formik.values.stateName}
          errors={formik.errors.stateName}
          touched={formik.touched.stateName}
        />

       <EditPinCodeFormField
          label={"latitude"}
          name="ltd"
          placeholder={"Enter the latitude"}
          handleChange={formik.handleChange}
          value={formik.values.stateName}
          errors={formik.errors.stateName}
          touched={formik.touched.stateName}
        />
         <EditPinCodeFormField
          label={"priority"}
          name="priority"
          type="number"
          placeholder={"Enter the priority"}
          handleChange={formik.handleChange}
          value={formik.values.priority}
          errors={formik.errors.priority}
          touched={formik.touched.priority}
        />

        {/* <Box sx={{ marginTop: "30px" }}>
          <Typography sx={styles.editGenderFormStatusText}>Status</Typography>
          <Switch
            checked={formik.values.active}
            onChange={() =>
              formik.setFieldValue("active", !formik.values.active)
            }
          />
        </Box> */}
   <Box sx={{ marginTop: "30px" }}></Box>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Update
        </CustomButtonComponent>

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => console.log("Reset clicked")}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default PinCodeEditPage;
