// export const styles = {
//     mainBox: {
//       pl: "6px",
//       marginBottom: "10px",
//     },
//     headingMainBox: {
//       marginTop: "40px",
//       display: "flex",
//       justifyContent: "space-between",
//       alignItems: "center",
//     },
//     headingTextBox: {
//       fontWeight: "600",
//       fontSize: "36px",
//       lineHeight: "48px",
//       color: "#323842",
//     },
//     headingButtonBox: { alignItems: "center", display: "flex" },
//     filterButtonBox: {
//       color: "primary.light",
//       backgroundColor: "primary.dark",
//       "&:hover": {
//         backgroundColor: "primary.main",
//         color: "primary.contrastText",
//       },
//       width: "140px",
//       justifyContent: "space-between",
//       pl: "12px",
//       pr: "20px",
//       py: "10px",
//       mx: "20px",
//     },
//     filterTypoText: { textTransform: "capitalize", fontWeight: "500" },
//     exportButtonBox: {
//       color: "primary.light",
//       backgroundColor: "primary.dark",
//       "&:hover": {
//         backgroundColor: "primary.main",
//         color: "primary.contrastText",
//       },
//       width: "114px",
//       justifyContent: "space-between",
//       py: "10px",
//       pl: "16px",
//       pr: "16px",
//       mx: "20px",
//     },
//     exportTypoText: { textTransform: "capitalize", fontWeight: "500" },
//     addIconButtonBox: {
//       color: "primary.light",
//       backgroundColor: "primary.dark",
//       justifyContent: "center",
//       paddingX: "10px",
//       paddingY: "10px",
//       borderRadius: "4px",
//       ml: "12px",
//       "&:hover": {
//         backgroundColor: "primary.main",
//         color: "primary.contrastText",
//       },
//     },
//     editBrandFormMainBox: {
//       height: "800px",
//       marginY: "50px",
//       backgroundColor: "#FFFFFF",
//       ml: "6px",
//       paddingY: "2px",
//       paddingX: "30px",
//       boxShadow: "8px 8px 9px 0px #0000001A",
//     },
//     editBrandFormLabel: { fontSize: "14px", fontWeight: "500", marginY: "6px" },
//     editBrandFormTextField: {
//       width: "70%",
//       "& .MuiOutlinedInput-root": {
//         borderRadius: "5px",
//         borderColor: "0.5px solid #BCBCBC",
//         height: "45px",
//       },
//       "& .MuiInputBase-input::placeholder": {
//         color: "#242424",
//         opacity: "0.7",
//       },
//     },
//     editBrandFormStatusText: {
//       fontSize: "14px",
//       fontWeight: "500",
//       marginY: "6px",
//     },
//     editBrandFormButtonMainBox: {
//       marginTop: "30px",
//       display: "flex",
//     },
//     editBrandFormUpdateButtonIcon: { marginRight: "5px", fontSize: "18px" },
//     editBrandFormUpdateButton: {
//       color: "primary.light",
//       marginRight: "40px",
//       textTransform: "capitalize",
//       alignItems: "center",
//     },
//     editBrandFormResetButtonIcon: {
//       marginRight: "5px",
//       fontSize: "18px",
//       transform: "rotate(270deg)",
//     },
//     editBrandFormResetButton: {
//       color: "primary.light",
//       backgroundColor: "#FF5B5B",
//       textTransform: "capitalize",
//     },
//   };








export const styles = {
  headingContainer: {
    pl: "6px",
    marginBottom: "10px",
  },
  header: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headingTitle: {
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "48px",
  },
  categoryFormBox: {
    marginY: "50px",
    backgroundColor: "#FFFFFF",
    ml: "6px",
    paddingY: "20px",
    paddingX: "40px",
    boxShadow: "8px 8px 9px 0px #0000001A",
  },
  editGenderFormUpdateButtonIcon: { marginRight: "5px", fontSize: "18px" },
  editGenderFormUpdateButton: {
    color: "primary.light",
    marginRight: "40px",
    textTransform: "capitalize",
    alignItems: "center",
  },
  editGenderFormResetButtonIcon: {
    marginRight: "5px",
    fontSize: "18px",
    transform: "rotate(270deg)",
  },
  editGenderFormResetButton: {
    color: "primary.light",
    backgroundColor: "#FF5B5B",
    textTransform: "capitalize",
  },
};















  