import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { SUBCATEGORY_URLS1 } from "../../constants";
import { toast } from 'sonner';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};



export const subCategoryListAPI = createAsyncThunk("subCategoryListAPI", async ({page , search}) => {
  try {
    const response = await instance.get(`${SUBCATEGORY_URLS1.LIST(page , search)}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const subCategoryDeleteAPI = createAsyncThunk(
  "subCategoryDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${SUBCATEGORY_URLS1.DELETE(data.id)}`);
      toast.success('Deleted successfully!');
      return response.data;
    } catch (error) {
      console.log("Error", error);
      toast.error('Failed to delete.');
      return error;
    }
  }
);

export const subCategoryAllAPI = createAsyncThunk("subCategoryAllAPI", async () => {
  try {
    const response = await instance.get(`${SUBCATEGORY_URLS1.DROP}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});


export const subCategoryGetByIdAPI = createAsyncThunk(
  "subCategoryGetByIdAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(SUBCATEGORY_URLS1.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);



export const subCategoryUpdateAPI = createAsyncThunk(
  "subCategoryUpdateAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        SUBCATEGORY_URLS1.UPDATE(data.id),
        data.formData
      );
      toast.success('Updated successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to update.');
      return rejectWithValue(err);
    }
  }
);


export const subCategoryCreateAPI = createAsyncThunk(
  "subCategoryCreateAPI",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${SUBCATEGORY_URLS1.CREATE}`, formdata);
      // console.log(response);
      toast.success('Sub-Category created successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to create Sub-Category.');
      return rejectWithValue(err);
    }
  }
);


const subCategoryList = createSlice({
  name: "subCategoryList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state?.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active : action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(subCategoryListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(subCategoryListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
      state.page = action?.payload?.pageInfo;
    });
    builder.addCase(subCategoryAllAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
    });
    builder.addCase(subCategoryListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { updateStatus } = subCategoryList.actions;
export default subCategoryList.reducer;
