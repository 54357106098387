
import React from "react";
import Button from "@mui/material/Button";

const TotalPagesFour = ({ currentPage, visitedPages, handlePageChange }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {[1, 2, 3, 4].map((page) => (
        <Button
          key={page}
          onClick={() => handlePageChange(page)}
          sx={{
            color: currentPage === page ? "#4a00e0" : "#000",
            border: currentPage === page ? "1px solid #4a00e0" : "1px solid #d3d3d3",
            fontFamily: "Avenir",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "16px",
            backgroundColor: "white",
            borderRadius: "8px",
            minWidth: "32px",
            padding: "8px",
            margin: "0 4px",
            "&:hover": { border: "1px solid #4a00e0" },
          }}
        >
          {page}
        </Button>
      ))}
    </div>
  );
};

export default TotalPagesFour;
