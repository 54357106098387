import React from "react";
import { Box, Typography, IconButton, Switch } from "@mui/material";
import { styles } from "./styles";
import CustomButtonComponent from "../../../../../Components/CustomButton/";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DownArrowIcon from "../../../../../Assets/Icons/Drawer/DownArrow.svg";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import AddIcon from "@mui/icons-material/Add";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import OnlineTrackingFormField from "./onlineTrackingFormField";
import CustomFormikHook from "../../../../../Hooks/CustomFormikHook";
import { onlineTrackingFormSchema } from "../../../../../Schemas";
import { createTrackingAPI } from "../../../../../Redux/slice/createTracking";
import { useDispatch } from "react-redux";

const OnlineTracking = () => {
  const dispatch = useDispatch();
  const initialValues = {
    saleUrlPattern: "",
    orderIdRegex: "",
    sampleOrderId: "",
    missingSalesCount: "",
    onlineTracking: true,
  };

  const formik = CustomFormikHook(initialValues, onlineTrackingFormSchema);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await formik.validateForm();
      formik.handleSubmit();
      const finalResponse = await dispatch(createTrackingAPI(formik.values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      
      <Box sx={styles.createFormBox}>
        <OnlineTrackingFormField
          label={"Sale url pattern"}
          name={"saleUrlPattern"}
          placeholder={"Enter Sale url pattern"}
          handleChange={formik.handleChange}
          value={formik.values.saleUrlPattern}
          errors={formik.errors.saleUrlPattern}
          touched={formik.touched.saleUrlPattern}
        />
        <OnlineTrackingFormField
          label={"Order Id regex"}
          name={"orderIdRegex"}
          placeholder={"Enter Order Id regex"}
          handleChange={formik.handleChange}
          value={formik.values.orderIdRegex}
          errors={formik.errors.orderIdRegex}
          touched={formik.touched.orderIdRegex}
        />
        <OnlineTrackingFormField
          label={"Sample Order Id"}
          name={"sampleOrderId"}
          placeholder={"Enter Sample Order Id"}
          handleChange={formik.handleChange}
          value={formik.values.sampleOrderId}
          errors={formik.errors.sampleOrderId}
          touched={formik.touched.sampleOrderId}
        />
        <OnlineTrackingFormField
          label={"Missing Sales Count"}
          name={"missingSalesCount"}
          placeholder={"Enter Missing Sales Count"}
          handleChange={formik.handleChange}
          value={formik.values.missingSalesCount}
          errors={formik.errors.missingSalesCount}
          touched={formik.touched.missingSalesCount}
        />
        <Box sx={{ marginTop: "30px" }}>
          <Typography sx={styles.editGenderFormStatusText}>
            Online Tracking
          </Typography>
          <Switch
            checked={formik.values.onlineTracking}
            onChange={() =>
              formik.setFieldValue(
                "onlineTracking",
                !formik.values.onlineTracking
              )
            }
          />
        </Box>
        <Box sx={styles.editGenderFormButtonMainBox}>
          <CustomButtonComponent
            variant="contained"
            sx={styles.editGenderFormUpdateButton}
            onClick={formik.handleSubmit}
          >
            <BorderColorOutlinedIcon
              sx={styles.editGenderFormUpdateButtonIcon}
            />
            Payout
            <ArrowBackIosIcon sx={{ mr: "5px", fontSize: 13 }} /> Prev
          </CustomButtonComponent>

        
        </Box>
        <Box sx={styles.formButtonMainBox}>
          <CustomButtonComponent
            variant="contained"
            sx={styles.formUpdateButton}
            onClick={handleSubmit}
          >
            <BorderColorOutlinedIcon sx={styles.formUpdateButtonIcon} />
            Update
          </CustomButtonComponent>
          <CustomButtonComponent
            variant="contained"
            sx={styles.formUpdateButton}
          >
            <BorderColorOutlinedIcon sx={styles.formUpdateButtonIcon} />
            Update Url
          </CustomButtonComponent>
          <CustomButtonComponent
            variant="contained"
            sx={styles.formResetButton}
           
          >
            <RefreshOutlinedIcon sx={styles.formResetButtonIcon} />
            Reset
          </CustomButtonComponent>
        </Box>
      </Box>
    </>
  );
};
export default OnlineTracking;
