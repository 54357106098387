import upDownArrow from "../../Assets/Icons/Drawer/upDownArrow.svg";
import editIcon from "../../Assets/Icons/Drawer/editIcon.svg";

export const styles = {
  mainBox: {
    pl: "6px",
    marginBottom: "10px",
  },
  headingMainBox: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headingTextBox: { fontWeight: "600", fontSize: "36px", lineHeight: "48px" },
  headingButtonBox: { alignItems: "center", display: "flex" },
  filterButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    // "&:hover": {
    //   backgroundColor: "primary.dark",
    //   color: "primary.light",
    // },
    "&:hover": {
      backgroundColor: "primary.main",
      color: "primary.contrastText",
    },
    width: "140px",
    justifyContent: "space-between",
    pl: "12px",
    pr: "20px",
    py: "10px",
    mx: "20px",
  },
  filterTypoText: { textTransform: "capitalize", fontWeight: "500" },
  exportButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": {
      backgroundColor: "primary.main",
      color: "primary.contrastText",
    },
    width: "114px",
    justifyContent: "space-between",
    py: "10px",
    pl: "16px",
    pr: "16px",
    mx: "20px",
  },
  exportTypoText: { textTransform: "capitalize", fontWeight: "500" },
  addIconButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
   "&:hover": {
      backgroundColor: "primary.main",
      color: "primary.contrastText",
    },
    justifyContent: "center",
    paddingX: "10px",
    paddingY: "10px",
    borderRadius: "4px",
    ml: "12px",
  },
  pagesDetailMainBox: { display: "flex", marginTop: "20px" },
  pagesDetailText: { color: "#6E7787", marginLeft: "4px" },
  pagesDetailNumber: {
    color: "#171A1F",
    fontWeight: "500",
    marginLeft: "4px",
  },
};

export const tableHead = [
  {
    heading: [{ type: "text", content: "Actions" }],
    key: "actions",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "USER" }],
    key: "user",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "ID" }],
    key: "id",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "E-MAIL ID" }],
    key: "emailID",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "AREA/CITY/STATE" }],
    key: "address",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "STATUS" }],
    key: "status",
    sort: false,
  },
];

export const tableBody = [
  {
    user: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Ryan Young",
          phoneNumber: "9525354515",
          verified: false,
        },
      },
    ],
    id: [{ type: "text", content: "67" }],
    totalNoBookings: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    address: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    lastBookedSlot: [{ type: "text", content: "Feb 15, 2023" }],
    status: [{ type: "status", content: "Active" }],
  },
  {
    user: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Matthew Martinez",
          phoneNumber: "9525354515",
          verified: false,
        },
      },
    ],
    id: [{ type: "text", content: "23" }],
    totalNoBookings: [{ type: "text", content: "10" }],
    emailID: [{ type: "email", content: "123@gmail.com" }],
    address: [{ type: "text", content: "Lathore/Pune/Maharastra" }],
    lastBookedSlot: [{ type: "text", content: "Mar 19, 2020" }],
    status: [{ type: "status", content: "Pending" }],
  },
  {
    user: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Emily Johnson",
          phoneNumber: "9525354515",
          verified: false,
        },
      },
    ],
    id: [{ type: "text", content: "25" }],
    totalNoBookings: [{ type: "text", content: "01" }],
    emailID: [{ type: "email", content: "xyz@gmail.com" }],
    address: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    lastBookedSlot: [{ type: "text", content: "Oct 19, 2020" }],
    status: [{ type: "status", content: "Active" }],
  },
  {
    user: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Ryan Brown",
          phoneNumber: "9525354515",
          verified: false,
        },
      },
    ],
    id: [{ type: "text", content: "34" }],
    totalNoBookings: [{ type: "text", content: "08" }],
    emailID: [{ type: "email", content: "aaa@gmail.com" }],
    address: [{ type: "text", content: "Lathore/Pune/Maharastra" }],
    lastBookedSlot: [{ type: "text", content: "Aug 29, 2021" }],
    status: [{ type: "status", content: "Pending" }],
  },
  {
    user: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Emma Wilson",
          phoneNumber: "9525354515",
          verified: false,
        },
      },
    ],
    id: [{ type: "text", content: "11" }],
    totalNoBookings: [{ type: "text", content: "09" }],
    emailID: [{ type: "email", content: "111@gmail.com" }],
    address: [{ type: "text", content: "Lathore/Pune/Maharastra" }],
    lastBookedSlot: [{ type: "text", content: "Jun 14, 2020" }],
    status: [{ type: "status", content: "Inactive" }],
  },
  {
    user: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Brian Hall",
          phoneNumber: "9525354515",
          verified: false,
        },
      },
    ],
    id: [{ type: "text", content: "10" }],
    totalNoBookings: [{ type: "text", content: "07" }],
    emailID: [{ type: "email", content: "456@gmail.com" }],
    address: [{ type: "text", content: "Lathore/Pune/Maharastra" }],
    lastBookedSlot: [{ type: "text", content: "Jan 19, 2021" }],
    status: [{ type: "status", content: "Active" }],
  },
  {
    user: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Layla Philips",
          phoneNumber: "9525354515",
          verified: false,
        },
      },
    ],
    id: [{ type: "text", content: "07" }],
    totalNoBookings: [{ type: "text", content: "06" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    address: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    lastBookedSlot: [{ type: "text", content: "Jan 05, 2022" }],
    status: [{ type: "status", content: "Pending" }],
  },
  {
    user: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Brian White",
          phoneNumber: "9525354515",
          verified: false,
        },
      },
    ],
    id: [{ type: "text", content: "05" }],

    totalNoBookings: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    address: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    lastBookedSlot: [{ type: "text", content: "May 16, 2023" }],
    status: [{ type: "status", content: "Active" }],
  },
  {
    user: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Jessica Wilson",
          phoneNumber: "9525354515",
          verified: false,
        },
      },
    ],
    id: [{ type: "text", content: "03" }],
    totalNoBookings: [{ type: "text", content: "03" }],
    emailID: [{ type: "email", content: "ccc@gmail.com" }],
    address: [{ type: "text", content: "Lathore/Pune/Maharastra" }],
    lastBookedSlot: [{ type: "text", content: "Apr 15, 2020" }],
    status: [{ type: "status", content: "Pending" }],
  },
  {
    user: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Matthew Johnson",
          phoneNumber: "9525354515",
          verified: false,
        },
      },
    ],
    id: [{ type: "text", content: "12" }],
    totalNoBookings: [{ type: "text", content: "04" }],
    emailID: [{ type: "email", content: "xyz@gmail.com" }],
    address: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    lastBookedSlot: [{ type: "text", content: "Feb 25, 2022" }],
    status: [{ type: "status", content: "Inactive" }],
  },
  {
    user: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Matthew Johnson",
          phoneNumber: "9525354515",
          verified: false,
        },
      },
    ],
    id: [{ type: "text", content: "12" }],
    totalNoBookings: [{ type: "text", content: "04" }],
    emailID: [{ type: "email", content: "xyz@gmail.com" }],
    address: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    lastBookedSlot: [{ type: "text", content: "Feb 25, 2022" }],
    status: [{ type: "status", content: "Inactive" }],
  },
];
