import {
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  Switch,
} from "@mui/material";
import React from "react";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { styles } from "./styles";
import { styled } from "@mui/system";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { genderCreateFormSchema } from "../../../../Schemas";
import { useDispatch } from "react-redux";
import { createGenderAPI } from "../../../../Redux/slice/genderList";

const RequiredIndicator = styled("span")({
  color: "red",
  marginRight: "2px",
});
const GenderCreatePage = () => {
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    title: "",
    description: "",
    priority: 0,
    active: true,
  };

  const handleSubmit = async (values) => {
    try {
      const finalResponse = await dispatch(createGenderAPI(values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(
    initialValues,
    genderCreateFormSchema,
    handleSubmit
  );

  const formFields = [
    {
      label: "Name",
      placeHolder: "Enter Your Name",
      name: "name",
      type: "text",
    },
    {
      label: "Title",
      placeHolder: "Enter Your Title",
      name: "title",
      type: "text",
    },
    {
      label: "Description",
      placeHolder: "Your Vision, our Passion",
      name: "description",
      type: "text",
    },
    {
      label: "Priority",
      placeHolder: "eg. 123",
      name: "priority",
      type: "number",
    },
  ];

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Create Gender</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
              i
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.editGenderFormMainBox}>
        {formFields.map((field, index) => {
          return (
            <Box key={index} sx={{ marginTop: "30px" }}>
              <Typography sx={styles.editGenderFormLabel}>
                <RequiredIndicator>*</RequiredIndicator>
                {field.label}
              </Typography>
              <TextField
                sx={styles.editGenderFormTextField}
                placeholder={field.placeHolder}
                name={field.name}
                type={field.type}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              />
              {formik.errors[field.name] && formik.touched[field.name] ? (
                <Typography
                  sx={{ color: "red", fontSize: "12px", paddingX: "2px" }}
                >
                  {formik.errors[field.name]}
                </Typography>
              ) : null}
            </Box>
          );
        })}
        <Box sx={{ marginTop: "30px" }}>
          <Typography sx={styles.editGenderFormStatusText}>Status</Typography>
          <Switch
            checked={formik.values.active}
            onChange={() =>
              formik.setFieldValue("active", !formik.values.active)
            }
          />
        </Box>
        <Box sx={styles.editGenderFormButtonMainBox}>
          <Button
            variant="contained"
            sx={styles.editGenderFormUpdateButton}
            onClick={formik.handleSubmit}
          >
            <BorderColorOutlinedIcon
              sx={styles.editGenderFormUpdateButtonIcon}
            />
            Create
          </Button>
          <Button
            variant="contained"
            sx={styles.editGenderFormResetButton}
            onClick={() => console.log("Reset clicked")}
          >
            <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
            Reset
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default GenderCreatePage;
