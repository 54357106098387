import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrowIcon from "../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomPagination from "../../Components/CustomPagination";
import DataInsertIntoTable from "../../Components/CustomTableComponent/dataInsertIntoTable";
import { styles, tableHead } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  academyDeleteAPI,
  academyStatusUpdateAPI,
  acadmyAPI,
  acadmyFilterAPI,
  acadmySearchAPI,
  deleteAcademy,
  updateAcademyStatus,
} from "../../Redux/slice/acadmyList";
import editIcon from "../../Assets/Icons/Drawer/editIcon.svg";
import Admin from "./SuperAcademyLogin";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { downloadCSV } from "../../utils/utils";
import Filter from "../../Components/Customfilter/Filter";
import { useSearchParams } from "react-router-dom";

const AcademyPage = () => {
  const [acadmies, setAcadmies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const [filterOpen, setFilterOpen] = useState(false);

  const academyData = useSelector((state) => state.acadmyList.data);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [keyOfSort, setKeyOfSort] = useState("profileVisits");
  const [currentPage, setCurrentPage] = useState(1);
  const head = tableHead;
  const sortedBody = acadmies?.sort((a, b) => {
    const nameA = a[keyOfSort][0]?.content?.toUpperCase();
    const nameB = b[keyOfSort][0]?.content?.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const totalItems = academyData?.pageInfo?.itemCount;
  const totalPages = academyData?.pageInfo?.pageCount;
  const itemsPerPage = academyData?.pageInfo?.perPage;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleAcademyProfilePage = (slug) => {
    window.open(`${process.env.REACT_APP_ACADEMY_PROFILE_BASE_URL}/${slug}/`, "_blank");
    console.log('slug' , slug);
  
  };

  // https://sportjacks.com/profile/academy/combat-sports--fitness-indore-261722081185762A/

  const handleExport = () => {
    const getAddreStr = (address) =>
      `${address?.address} ${address?.city}; ${address?.state}`;

    const csv = academyData?.data?.map((row) => {
      return {
        id: row?._id,
        slug: row?.slug,
        name: row?.name,
        phoneNumber: row?.phoneNumber,
        email: row?.email,
        sports: row?.sports?.map((sport) => sport.name).join("; "),
        totalNoBookings: "NA",
        profileVisits: "NA",
        address: getAddreStr(row?.address),
        status: row.coachProfile?.status,
        rating: "4.5",
      };
    });
    downloadCSV(csv, "academies");
  };

  const handleDelete = async () => {
    for (let slug of selectedItems) {
      dispatch(deleteAcademy({ slug: slug }));
      dispatch(academyDeleteAPI({ slug: slug }));
    }
  };

  useEffect(() => {
    const handleStatus = (slug) => (event) => {
      dispatch(updateAcademyStatus({ slug, status: event?.target?.checked }));
      dispatch(
        academyStatusUpdateAPI({
          slug,
          body: { status: event?.target?.checked },
        })
      );
    };
    let arr = academyData?.data?.map((academy) => {
      return {
        actions: [
          {
            content: (
              <Switch
                onChange={handleStatus(academy?.slug)}
                checked={academy?.active}
              />
            ),
          },
          {
            content: (
              <VisibilityOutlinedIcon
                sx={{ color: "primary.main" }}
                onClick={() => handleAcademyProfilePage(academy?.slug)}
              />
            ),
          },
          { type: "ButtonWithImg", content: editIcon },
        ],
        academy: [
          {
            type: "verifiedDetails",
            content: {
              img: editIcon,
              name: academy?.name,
              phoneNumber: academy?.phoneNumber,
              verified: true,
            },
          },
        ],
        slug: [{ type: "text", content: academy?.slug }],
        totalNoBookings: [{ type: "text", content: "NA" }],
        profileVisits: [{ type: "text", content: "NA" }],
        emailID: [{ type: "email", content: academy?.email || "NA" }],
        country: [{ type: "text", content: academy?.address?.city }],
        admin: [{ type: "text", content: academy?.superAdmin?.fullName }],
        status: [
          {
            type: "status",
            content: academy?.deleted
              ? "DELETED"
              : academy?.active
              ? "Active"
              : academy?.status,
          },
        ],
        rating: [{ type: "rating", content: "4.5" }],

        extraInfo: [
          {
            id: academy?._id,
            slug: academy?.slug,
            name: academy?.name,
            address: academy?.address,
          },
        ],
      };
    });
    setAcadmies(arr);
    setLoading(false);
  }, [academyData, dispatch]);

  useEffect(() => {
    let q = searchParams.get("q");
    console.log("Here is the query", q);
    if (q) {
      dispatch(acadmySearchAPI({ search: q , page: currentPage }));
    } else {
      dispatch(acadmyAPI({ page: currentPage }));
    }
  }, [dispatch, currentPage, searchParams]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const handleOpenAdminModal = (adminDetails) => {
    setSelectedAdmin(adminDetails);
    setOpenAdminModal(true);
  };

  const handleCloseAdminModal = () => {
    setOpenAdminModal(false);
    setSelectedAdmin(null);
  };

  const handleFilter = (filter) => {
    dispatch(acadmyFilterAPI(filter));
  };

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Academy List</Box>
          <Box sx={styles.headingButtonBox}>
            <Button
              sx={styles.filterButtonBox}
              onClick={() => setFilterOpen(true)}
            >
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText} onClick={handleExport}>
                Export
              </Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.pagesDetailMainBox}>
          <Typography>
            <span style={{ color: "#171A1F", fontWeight: "700" }}>
              {itemsPerPage}
            </span>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>
              in total
            </span>
          </Typography>

          <Typography sx={{ marginLeft: "70px" }}>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
            <span style={styles.pagesDetailNumber}>
              {(currentPage - 1) * itemsPerPage}
            </span>
            <span style={styles.pagesDetailText}>to</span>
            <span style={styles.pagesDetailNumber}>
              {currentPage * itemsPerPage}
            </span>
            <span style={styles.pagesDetailText}>of</span>
            <span style={styles.pagesDetailNumber}>{totalItems}</span>
            <span style={styles.pagesDetailText}>results</span>
          </Typography>

          <Box mt={2} textAlign="center" ml="auto">
            {selectedItems?.length > 0 ? (
              <IconButton
                onClick={handleDelete}
                aria-label="delete"
                sx={{ color: "red" }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          pl: "6px",
          display: "flex",
          //overflowX: "hidden",
          // width : "calc(99vw - 310px)" ,
          overflowX: "auto",
          whiteSpace: "wrap",
        }}
      >
        <DataInsertIntoTable
          checkData={selectedItems}
          setCheckData={setSelectedItems}
          headingOfTable={head}
          bodyOfTable={acadmies}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
          setHandleOpen={setOpenAdminModal}
          setHandleAcademyClick={setSelectedAdmin}
          modalOpen={true}
          academyList={true}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>
      {/* <Modal
        open={openAdminModal}
        onClose={handleCloseAdminModal}
        aria-labelledby="modal-admin-title"
        aria-describedby="modal-admin-description"
      > */}

      {selectedAdmin ? (
        <Admin
          onClose={handleCloseAdminModal}
          slug={selectedAdmin?.extraInfo[0]?.slug}
          academyName={selectedAdmin?.extraInfo[0]?.name}
          academyAddress={selectedAdmin?.extraInfo[0]?.address}
          onAcademyNameClick={() =>
            handleAcademyProfilePage(selectedAdmin?.extraInfo[0]?.slug)
          }
        />
      ) : null}
      {/* </Modal> */}
      {filterOpen && (
        <Filter
          filterAction={handleFilter}
          onClose={() => setFilterOpen(false)}
        />
      )}
    </>
  );
};

export default AcademyPage;
