import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "../Create/styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomOfferSchema} from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { imageUpload } from "../../../../Redux/slice/thunks";
import EditFormField from "../../CustomFormFields/EditFormField";
import { ProductCategoryAllAPI } from "../../../../Redux/slice/categoryEcomList";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { offerGetByIdAPI, offerUpdateAPI } from "../../../../Redux/slice/offerEcom";
import moment from "moment";  


const OfferEcomEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();


  const initialValues = {
    sellerSKU: "",
    productPrice: "",
    quantity: "",
    condition: "",
    shippingChannel: "",
    countryOfOrigin: "",
    hsnCode: "",
    productMRP: "",
    offerAvailabilityFrom: "",
    offerAvailabilityTo: "",
  };

  

  const handleSubmit = async (values) => {
    try {
      const finalResponse = await dispatch(
        offerUpdateAPI({
          id: searchParams.get("id"),
          formData: values,
        })
      );
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomOfferSchema , handleSubmit);




  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(offerGetByIdAPI({ id: searchParams.get("id") }));
      data = data?.payload?.data?.data;
      formik.setValues({
        sellerSKU: data?.sellerSKU || "",
        productPrice: data?.productPrice || "",
        quantity: data?.quantity || "",
        condition: data?.condition || "",
        shippingChannel: data?.shippingChannel || "",
        countryOfOrigin: data?.countryOfOrigin || "",
        hsnCode: data?.hsnCode || "",
        productMRP: data?.productMRP || "",
        offerAvailabilityFrom: moment(data?.offerAvailabilityFrom ).format("YYYY-MM-DD") || "",
        offerAvailabilityTo:  moment(data?.offerAvailabilityTo ).format("YYYY-MM-DD") || "",
      });
    };

    fetchData();
  }, [dispatch, searchParams]);

  
  console.log("offer" , formik.values);
    

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Edit Offer</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <EditFormField
          label={"Seller SKU"}
          name="sellerSKU"
          placeholder={"Enter Seller SKU"}
          handleChange={formik.handleChange}
          value={formik.values.sellerSKU}
          errors={formik.errors.sellerSKU}
          touched={formik.touched.sellerSKU}
        />

        <EditFormField
          label={"Product Price"}
          name="productPrice"
          placeholder={"Enter Product Price"}
          handleChange={formik.handleChange}
          value={formik.values.productPrice}
          errors={formik.errors.productPrice}
          touched={formik.touched.productPrice}
        />

        <EditFormField
          label={"Quantity"}
          name="quantity"
          placeholder={"Enter Quantity"}
          handleChange={formik.handleChange}
          value={formik.values.quantity}
          errors={formik.errors.quantity}
          touched={formik.touched.quantity}
        />

        <EditFormField
          label={"Condition"}
          name="condition"
          placeholder={"Enter Condition"}
          handleChange={formik.handleChange}
          value={formik.values.condition}
          errors={formik.errors.condition}
          touched={formik.touched.condition}
        />

        <EditFormField
          label={"Shipping Channel"}
          name="shippingChannel"
          placeholder={"Enter Shipping Channel"}
          handleChange={formik.handleChange}
          value={formik.values.shippingChannel}
          errors={formik.errors.shippingChannel}
          touched={formik.touched.shippingChannel}
        />

        <EditFormField
          label={"Country of Origin"}
          name="countryOfOrigin"
          placeholder={"Enter Country of Origin"}
          handleChange={formik.handleChange}
          value={formik.values.countryOfOrigin}
          errors={formik.errors.countryOfOrigin}
          touched={formik.touched.countryOfOrigin}
        />

        <EditFormField
          label={"HSN Code"}
          name="hsnCode"
          placeholder={"Enter HSN Code"}
          handleChange={formik.handleChange}
          value={formik.values.hsnCode}
          errors={formik.errors.hsnCode}
          touched={formik.touched.hsnCode}
        />

        <EditFormField
          label={"Product MRP"}
          name="productMRP"
          placeholder={"Enter Product MRP"}
          handleChange={formik.handleChange}
          value={formik.values.productMRP}
          errors={formik.errors.productMRP}
          touched={formik.touched.productMRP}
        />

        <EditFormField
          label={"Offer Availability From"}
          name="offerAvailabilityFrom"
          placeholder={"Enter Offer Availability From"}
          handleChange={formik.handleChange}
          value={formik.values.offerAvailabilityFrom}
          errors={formik.errors.offerAvailabilityFrom}
          touched={formik.touched.offerAvailabilityFrom}
          type="date"
        />

        <EditFormField
          label={"Offer Availability To"}
          name="offerAvailabilityTo"
          placeholder={"Enter Offer Availability To"}
          handleChange={formik.handleChange}
          value={formik.values.offerAvailabilityTo}
          errors={formik.errors.offerAvailabilityTo}
          touched={formik.touched.offerAvailabilityTo}
          type="date"
        />

        <Box sx={{ marginTop: "38px" }}></Box> 

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Update
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default OfferEcomEditPage;
