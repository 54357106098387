import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { TIMESLOT_URLS } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const timeSlotListAPI = createAsyncThunk("timeSlotListAPI", async () => {
  try {
    const response = await instance.get(`${TIMESLOT_URLS.LIST}`);
    return response.data;   
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const timeSlotDeleteAPI = createAsyncThunk(
  "timeSlotDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${TIMESLOT_URLS.LIST}/${data.id}`);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);


export const TimeSlotGetById = createAsyncThunk(
  "TimeSlotGetById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(TIMESLOT_URLS.EDIT(data.id));
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

export const timeSlotStatusUpdate = createAsyncThunk(
  "timeSlotStatusUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        TIMESLOT_URLS.UPDATE_STATUS(data.id),
        data.body
      );
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

export const TimeSlotEditAPI = createAsyncThunk(
  "timeSlotEdit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        TIMESLOT_URLS.EDIT(data.id),
        data.formdata
      );
      console.log(data.formdata);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


export const timeSlotCreateAPI = createAsyncThunk(
  "timeSlotCreate",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${TIMESLOT_URLS.CREATE}`, formdata);
      // console.log(response);;
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);




const timeSlot = createSlice({
  name: "timeSlot",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.status,
          };
        }
        return item;
      });
      state.data = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(timeSlotListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(timeSlotListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
    });
    builder.addCase(timeSlotListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});
export const { updateStatus } = timeSlot.actions;
export default timeSlot.reducer;
