import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import { styles } from "./styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomShippingSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import EditFormField from "../../CustomFormFields/EditFormField";
import { shippingGetByIdAPI, shippingUpdateAPI } from "../../../../Redux/slice/shippingEcom";

const ShippingEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const initialValues = {
    shippingType: "",
    shippingDescription: "",
  };

  const handleSubmit = async (values) => {
    try {
      const finalResponse = await dispatch(
        shippingUpdateAPI({
          id: searchParams.get("id"),
          formData: values,
        })
      );
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomShippingSchema, handleSubmit);

  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(shippingGetByIdAPI({ id: searchParams.get("id") }));
      data = data?.payload?.data?.data;
      formik.setValues({
        shippingType: data?.shippingType || "",
        shippingDescription: data?.shippingDescription || "",
      });
    };

    fetchData();
  }, [dispatch, searchParams]);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Edit Shipping Method</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <EditFormField
          label={"Shipping Type"}
          name="shippingType"
          placeholder={"Enter Shipping Type"}
          handleChange={formik.handleChange}
          value={formik.values.shippingType}
          errors={formik.errors.shippingType}
          touched={formik.touched.shippingType}
        />

        <EditFormField
          label={"Shipping Description"}
          name="shippingDescription"
          placeholder={"Enter Shipping Description"}
          handleChange={formik.handleChange}
          value={formik.values.shippingDescription}
          errors={formik.errors.shippingDescription}
          touched={formik.touched.shippingDescription}
        />

        <Box sx={{ marginTop: "38px" }}></Box> 
  
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Update
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default ShippingEditPage;
