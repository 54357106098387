import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styles } from "./styles";
import SJLogo from "../../Assets/Logos/blogo-1.svg";
import CustomButtonComponent from "../../Components/CustomButton";
import CustomTextFieldComponent from "../../Components/CustomTextField";
import { useDispatch, useSelector } from "react-redux";
import { loginAPI } from "../../Redux/loginSlice.js";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  
  const location = useLocation() ;

  const userData = useSelector((state) => state.login);

  useEffect(() => {
    if (userData?.currentUser?.accessToken) {
      // navigate("/dashboard");
      navigate(location?.state?.from || "/dashboard");
    }
  }, [navigate, userData , location?.state?.from]);

  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };
  const handleClickShowPassword = () => {
    setCredentials((prev) => ({ ...prev, showPassword: !prev.showPassword }));
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!credentials.email || !credentials.password) {
      toast.error("Email and password are required", {
        position: "top-right",
        className: "my-classname",
        duration: 5000,
      });
      return;
    }

   let res =  await dispatch(loginAPI(credentials)).unwrap();
   if (res.status === 200) {
    navigate("/dashboard");
   }
  };

  const textFieldStyles = {
    color: "#242424",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "120%",
    letterSpacing: "0.06px",
    width: "100%",
  };

  return (
    <Container disableGutters maxWidth={false} sx={styles.root}>
      <Box sx={styles.mainContainer} component={Paper}>
        <Grid container disableGutters>
          <Grid
            item
            xs={6}
            sx={{
              height: "100%",
              backgroundColor: "#D9D9D9",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "58px",
                height: "37px",
                bottom: "37px",
                left: "17px",
                position: "absolute",
              }}
            >
              <img src={SJLogo} alt="SJ_Logo" />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ margin: "60px" }}>
              <Typography
                variant="h5"
                sx={{
                  color: "#242424",
                  fontFamily: "Avenir",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "900",
                  lineHeight: "120%",
                  letterSpacing: "0.1px",
                  marginBottom: "30px",
                }}
              >
                Login
              </Typography>
              <form onSubmit={handleSubmit}>
                <CustomTextFieldComponent
                  variant="standard"
                  hiddenLabel
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={credentials.email}
                  onChange={handleChange}
                  sx={{
                    "& .MuiInputBase-input::placeholder": {
                      color: "#3E3E3E",
                      fontWeight: "800",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "2px dotted #898989",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: "2px dotted #898989",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "2px dotted #898989",
                    },
                    width: "100%",
                  }}
                  inputProps={{
                    style: textFieldStyles,
                  }}
                />

                <CustomTextFieldComponent
                  variant="standard"
                  hiddenLabel
                  type={credentials.showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={credentials.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {credentials.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginTop: "20px",
                    "& .MuiInputBase-input::placeholder": {
                      color: "#3E3E3E",
                      fontWeight: "800",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "2px dotted #898989",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: "2px dotted #898989",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "2px dotted #898989",
                    },
                    width: "100%",
                  }}
                  inputProps={{
                    style: textFieldStyles,
                  }}
                />
                <CustomButtonComponent
                  sx={{
                    width: "80%",
                    marginTop: "50px",
                    backgroundColor: "#242424",
                    color: "#fff",
                    textTransform: "capitalize",
                  }}
                  type="submit"
                >
                  Continue
                </CustomButtonComponent>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Login;
