import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import {SIZE_URLS1} from "../../constants";
import { toast } from 'sonner';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const sizeListAPI = createAsyncThunk("sizeListAPI", async ({page , search}) => {
  try {
    const response = await instance.get(`${SIZE_URLS1.LIST(page , search)}`);
    
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const sizeListDeleteAPI = createAsyncThunk(
  "sizeListDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${SIZE_URLS1.DELETE(data.id)}`);
      toast.success('Deleted successfully!');
      return response.data;
    } catch (error) {
      console.log("Error", error);
      toast.error('Failed to delete.');
      return error;
    }
  }
);

export const sizeGetByIdAPI = createAsyncThunk(
  "sizeGetByIdAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(SIZE_URLS1.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

export const sizeUpdateAPI = createAsyncThunk(
  "sizeUpdateAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        SIZE_URLS1.UPDATE(data.id),
        data.formData
      );
      toast.success('Updated successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to update.');
      return rejectWithValue(err);
    }
  }
);

export const sizeCreateAPI = createAsyncThunk(
  "sizeCreateAPI",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${SIZE_URLS1.CREATE}`, formdata);
      toast.success('Size created successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to create Size.');
      return rejectWithValue(err);
    }
  }
);

const sizeEcomList = createSlice({
  name: "sizeEcomList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state?.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active : action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(sizeListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sizeListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
      state.page = action?.payload?.pageInfo;
    });

    builder.addCase(sizeListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { updateStatus } = sizeEcomList.actions;
export default sizeEcomList.reducer;
