import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { SELLER_URLS } from "../../constants";
import { toast } from "sonner";

const initialState = {
  isLoading: false,
  data: null,
  seller: null,
  products: null,
  orders: null,
  brands: null,
  approvalRequests: null,
  approvalRequest: null,
  isError: false,
};

export const sellerListAPI = createAsyncThunk(
  "sellerListAPI",
  async ({ page, search }) => {
    try {
      const response = await instance.get(SELLER_URLS.LIST(page), {
        params: { search },
      });
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const sellerProfileAPI = createAsyncThunk(
  "sellerProfileAPI",
  async ({ id }) => {
    try {
      const response = await instance.get(SELLER_URLS.GET(id));
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const sellerOrdersListAPI = createAsyncThunk(
  "sellerOrdersListAPI",
  async ({ id, page }) => {
    try {
      const response = await instance.get(SELLER_URLS.GET_ORDERS(id), {
        params: { page, limit: 10 },
      });
      return response?.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);
export const sellerProductsListAPI = createAsyncThunk(
  "sellerProductsListAPI",
  async ({ id, page }) => {
    try {
      const response = await instance.get(SELLER_URLS.GET_PRODUCTS(id), {
        params: { page, limit: 10 },
      });
      return response?.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const sellerBrandListAPI = createAsyncThunk(
  "sellerBrandListAPI",
  async ({ id, page }) => {
    try {
      const response = await instance.get(SELLER_URLS.GET_BRANDS(id), {
        params: { page, limit: 10 },
      });
      return response?.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const ApproveSellerAPI = createAsyncThunk(
  "ApproveSellerAPI",
  async ({ id, status, reason }) => {
    try {
      const response = await instance.put(SELLER_URLS.APPROVE_PROD_BRAND(id), {
        status,
        reason,
      });
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const approvalRequestListAPI = createAsyncThunk(
  "approvalRequestListAPI",
  async ({ id, page }) => {
    try {
      const response = await instance.get(SELLER_URLS.APPROVAL_REQUESTS(id), {
        params: { page, limit: 10 },
      });
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const approvalGetByIdAPI = createAsyncThunk(
  "approvalGetByIdAPI",
  async ({ id }) => {
    try {
      const response = await instance.get(SELLER_URLS.GET_APPROVAL_REQUEST(id));
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

const sellerSlice = createSlice({
  name: "sellerSlice",
  initialState,
  reducers: {
    deleteSeller: (state, action) => {
      console.log(action.payload.id);
      let dataArray = [...state.data.data];
      state.data.data = dataArray.map((seller) =>
        seller._id === action.payload.id ? { ...seller, deleted: true } : seller
      );
    },
    updateSellerStatus: (state, action) => {
      let dataArray = [...state.data.data];
      state.data.data = dataArray.map((seller) =>
        seller._id === action.payload.id
          ? { ...seller, active: action.payload.status }
          : seller
      );
    },
  },

  extraReducers: (builder) => {
    builder.addCase(sellerListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sellerListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(sellerListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    builder.addCase(sellerProfileAPI.fulfilled, (state, action) => {
      state.seller = action.payload.data;
    });
    builder.addCase(sellerOrdersListAPI.fulfilled, (state, action) => {
      state.orders = action.payload;
    });
    builder.addCase(sellerProductsListAPI.fulfilled, (state, action) => {
      state.products = action.payload;
    });
    builder.addCase(sellerBrandListAPI.fulfilled, (state, action) => {
      state.brands = action.payload;
    });
    builder.addCase(ApproveSellerAPI.fulfilled, (state, action) => {
      toast.success("product Approved");
      sellerListAPI()
    });
    builder.addCase(ApproveSellerAPI.rejected, (state, action) => {
      toast.error("somthing went wrong");
      sellerListAPI()
    });
    builder.addCase(approvalRequestListAPI.fulfilled, (state, action) => {
      state.approvalRequests = action.payload;
    });
    builder.addCase(approvalGetByIdAPI.fulfilled, (state, action) => {
      state.approvalRequest = action.payload?.data?.[0];
    });
    builder.addCase(approvalGetByIdAPI.rejected, (state, action) => {
      toast.error("somthing went wrong");
    });
  },
});

export const { deleteSeller, updateSellerStatus } = sellerSlice.actions;
export default sellerSlice.reducer;
