import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { OFFER_URLS } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const fetchCategory = createAsyncThunk("fetchCategory", async () => {
  const response = await instance.get(
    `${OFFER_URLS.LIST}`
  );
  console.log(response.data);
  return response.data;
});

export const editeOfferAPI = createAsyncThunk(
  "editeOffer",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(
        `${OFFER_URLS.CREATE}`,
        formdata
      );
      // console.log(response);;
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


const offer = createSlice({
  name: "offerList",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCategory.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default offer.reducer;
