export const SERVICES = {
  RBAC_SERVICE: "/rbac-service/api/v1",
  WEBSITE_MASTER_SERVICE: "/website-master-service/api/v1",
  COACH_SERVICE: "/coach-service/api/v1",
  ACADEMY_SERVICE: "/academy-service/api/v1",
  UTILITY_SERVICE : "/utility-service/api/v1" , 
  MASTER_SERVICE : "/master-service/api/v1" ,
  PRODUCT_SERVICE : "/product-service/api/v1" ,
  ORDER_SERVICE : "/order-service/api/v1" ,
  SELLER_SERVICE : "/seller-service/api/v1" ,
};

