import React from "react";
import { Box, Typography } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "./styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { AdvertisementCreateFormSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch } from "react-redux";
import { advertisementCreateAPI } from "../../../../Redux/slice/advEcom";
import { imageUpload } from "../../../../Redux/slice/thunks";
import CreateFormField from "../../CustomFormFields/CreateFormField";

const AdvertisementCreatePage = () => {
  const dispatch = useDispatch();
  const initialValues = {
    title: "",
    icon: "",
    banner: "",
    description: "",
  };

  

  
  
  const handleSubmit = async (values) => {
    try {
      await formik.validateForm();
      const finalResponse = await dispatch(advertisementCreateAPI(values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };
  
  const formik = CustomFormikHook(initialValues, AdvertisementCreateFormSchema, handleSubmit);
  console.log(formik.isValid);


  const handleFileChange = async (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("platform", "CRMS");

      try {
        const response = await dispatch(imageUpload(formData)).unwrap();
        const filePath = response?.fileLocations?.[0];
        if (type === "icon") {
          formik.setFieldValue("icon", filePath);
        } else if (type === "banner") {
          formik.setFieldValue("banner", filePath);
        }
        console.log({ response });
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Advertisement</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <CreateFormField
          label={"title"}
          name="title"
          placeholder={"Enter title"}
          handleChange={formik.handleChange}
          value={formik.values.title}
          errors={formik.errors.title}
          touched={formik.touched.title}
        />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <CreateFormField
              label={"Icon"}
              name="icon"
              placeholder={"Icon"}
              handleChange={formik.handleChange}
              value={formik.values.icon}
              errors={formik.errors.icon}
              touched={formik.touched.icon}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
            component="label"
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, "icon")}
            />
          </CustomButtonComponent>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <CreateFormField
              label={"Banner"}
              name="banner"
              placeholder={"Banner"}
              handleChange={formik.handleChange}
              value={formik.values.banner}
              errors={formik.errors.banner}
              touched={formik.touched.banner}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
            component="label"
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, "banner")}
            />
          </CustomButtonComponent>
        </Box>

        <CreateFormField
          label={"Description"}
          name="description"
          placeholder={"Enter Description"}
          handleChange={formik.handleChange}
          value={formik.values.description}
          errors={formik.errors.description}
          touched={formik.touched.description}
        />

        <Box sx={{ marginTop: "38px" }}></Box>

        
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Create
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default AdvertisementCreatePage;
