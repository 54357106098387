import upDownArrow from "../../../../Assets/Icons/Drawer/upDownArrow.svg";
import editIcon from "../../../../Assets/Icons/Drawer/editIcon.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Switch from "@mui/material/Switch";

export const styles = {
  mainBox: {
    pl: "6px",
    marginBottom: "10px",
  },
  headingMainBox: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headingTextBox: {
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "48px",
  },
  headingButtonBox: {
    alignItems: "center",
    display: "flex",
  },
  filterButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": {
      backgroundColor: "primary.main",
      color: "primary.contrastText",
    },
    width: "140px",
    justifyContent: "space-between",
    pl: "12px",
    pr: "20px",
    py: "10px",
    mx: "20px",
  },
  filterTypoText: {
    textTransform: "capitalize",
    fontWeight: "500",
  },
  exportButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
   "&:hover": {
      backgroundColor: "primary.main",
      color: "primary.contrastText",
    },
    width: "114px",
    justifyContent: "space-between",
    py: "10px",
    pl: "16px",
    pr: "16px",
    mx: "20px",
  },
  exportTypoText: {
    textTransform: "capitalize",
    fontWeight: "500",
  },
  addIconButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    justifyContent: "center",
    paddingX: "10px",
    paddingY: "10px",
    borderRadius: "4px",
    ml: "12px",
   "&:hover": {
      backgroundColor: "primary.main",
      color: "primary.contrastText",
    },
  },
  pagesDetailMainBox: {
    display: "flex",
    marginTop: "20px",
  },
  pagesDetailText: {
    color: "#6E7787",
    marginLeft: "4px",
  },
  pagesDetailNumber: {
    color: "#171A1F",
    fontWeight: "500",
    marginLeft: "4px",
  },
};

export const tableHead = [
  {
    heading: [{ type: "text", content: "Actions" }],
    key: "actions",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "ID" }],
    key: "id",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Name" }],
    key: "name",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Icon" }],
    key: "icon",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Banner" }],
    key: "banner",
    sort: false,
  },
  {
    heading: [
      { type: "text", content: "Priority" },
      { type: "ButtonWithImg", content: upDownArrow },
    ],
    key: "priority",
    sort: true,
  },
  {
    heading: [{ type: "text", content: "Description" }],
    key: "description",
    sort: false,
  },

  {
    heading: [
      { type: "text", content: "Created At" },
      { type: "ButtonWithImg", content: upDownArrow },
    ],
    key: "createdAt",
    sort: true,
  },
  {
    heading: [
      { type: "text", content: "Updated At" },
      { type: "ButtonWithImg", content: upDownArrow },
    ],
    key: "updatedAt",
    sort: true,
  },
];

export const tableBody = [
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    name: [{ type: "text", content: "Male" }],
    title: [{ type: "text", content: "Male" }],
    description: [{ type: "text", content: "Boult Astraverse Earbuds" }],
    priority: [{ type: "text", content: "1085" }],
    type: [{ type: "text", content: "Ravi Sharma" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    name: [{ type: "text", content: "Male" }],
    title: [{ type: "text", content: "Male" }],
    description: [{ type: "text", content: "Boult Astraverse Earbuds" }],
    priority: [{ type: "text", content: "1085" }],
    type: [{ type: "text", content: "Ravi Sharma" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    name: [{ type: "text", content: "Male" }],
    title: [{ type: "text", content: "Male" }],
    description: [{ type: "text", content: "Boult Astraverse Earbuds" }],
    priority: [{ type: "text", content: "1085" }],
    type: [{ type: "text", content: "Ravi Sharma" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    name: [{ type: "text", content: "Male" }],
    title: [{ type: "text", content: "Male" }],
    description: [{ type: "text", content: "Boult Astraverse Earbuds" }],
    priority: [{ type: "text", content: "1085" }],
    type: [{ type: "text", content: "Ravi Sharma" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    name: [{ type: "text", content: "Male" }],
    title: [{ type: "text", content: "Male" }],
    description: [{ type: "text", content: "Boult Astraverse Earbuds" }],
    priority: [{ type: "text", content: "1085" }],
    type: [{ type: "text", content: "Ravi Sharma" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    name: [{ type: "text", content: "Male" }],
    title: [{ type: "text", content: "Male" }],
    description: [{ type: "text", content: "Boult Astraverse Earbuds" }],
    priority: [{ type: "text", content: "1085" }],
    type: [{ type: "text", content: "Ravi Sharma" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    name: [{ type: "text", content: "Male" }],
    title: [{ type: "text", content: "Male" }],
    description: [{ type: "text", content: "Boult Astraverse Earbuds" }],
    priority: [{ type: "text", content: "1085" }],
    type: [{ type: "text", content: "Ravi Sharma" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    name: [{ type: "text", content: "Male" }],
    title: [{ type: "text", content: "Male" }],
    description: [{ type: "text", content: "Boult Astraverse Earbuds" }],
    priority: [{ type: "text", content: "1085" }],
    type: [{ type: "text", content: "Ravi Sharma" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    name: [{ type: "text", content: "Male" }],
    title: [{ type: "text", content: "Male" }],
    description: [{ type: "text", content: "Boult Astraverse Earbuds" }],
    priority: [{ type: "text", content: "1085" }],
    type: [{ type: "text", content: "Ravi Sharma" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    name: [{ type: "text", content: "Male" }],
    title: [{ type: "text", content: "Male" }],
    description: [{ type: "text", content: "Boult Astraverse Earbuds" }],
    priority: [{ type: "text", content: "1085" }],
    type: [{ type: "text", content: "Ravi Sharma" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    name: [{ type: "text", content: "Male" }],
    title: [{ type: "text", content: "Male" }],
    description: [{ type: "text", content: "Boult Astraverse Earbuds" }],
    priority: [{ type: "text", content: "1085" }],
    type: [{ type: "text", content: "Ravi Sharma" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    name: [{ type: "text", content: "Male" }],
    title: [{ type: "text", content: "Male" }],
    description: [{ type: "text", content: "Boult Astraverse Earbuds" }],
    priority: [{ type: "text", content: "1085" }],
    type: [{ type: "text", content: "Ravi Sharma" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
];
