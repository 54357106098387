import upDownArrow from "../../../../Assets/Icons/Drawer/upDownArrow.svg";
import editIcon from "../../../../Assets/Icons/Drawer/editIcon.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

export const styles = {
  mainBox: {
    pl: "6px",
    marginBottom: "10px",
  },
  headingMainBox: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headingTextBox: {
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "48px",
  },
  headingButtonBox: {
    alignItems: "center",
    display: "flex",
  },
  filterButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 15px",
    marginRight: "20px",
    fontSize: "16px",
    borderRadius: "8px",
  },
  filterTypoText: {
    color: "primary.light",
  },
  exportButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 15px",
    fontSize: "16px",
    borderRadius: "8px",
  },
  exportTypoText: {
    marginLeft: "10px",
  },
  addIconButtonBox: {
    marginLeft: "20px",
    borderRadius: "8px",
    backgroundColor: "primary.dark",
    padding: "10px",
  },
  pagesDetailMainBox: {
    marginTop: "15px",
    display: "flex",
  },
  pagesDetailNumber: {
    color: "primary.light",
    fontSize: "14px",
    fontWeight: "700",
    marginLeft: "6px",
  },
  pagesDetailText: {
    color: "#6E7787",
    marginLeft: "6px",
  },
  pagesDetailMainBox: {
    display: "flex",
    alignItems: "center",
    mt: 2,
  },
};


export const tableHead = [
  {
    heading: [{ type: "text", content: "Actions" }],
    key: "actions",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "ID" }],
    key: "id",
    sort: false,
  },

  {
    heading: [{ type: "text", content: "List Price" }],
    key: "listPrice",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Sell Price" }],
    key: "sellPrice",
    sort: true,
  },
  {
    heading: [{ type: "text", content: "Currency" }],
    key: "currency",
    sort: true,
  },
  {
    heading: [{ type: "text", content: "Code" }],
    key: "code",
    sort: true,
  },
  {
    heading: [{ type: "text", content: "PUBLISHED" }],
    key: "status",
    sort: true,
  },
  {
    heading: [
      { type: "text", content: "Created At" },
      { type: "ButtonWithImg", content: upDownArrow },
    ],
    key: "createdAt",
    sort: true,
  },
  {
    heading: [
      { type: "text", content: "Updated At" },
      { type: "ButtonWithImg", content: upDownArrow },
    ],
    key: "updatedAt",
    sort: true,
  },
];
