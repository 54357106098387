import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import {PRODUCT_URLS1} from "../../constants";
import { toast } from 'sonner';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};


// export const productListAPI = createAsyncThunk("productListAPI", async ({page , search}) => {
//   try {
//     const response = await instance.get(`${PRODUCT_URLS1.LIST(page , search)}`);
//     return response.data;
//   } catch (error) {
//     console.log("Error", error);
//     return error;
//   }
// });


export const productListAPI = createAsyncThunk("productListAPI", async () => {
  try {
    const response = await instance.get(`${PRODUCT_URLS1.LIST}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const productListDeleteAPI = createAsyncThunk(
  "productListDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${PRODUCT_URLS1.DELETE(data.id)}`);
      toast.success('product deleted successfully!');
      return response.data;
    } catch (error) {
      console.log("Error", error);
      toast.error('Failed to delete product.');
      return error;
    }
  }
);


export const productGetByIdAPI = createAsyncThunk(
  "productGetByIdAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(PRODUCT_URLS1.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);



export const productUpdateAPI = createAsyncThunk(
  "productUpdateAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        PRODUCT_URLS1.UPDATE(data.id),
        data.formData
      );
      toast.success('product updated successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to update product.');
      return rejectWithValue(err);
    }
  }
);


export const productCreateAPI = createAsyncThunk(
  "productCreateAPI",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${PRODUCT_URLS1.CREATE}`, formdata);
      // console.log(response);
      toast.success('product created successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to create product.');
      return rejectWithValue(err);
    }
  }
);


const productEcomList = createSlice({
  name: "productEcomList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state?.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(productListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(productListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
    });

    builder.addCase(productListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { updateStatus } = productEcomList.actions;
export default productEcomList.reducer;
