
import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton  , Switch} from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "./styles";
import CreateCategoryFormField from "./createCategoryFormField";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { createProductCategorySchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch, useSelector } from "react-redux";
import { ProductCategoryCreateAPI } from "../../../../Redux/slice/categoryEcomList";
import { imageUpload } from "../../../../Redux/slice/thunks";
import { sportEcomAllAPI} from "../../../../Redux/slice/sportEcomList";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { segmentAllAPI } from "../../../../Redux/slice/segmentEcomList";
import { groupListAllAPI } from "../../../../Redux/slice/groupEcomList";

const ProductCategoryCreatePage = () => {
  const dispatch = useDispatch();
 
  const sportGroupData = useSelector((state) => state.sportEcomList.data);
  const segmentGroupData = useSelector((state) => state.segmentList.data);
  const GroupData = useSelector((state) => state.groupList.data);
  
  console.log("response" , sportGroupData);

  const initialValues = {
    name: "",
    description: "",
    icon: "",
    banner: "",
    sportId : "",
    groupId : "" ,
    segmentId : "" ,
    discount : "",
  };

  const handleSubmit = async (values) => {
    try {
      await formik.validateForm();
      const finalResponse = await dispatch(ProductCategoryCreateAPI(values));
      console.log("response final" , finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, createProductCategorySchema, handleSubmit);


  const handleFileChange = async (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("platform", "CRMS");

      try {
        const response = await dispatch(imageUpload(formData));
        const filePath = response?.payload?.fileLocations?.[0];
        if (type === "icon") {
          formik.setFieldValue("icon", filePath);
        } else if (type === "banner") {
          formik.setFieldValue("banner", filePath);
        }
        console.log({ response });
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  useEffect(() => {
    dispatch(sportEcomAllAPI());
    dispatch(segmentAllAPI()) ; 
    dispatch(groupListAllAPI());
  }, [dispatch]);

  console.log("formik valid" , formik.isValid);
  console.log("formik values" , formik.values);
  

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Category</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <CreateCategoryFormField
          label={"Name"}
          name="name"
          placeholder={"Enter Name"}
          handleChange={formik.handleChange}
          value={formik.values.name}
          errors={formik.errors.name}
          touched={formik.touched.name}
        />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <CreateCategoryFormField
              label={"Icon"}
              name="icon"
              placeholder={"Icon"}
              handleChange={formik.handleChange}
              value={formik.values.icon}
              errors={formik.errors.icon}
              touched={formik.touched.icon}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
            component="label"
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, "icon")}
            />
          </CustomButtonComponent>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <CreateCategoryFormField
              label={"Banner"}
              name="banner"
              placeholder={"Banner"}
              handleChange={formik.handleChange}
              value={formik.values.banner}
              errors={formik.errors.banner}
              touched={formik.touched.banner}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
            component="label"
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, "banner")}
            />
          </CustomButtonComponent>
        </Box>

        <CreateCategoryFormField
          label={"Description"}
          name="description"
          placeholder={"Enter Description"}
          handleChange={formik.handleChange}
          value={formik.values.description}
          errors={formik.errors.description}
          touched={formik.touched.description}
        />

        <Box>
          <Box sx={{ minWidth: 120, marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Sports</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.sportId}
                label="Sports Category"
                name = "sportId"
                onChange={formik.handleChange}
              >
                {sportGroupData?.map((category) => (
                  <MenuItem key={category?._id} value={category?._id}>
                    {category?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>


        <Box>
          <Box sx={{ minWidth: 120, marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Segments</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.segmentId}
                label="Sports Category"
                name = "segmentId"
                onChange={formik.handleChange}
              >
                {segmentGroupData?.map((seg) => (
                  <MenuItem key={seg?._id} value={seg?._id}>
                    {seg?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box>
          <Box sx={{ minWidth: 120, marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Group Category</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.groupId}
                label="Group Category"
                name = "groupId"
                onChange={formik.handleChange}
              >
                {GroupData?.map((seg) => (
                  <MenuItem key={seg?._id} value={seg?._id}>
                    {seg?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>


        <Box sx={{ marginTop: "30px" }}>
          {/* <Typography sx={styles.editGenderFormStatusText}>Status</Typography>
          <Switch
            checked={formik.values.active}
            onChange={() => formik.setFieldValue("active", !formik.values.active)}
          /> */}
        </Box>

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Create
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default ProductCategoryCreatePage;
