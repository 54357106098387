import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { COACHING_TYPE_URLS } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const coachingTypeListAPI = createAsyncThunk(
  "coachingTypeListAPI",
  async () => {
    try {
      const response = await instance.get(`${COACHING_TYPE_URLS.LIST}`);
      return response.data;
    }
     catch(error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const coachingTypeDeleteAPI = createAsyncThunk(
  "coachingTypeDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(
        `${COACHING_TYPE_URLS.LIST}/${data.id}`
      );
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);



export const CoachingTypeGetById = createAsyncThunk(
  "CoachingTypeGetById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(COACHING_TYPE_URLS.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);
export const coachingTypeStatusUpdate = createAsyncThunk(
  "coachingTypeStatusUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        COACHING_TYPE_URLS.UPDATE_STATUS(data.id),
        data.body
      );
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

export const CoachingTypeEditAPI = createAsyncThunk(
  "coachingTypeEdit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        COACHING_TYPE_URLS.EDIT(data.id),
        data.formdata
      );

      console.log(data.formdata);
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);



export const coachingTypeCreateAPI = createAsyncThunk(
  "coachingTypeCreate",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(
        `${COACHING_TYPE_URLS.CREATE}`,
        formdata
      );
      // console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);




const coachingType = createSlice({
  name: "coachingTypeList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.status,
          };
        }
        return item;
      });
      state.data = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(coachingTypeListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(coachingTypeListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
    });
    builder.addCase(coachingTypeListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});
export const { updateStatus } = coachingType.actions;
export default coachingType.reducer;
