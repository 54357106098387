import React from "react";

import { ProviderWrapper } from "./Redux";

import { ThemeProvider } from "@mui/material";
import { theme } from "./Theme";
import { Toaster } from "sonner";
import AllRoutes from "./Routes";
import styles from "./app.css";
 
const App = () => {
  return (
    <ProviderWrapper>
      <ThemeProvider theme={theme}>
        <div className="App" style={styles}>
          <Toaster style={{ top: "100px" }} richColors position="top-right" />
          <AllRoutes />
        </div>
      </ThemeProvider>
    </ProviderWrapper>
  );
};
export default App;
