import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "./styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomOfferSchema} from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch} from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CreateFormField from "../../CustomFormFields/CreateFormField";
import {offerCreateAPI } from "../../../../Redux/slice/offerEcom";

const OfferEcomCreatePage = () => {

  const [offers, setOffers] = useState("");


  const dispatch = useDispatch();
  const initialValues = {
    sellerSKU: "",
    productPrice: "",
    quantity: "",
    condition: "",
    shippingChannel: "",
    countryOfOrigin: "",
    hsnCode: "",
    productMRP: "",
    offerAvailabilityFrom: "",
    offerAvailabilityTo: "",
  };


  const handleSubmit = async (values) => {
    try {
      await formik.validateForm();
      const finalResponse = await dispatch(offerCreateAPI(values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomOfferSchema, handleSubmit);


  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Offer</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <CreateFormField
          label={"Seller SKU"}
          name="sellerSKU"
          placeholder={"Enter Seller SKU"}
          handleChange={formik.handleChange}
          value={formik.values.sellerSKU}
          errors={formik.errors.sellerSKU}
          touched={formik.touched.sellerSKU}
        />

        <CreateFormField
          label={"Product Price"}
          name="productPrice"
          placeholder={"Enter Product Price"}
          handleChange={formik.handleChange}
          value={formik.values.productPrice}
          errors={formik.errors.productPrice}
          touched={formik.touched.productPrice}
        />

        <CreateFormField
          label={"Quantity"}
          name="quantity"
          placeholder={"Enter Quantity"}
          handleChange={formik.handleChange}
          value={formik.values.quantity}
          errors={formik.errors.quantity}
          touched={formik.touched.quantity}
        />

        <CreateFormField
          label={"Condition"}
          name="condition"
          placeholder={"Enter Condition"}
          handleChange={formik.handleChange}
          value={formik.values.condition}
          errors={formik.errors.condition}
          touched={formik.touched.condition}
        />

    
        <CreateFormField
          label={"Shipping Channel"}
          name="shippingChannel"
          placeholder={"Enter Shipping Channel"}
          handleChange={formik.handleChange}
          value={formik.values.shippingChannel}
          errors={formik.errors.shippingChannel}
          touched={formik.touched.shippingChannel}
        />

        <CreateFormField
          label={"Country of Origin"}
          name="countryOfOrigin"
          placeholder={"Enter Country of Origin"}
          handleChange={formik.handleChange}
          value={formik.values.countryOfOrigin}
          errors={formik.errors.countryOfOrigin}
          touched={formik.touched.countryOfOrigin}
        />

        <CreateFormField
          label={"HSN Code"}
          name="hsnCode"
          placeholder={"Enter HSN Code"}
          handleChange={formik.handleChange}
          value={formik.values.hsnCode}
          errors={formik.errors.hsnCode}
          touched={formik.touched.hsnCode}
        />

        <CreateFormField
          label={"Product MRP"}
          name="productMRP"
          placeholder={"Enter Product MRP"}
          handleChange={formik.handleChange}
          value={formik.values.productMRP}
          errors={formik.errors.productMRP}
          touched={formik.touched.productMRP}
        />

        <CreateFormField
          label={"Offer Availability From"}
          name="offerAvailabilityFrom"
          placeholder={"Enter Offer Availability From"}
          handleChange={formik.handleChange}
          value={formik.values.offerAvailabilityFrom}
          errors={formik.errors.offerAvailabilityFrom}
          touched={formik.touched.offerAvailabilityFrom}
          type="date"
        />

        <CreateFormField
          label={"Offer Availability To"}
          name="offerAvailabilityTo"
          placeholder={"Enter Offer Availability To"}
          handleChange={formik.handleChange}
          value={formik.values.offerAvailabilityTo}
          errors={formik.errors.offerAvailabilityTo}
          touched={formik.touched.offerAvailabilityTo}
          type="date"
        />

        <Box sx={{ marginTop: "38px" }}></Box> 

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Create
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default OfferEcomCreatePage;
