// import React, { useEffect } from "react";
// import { Box, Typography } from "@mui/material";
// import { Link } from "react-router-dom";
// import { styles } from "./styles";
// import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import superAcademyIcon from "../../../Assets/Images/superAcademyIcon.svg";
// import SuperLoginTable from "./superLoginTable";
// import IconForSuperAdmin from "../../../Assets/Images/superAdminRowIcon.svg";
// import IconForAdmin from "../../../Assets/Images/adminRowIcon.svg";
// import IconForEdite from "../../../Assets/Images/pencilIcon.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { getCoachesAPI } from "../../../Redux/slice/acadmyList";

// const Admin = () => {

//   const coach = useSelector((state)=>state.coaches) ;
//   const dispatch = useDispatch() ;

//   useEffect(()=>{
//     dispatch(getCoachesAPI())
//   }, [dispatch]) ;

//   const rows = [
//     {
//       admin: (
//         <Box style={{ display: "flex", gap: "4%", marginTop: "5%" }}>
//           <img src={IconForSuperAdmin} alt="IconSuperAdmin" />
//           <Typography sx={styles.superAdmin}>Super Admin</Typography>
//           <Typography sx={styles.superAdminName}>Ravi Sharma</Typography>
//           <img
//             style={{ marginLeft: "4%" }}
//             src={IconForEdite}
//             alt="IconForEdite"
//           />
//         </Box>
//       ),
//     },
//     {
//       admin: (
//         <Box style={{ display: "flex", gap: "4%" }}>
//           <img src={IconForAdmin} alt="IconForAdmin" />
//           <Typography sx={styles.admin}>Admin 1</Typography>
//           <Typography sx={styles.adminName}>Rohith</Typography>
//           <img
//             style={{ marginLeft: "12.5%" }}
//             src={IconForEdite}
//             alt="IconForEdite"
//           />
//         </Box>
//       ),
//     },
//     {
//       admin: (
//         <Box style={{ display: "flex", gap: "4%" }}>
//           <img src={IconForAdmin} alt="IconForAdmin" />
//           <Typography sx={styles.admin}>Admin 2</Typography>
//           <Typography sx={styles.adminName}>Kiran Kumar</Typography>
//           <img
//             style={{ marginLeft: "3%" }}
//             src={IconForEdite}
//             alt="IconForEdite"
//           />
//         </Box>
//       ),
//     },
//   ];

//   const headingOfTable = ["ADMIN", "E-MAIL", "PH. NO"];

//   return (
//     <Box sx={styles.adminProfileMainBox}>
//       <Box
//         sx={{
//           padding: "15.75px",
//           height: "15.75px",
//           top: "214.17px",
//           left: "1429.62px",
//           color: "#242424",
//           display: "flex",
//           justifyContent: "flex-end",
//         }}
//       >
//         <CloseRoundedIcon />
//       </Box>
//       <Box sx={styles.headerContainer}>
//         <img
//           style={{ marginLeft: "3%" }}
//           src={superAcademyIcon}
//           alt="superAcademyIcon"
//         />
//         <Link
//           to="/academy/academyprofile"
//           style={{ textDecoration: "none", color: "inherit" }}
//         >
//           <Box sx={styles.textContainer}>
//             <Typography sx={styles.academyName}>
//               Sparks Cricket Academy
//             </Typography>
//             <Typography sx={styles.location}>Vadodara, Gujarat</Typography>
//           </Box>
//         </Link>
//       </Box>
//       <SuperLoginTable headingOfTable={headingOfTable} bodyOfTable={rows} />
//     </Box>
//   );
// };

// export default Admin;

import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { styles } from "./styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import superAcademyIcon from "../../../Assets/Images/superAcademyIcon.svg";
import IconForSuperAdmin from "../../../Assets/Images/superAdminRowIcon.svg";
import IconForAdmin from "../../../Assets/Images/adminRowIcon.svg";
import IconForEdit from "../../../Assets/Images/pencilIcon.svg";
import SuperLoginTable from "./superLoginTable";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCoachRole,
  changeCoachRoleAPI,
  changeSuperAdminRoleAPI,
  getCoachesAPI,
  superAdminToCoachOrAdminRole,
  toSuperAdminRole,
} from "../../../Redux/slice/acadmyList";
import useDetectOutsideClick from "../../../Hooks/detectOutsideClickHook";

const RoleDrpdown = ({ onChange, selected, desableSuperAdmin }) => {
  return (
    <select
      name="role"
      id="role"
      onChange={onChange}
      // defaultValue={isSuperAdmin ? "Super Admin" : isAdmin ? "Admin" : "Coach"}
      defaultValue={selected}
    >
      <option value="Superadmin" disabled={desableSuperAdmin}>
        Super Admin
      </option>
      <option value="Admin">Admin</option>
      <option value="Coach">Coach</option>
    </select>
  );
};

const Admin = ({
  slug,
  onClose,
  academyName,
  academyAddress,
  onAcademyNameClick,
}) => {
  const dispatch = useDispatch();
  const { coaches, superAdmin } = useSelector((state) => state.acadmyList);
  const [isModified, setIsModified] = useState(false);
  const [isModifiedSuperAdmin, setIsModifiedSuperAdmin] = useState(false);

  const ref = useRef(null);
  useDetectOutsideClick(ref, onClose);

  useEffect(() => {
    dispatch(getCoachesAPI({ slug }));
  }, [dispatch, slug]);

  const handleApply = () => {
    if (isModifiedSuperAdmin) {
      dispatch(
        changeSuperAdminRoleAPI({ slug, body: { coachId: superAdmin._id } })
      );
    }
    if (isModified) {
      let _coaches = coaches.map((c) => ({
        coach: c.coach._id,
        isAdmin: c.isAdmin,
      }));
      dispatch(changeCoachRoleAPI({ slug, body: { coaches: _coaches } }));
    }
    setIsModified(false);
    setIsModifiedSuperAdmin(false);
  };

  const headingOfTable = ["Name", "ROLE", "EMAIL", "PHONE"];

  const onChangeRole = (coach) => (e) => {
    // console.log(e.target.value);
    // console.log(coach);
    const role = e.target.value;

    const isAdmin = role === "Admin";
    const isSuperAdmin = role === "Superadmin";

    if (coach.isSuperAdmin && !isSuperAdmin) {
      dispatch(superAdminToCoachOrAdminRole({ id: coach._id, isAdmin }));
      setIsModifiedSuperAdmin(true);
      setIsModified(true);
    }
    if (!coach.isSuperAdmin && isSuperAdmin) {
      dispatch(toSuperAdminRole({ id: coach._id }));
      setIsModifiedSuperAdmin(true);
      setIsModified(true);
    }
    if (!coach.isSuperAdmin && !isSuperAdmin && coach.isAdmin !== isAdmin) {
      dispatch(changeCoachRole({ id: coach._id, isAdmin }));
      setIsModified(true);
    }
  };

  const rows = [];

  if (superAdmin) {
    console.log(superAdmin, "Super Admin");
    rows.push({
      name: (
        <Box style={{ display: "flex", gap: "4%", marginTop: "5%" }}>
          {/* <img src={IconForSuperAdmin} alt="IconSuperAdmin" /> */}
          {/* <Typography sx={styles.superAdmin}>Super Admin</Typography> */}
          <Typography sx={styles.superAdminName}>
            {superAdmin?.fullName}
          </Typography>
          <img
            style={{ marginLeft: "4%" }}
            src={IconForEdit}
            alt="IconForEdit"
          />
        </Box>
      ),
      role: (
        <RoleDrpdown
          key={superAdmin?._id}
          onChange={onChangeRole({
            ...superAdmin,
            isAdmin: false,
            isSuperAdmin: true,
          })}
          selected={"Superadmin"}
        />
      ),
      email: <Typography>{superAdmin.email}</Typography>,
      phone: <Typography>{superAdmin.phoneNumber}</Typography>,
    });
  }

  coaches?.forEach((c) => {
    console.log(c?.coach, c.isAdmin ? "Admin" : "Coach");
    if (!c.coach) return;
    rows.push({
      name: (
        <Box key={c.id} style={{ display: "flex", gap: "4%" }}>
          {/* <img src={IconForAdmin} alt="IconForAdmin" />
          <Typography sx={styles.admin}>
            {admin.isAdmin ? "Admin" : ""}
          </Typography> */}
          <Typography sx={styles.adminName}>{c.coach?.fullName}</Typography>
          <img
            style={{ marginLeft: "4%" }}
            src={IconForEdit}
            alt="IconForEdit"
          />
        </Box>
      ),
      // role: <Typography>{admin.isAdmin ? "Admin" : "Coach"}</Typography>,
      role: (
        <RoleDrpdown
          key={c.coach?._id}
          onChange={onChangeRole({
            ...c.coach,
            isAdmin: c.isAdmin,
            isSuperAdmin: false,
          })}
          selected={c.isAdmin ? "Admin" : "Coach"}
          desableSuperAdmin={!!superAdmin}
        />
      ),
      email: <Typography>{c.coach.email}</Typography>,
      phone: <Typography>{c.coach.phoneNumber}</Typography>,
    });
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        // alignItems: "center",
        width: "100%",
        height: "100vh",
        position: "fixed",
        // border: "5px solid red",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 9999,
      }}
    >
      <Box sx={styles.adminProfileMainBox} ref={ref}>
        <Stack direction={"row-reverse"}>
          <CloseRoundedIcon onClick={onClose} />
        </Stack>
        <Box sx={styles.headerContainer}>
          <img
            style={{ marginLeft: "3%" }}
            src={superAcademyIcon}
            alt="superAcademyIcon"
          />
          <Link
            to="#"
            onClick={onAcademyNameClick}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box sx={styles.textContainer}>
              <Typography sx={styles.academyName}>
                {`${academyName}`}
              </Typography>
              <Typography
                sx={styles.location}
              >{`${academyAddress.address}, ${academyAddress.city}`}</Typography>
            </Box>
          </Link>
        </Box>
        <SuperLoginTable headingOfTable={headingOfTable} bodyOfTable={rows} />
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          p={2}
        >
          <Button
            sx={{
              fontSize: "18px",
              textTransform: "capitalize",
            }}
            onClick={handleApply}
            disabled={!isModified && !isModifiedSuperAdmin}
          >
            Apply
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default Admin;
