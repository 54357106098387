import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "./styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomGroupSchema, ecomSegmentSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch , useSelector } from "react-redux";
import { imageUpload } from "../../../../Redux/slice/thunks";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CreateGroupFormField from "./createGroupFormField";
import { groupCreateAPI } from "../../../../Redux/slice/groupEcomList";

const GroupCreatePage = () => {
  
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    description: "",
    icon: "",
  };

  const handleSubmit = async (values) => {
    try {
      await formik.validateForm();
      const finalResponse = await dispatch(groupCreateAPI(values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomGroupSchema, handleSubmit);

  const handleFileChange = async (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("platform", "CRMS");

      try {
        const response = await dispatch(imageUpload(formData)).unwrap();
        const filePath = response?.fileLocations?.[0];
       
          formik.setFieldValue("icon", filePath);
        
        console.log({ response });
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };


  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Group</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <CreateGroupFormField
          label={"Name"}
          name="name"
          placeholder={"Enter Name"}
          handleChange={formik.handleChange}
          value={formik.values.name}
          errors={formik.errors.name}
          touched={formik.touched.name}
        />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <CreateGroupFormField
              label={"Icon"}
              name="icon"
              placeholder={"Icon"}
              handleChange={formik.handleChange}
              value={formik.values.icon}
              errors={formik.errors.icon}
              touched={formik.touched.icon}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
            component="label"
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, "icon")}
            />
          </CustomButtonComponent>
        </Box>

        <CreateGroupFormField
          label={"Description"}
          name="description"
          placeholder={"Enter Description"}
          handleChange={formik.handleChange}
          value={formik.values.description}
          errors={formik.errors.description}
          touched={formik.touched.description}
        />


<Box sx={{marginTop: "38px"}}></Box> 

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Create
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default GroupCreatePage;
