import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomPagination from "../../../../Components/CustomPagination";
import DataInsertIntoTable from "../../../../Components/CustomTableComponent/dataInsertIntoTable";
import { styles, tableHead } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../Assets/Icons/Drawer/editIcon.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { pinDeleteAPI, pinListAPI, pinStatusUpdate } from "../../../../Redux/slice/pinList";
import { downloadCSV } from "../../../../utils/utils";
import { updateStatus } from "../../../../Redux/slice/pinList";

const PinListPage = () => {
  const [pin, setPin] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [keyOfSort, setKeyOfSort] = useState("priority");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const head = tableHead;

  const PinData = useSelector((state) => state?.pinList?.data);
  const totalItems = PinData?.pageInfo?.itemCount;
  const totalPages = PinData?.pageInfo?.pageCount;
  const itemsPerPage = PinData?.pageInfo?.perPage;

  const handleExport = () => {
    const csvData = PinData?.data?.map((row) => ({
      id: row?._id,
      city: row?.city,
      circleName: row?.circleName,
      regionName: row?.regionName,
      divisionName: row?.divisionName,
      pincode: row?.pincode,
      district: row?.district,
      stateName: row?.stateName,
      status: row?.status,
      lat : row?.lat ,
      lng : row?.lng ,
      priority: row?.priority,
      createdAt: row?.createdAt,
      updatedAt: row?.updatedAt,
    }));
    downloadCSV(csvData, "PinData");
  };

  // const handleStatus = (id) => (event) => {
  //   console.log(event?.target?.checked);
  //   dispatch(pinStatusUpdate({ id, body: { active: event?.target?.checked } }));
  // };

  useEffect(() => {
    
  const handleStatus = (id) => (event) => {
    console.log(event?.target?.checked);
    dispatch(pinStatusUpdate({ id, body: { active: event?.target?.checked } }));
    dispatch(updateStatus({ id, status: event?.target?.checked }));
  };

    if (PinData) {
      let arr = PinData?.data?.map((pinCode) => {
        return {
          actions: [
            {
              content: (
                <Switch
                  checked={pinCode?.status}
                  onChange={handleStatus(pinCode?._id)}
                />
              ),
            },
            {
              content: (
                <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />
              ),
            },
            { type: "ButtonWithImg", content: editIcon },
          ],
          id: [{ type: "text", content: pinCode?._id }],
          city: [{ type: "text", content: pinCode?.city }],
          circleName: [{ type: "text", content: pinCode?.circleName }],
          regionName: [{ type: "text", content: pinCode?.regionName }],
          divisionName: [{ type: "text", content: pinCode?.divisionName }],
          pincode: [{ type: "text", content: pinCode?.pincode }],
          district: [{ type: "text", content: pinCode?.district }],
          priority: [{ type: "text", content: pinCode?.priority }],
          stateName: [{ type: "text", content: pinCode?.stateName }],
          lng: [{ type: "number", content: pinCode?.lng }],
          lat: [{ type: "number", content: pinCode?.lat }],
          createdAt: [{ type: "text", content: pinCode?.createdAt }],
          updatedAt: [{ type: "text", content: pinCode?.updatedAt }],
        };
      });
      setPin(arr);
      setLoading(false);
    }
  }, [PinData]);

  useEffect(() => {
    dispatch(pinListAPI({ page: currentPage }));
  }, [dispatch, currentPage]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!pin || pin.length === 0) {
    return (
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "red",
          fontFamily: "Avenir",
          fontSize: "40px",
          alignItems: "center",
        }}
      >
        No data available
      </Typography>
    );
  }

  const sortedBody = pin.slice().sort((a, b) => {
    const nameA = a[keyOfSort]?.content;
    const nameB = b[keyOfSort]?.content;

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = async () => {
    try {
      let arr1 = [...pin];
      arr1 = arr1.filter(
        (Object) => !selectedItems.includes(String(Object?.id[0].content))
      );
      setPin(arr1);
      for (let id of selectedItems) {
        dispatch(pinDeleteAPI({ id }));
      }
      setSelectedItems([]);
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>PinCode List</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox} onClick={handleExport}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.pagesDetailMainBox}>
          <Typography>
            <span style={{ color: "#171A1F", fontWeight: "700" }}>
              {pin.length}
            </span>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>
              in total
            </span>
          </Typography>

          <Typography sx={{ marginLeft: "70px" }}>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
            <span style={styles.pagesDetailNumber}>{(currentPage - 1) * itemsPerPage + 1}</span>
            {console.log(currentPage, itemsPerPage, totalItems)}
            <span style={styles.pagesDetailText}>to</span>
            <span style={styles.pagesDetailNumber}>
              {currentPage * itemsPerPage < totalItems ? currentPage * itemsPerPage : totalItems}
            </span>
            <span style={styles.pagesDetailText}>of</span>
            <span style={styles.pagesDetailNumber}>{totalItems}</span>
            <span style={styles.pagesDetailText}>results</span>
          </Typography>

          <Box textAlign="center" ml="auto">
            {selectedItems.length > 0 ? (
              <IconButton
                onClick={handleDelete}
                aria-label="delete"
                sx={{ color: "red" }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          pl: "6px",
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <DataInsertIntoTable
          checkData={selectedItems}
          setCheckData={setSelectedItems}
          headingOfTable={head}
          bodyOfTable={pin}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
          pin={true}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>
    </>
  );
};

export default PinListPage;
