

export const styles = {
  headingContainer: {
    pl: "6px",
    marginBottom: "10px",
  },
  header: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headingTitle: {
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "48px",
  },
  categoryFormBox: {
    marginY: "50px",
    backgroundColor: "#FFFFFF",
    ml: "6px",
    paddingY: "20px",
    paddingX: "40px",
    boxShadow: "8px 8px 9px 0px #0000001A",
  },
  editGenderFormUpdateButtonIcon: { marginRight: "5px", fontSize: "18px" },
  editGenderFormUpdateButton: {
    color: "primary.light",
    marginRight: "40px",
    textTransform: "capitalize",
    alignItems: "center",
  },
  editGenderFormResetButtonIcon: {
    marginRight: "5px",
    fontSize: "18px",
    transform: "rotate(270deg)",
  },
  editGenderFormResetButton: {
    color: "primary.light",
    backgroundColor: "#FF5B5B",
    textTransform: "capitalize",
  },
};





