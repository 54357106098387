import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { AGEGROUP_URLS } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};



export const ageGroupListAPI = createAsyncThunk("ageGroupListAPI", async () => {
  try {
    const response = await instance.get(`${AGEGROUP_URLS.LIST}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const AgeGroupDeleteAPI = createAsyncThunk(
  "ageGroupDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${AGEGROUP_URLS.LIST}/${data.id}`);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);


export const ageGroupGetById = createAsyncThunk(
  "ageGroupGetById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(AGEGROUP_URLS.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

export const ageGroupStatusUpdate = createAsyncThunk(
  "ageGroupStatusUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        AGEGROUP_URLS.UPDATE_STATUS(data.id),
        data.body
      );
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

export const ageGroupEditAPI = createAsyncThunk(
  "ageGroupEdit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        AGEGROUP_URLS.EDIT(data.id),
        data.formdata
      );
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


export const ageGroupCreateAPI = createAsyncThunk(
  "ageGroupCreate",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${AGEGROUP_URLS.CREATE}`, formdata);
      // console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


const ageGroup = createSlice({
  name: "ageGroupList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.status,
          };
        }
        return item;
      });
      state.data = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ageGroupListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(ageGroupListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
    });
    builder.addCase(ageGroupListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { updateStatus } = ageGroup.actions;
export default ageGroup.reducer;
