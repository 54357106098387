import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrowIcon from "../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomPagination from "../../../Components/CustomPagination";
import DataInsertIntoTable from "../../../Components/CustomTableComponent/dataInsertIntoTable";
import { styles, tableHead } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { sellerListAPI, deleteSeller } from "../../../Redux/slice/sellerSlice";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { downloadCSV } from "../../../utils/utils";
import { useSearchParams } from "react-router-dom";

function SellersList() {
  const [sellers, setSellers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);

  const sellersData = useSelector((state) => state.seller.data);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [keyOfSort, setKeyOfSort] = useState("company_name");
  const [currentPage, setCurrentPage] = useState(1);
  const head = tableHead;
  const sortedBody = sellers?.sort((a, b) => {
    const nameA = a[keyOfSort][0]?.content?.toUpperCase();
    const nameB = b[keyOfSort][0]?.content?.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const totalItems = sellersData?.pageInfo?.itemCount;
  const totalPages = sellersData?.pageInfo?.pageCount;
  const itemsPerPage = sellersData?.pageInfo?.perPage;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleExport = () => {
    const csv = sellersData?.data?.map((row) => {
      return {};
    });
    downloadCSV(csv, "sellerList");
  };

  const handleDelete = async () => {
    for (let id of selectedItems) {
      dispatch(deleteSeller({ id: id }));
    }
  };

  useEffect(() => {
    let arr = sellersData?.data?.map((seller) => {
      return {
        seller: [
          {
            content: {
              // img: editIcon,
              name: seller?.user?.fullName,
              phoneNumber: seller?.user?.phoneNumber,
              verified: seller.isOnboarded,
            },
            type: "verifiedDetails",
          },
        ],
        id: [{ content: seller._id, type: "text" }],
        company_name: [{ content: seller.companyName, type: "text" }],
        status: [
          { type: "text", content: seller.isOnboarded ? "Active" : "Inactive" },
        ],
      };
    });
    setSellers(arr);
    setLoading(false);
  }, [sellersData, dispatch]);

  useEffect(() => {
    let q = searchParams.get("q") || "";
    dispatch(sellerListAPI({ search: q, page: currentPage }));
  }, [dispatch, currentPage, searchParams]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Sellers List</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox} onClick={() => {}}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText} onClick={handleExport}>
                Export
              </Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.pagesDetailMainBox}>
          <Typography>
            <span style={{ color: "#171A1F", fontWeight: "700" }}>
              {itemsPerPage}
            </span>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>
              in total
            </span>
          </Typography>

          <Typography sx={{ marginLeft: "70px" }}>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
            <span style={styles.pagesDetailNumber}>
              {(currentPage - 1) * itemsPerPage}
            </span>
            <span style={styles.pagesDetailText}>to</span>
            <span style={styles.pagesDetailNumber}>
              {currentPage * itemsPerPage}
            </span>
            <span style={styles.pagesDetailText}>of</span>
            <span style={styles.pagesDetailNumber}>{totalItems}</span>
            <span style={styles.pagesDetailText}>results</span>
          </Typography>

          <Box mt={2} textAlign="center" ml="auto">
            {selectedItems?.length > 0 ? (
              <IconButton
                onClick={handleDelete}
                aria-label="delete"
                sx={{ color: "red" }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          pl: "6px",
          display: "flex",
          //overflowX: "hidden",
          // width : "calc(99vw - 310px)" ,
          overflowX: "auto",
          whiteSpace: "wrap",
        }}
      >
        <DataInsertIntoTable
          checkData={selectedItems}
          setCheckData={setSelectedItems}
          headingOfTable={head}
          // bodyOfTable={sellers}
          bodyOfTable={sortedBody}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
          sellerList={true}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>
    </>
  );
}

export default SellersList;
