import { SERVICES } from "./API/urlConstants";

export const BASE_URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = `${SERVICES.RBAC_SERVICE}/auth/authenticate`;
export const AUTH_URL = `${SERVICES.RBAC_SERVICE}/auth/validate-token`;
export const DETAILS_URL = "crms/createStoreDetail";
export const STORELIST_URL = "crms/store-list";
export const TARGETING_URL = "crms/createStoreTargeting";
export const PAYOUT_URL = "crms/createStorePayout";
export const TRACKING_URL = "crms/createStoreOnlineTracking";
export const SETTING_URL = "crms/settings";
export const COCHING_PERIODS_ENUMS = `${SERVICES.COACH_SERVICE}/signup/coachingPeriods`;

export const USERLIST_URL = "crms/users";

export const ACADEMY_URLS = {
  LIST: (page) =>
    `${SERVICES.ACADEMY_SERVICE}/academies/all?page=${page}&limit=10`,
  SEARCH: (search, page) =>
    `${SERVICES.ACADEMY_SERVICE}/academies/all?page=${page}&limit=10&q=${search}`,
  GET: (slug, id) => `${SERVICES.ACADEMY_SERVICE}/${slug}/profile/?id=${id}`,
  UPDATE: (slug, id) =>
    `${SERVICES.ACADEMY_SERVICE}/${slug}/profile/update?id=${id}`,
  STATUS: (slug, id) => `${SERVICES.ACADEMY_SERVICE}/${slug}/status?id=${id}`,
  DELETE: (slug, id) =>
    `${SERVICES.ACADEMY_SERVICE}/academies/${slug}/delete?id=${id}`,
  GET_COACHES: (slug, id) =>
    `${SERVICES.ACADEMY_SERVICE}/${slug}/coaches?id=${id}`,
  CHANGE_SUPER_ADMIN_ROLE: (slug, id) =>
    `${SERVICES.ACADEMY_SERVICE}/academies/${slug}/change-superadmin?id=${id}`,
  CHANGE_COACH_ROLE: (slug, id) =>
    `${SERVICES.ACADEMY_SERVICE}/${slug}/coaches?id=${id}`,
  FILTER: `${SERVICES.ACADEMY_SERVICE}/academies/filtered`,
};

export const IMG_URL = {
  UPLOAD: `${SERVICES.UTILITY_SERVICE}/file/upload-file`,
};

export const AGEGROUP_URLS = {
  LIST: `${SERVICES.WEBSITE_MASTER_SERVICE}/age-group?sort=-priority`,
  CREATE: `${SERVICES.WEBSITE_MASTER_SERVICE}/age-group/create`,
  UPDATE_STATUS: (id) =>
    `${SERVICES.WEBSITE_MASTER_SERVICE}/age-group/${id}/status`,
  EDIT: (id) => `${SERVICES.WEBSITE_MASTER_SERVICE}/age-group/${id}`,
};

export const SPORTS_URLS = {
  LIST: (page) =>
    `${SERVICES.WEBSITE_MASTER_SERVICE}/sport?page=${page}&limit=10&sort=-priority`,
  SEARCH: (search, page) =>
    `${SERVICES.WEBSITE_MASTER_SERVICE}/sport?page=${page}&limit=10&q=${search}`,
  CREATE: `${SERVICES.WEBSITE_MASTER_SERVICE}/sport/create`,
  GET: (id) => `${SERVICES.WEBSITE_MASTER_SERVICE}/sport/${id}`,
  UPDATE_STATUS: (id) =>
    `${SERVICES.WEBSITE_MASTER_SERVICE}/sport/${id}/status`,
  EDIT: (id) => `${SERVICES.WEBSITE_MASTER_SERVICE}/sport/${id}`,
};

export const PIN_URLS = {
  LIST: (page) =>
    `${SERVICES.WEBSITE_MASTER_SERVICE}/pincode?page=${page}&limit=10&sort=-priority`,
  SEARCH: (search, page) =>
    `${SERVICES.WEBSITE_MASTER_SERVICE}/pincode?page=${page}&limit=10&q=${search}`,
  CREATE: `${SERVICES.WEBSITE_MASTER_SERVICE}/pincode/create`,
  GET: (id) => `${SERVICES.WEBSITE_MASTER_SERVICE}/pincode/${id}`,
  UPDATE_STATUS: (id) =>
    `${SERVICES.WEBSITE_MASTER_SERVICE}/pincode/${id}/status`,
  EDIT: (id) => `${SERVICES.WEBSITE_MASTER_SERVICE}/pincode/${id}`,
};

export const TIMESLOT_URLS = {
  LIST: `${SERVICES.WEBSITE_MASTER_SERVICE}/time-period?sort=-priority`,
  CREATE: `${SERVICES.WEBSITE_MASTER_SERVICE}/time-period/create`,
  UPDATE_STATUS: (id) =>
    `${SERVICES.WEBSITE_MASTER_SERVICE}/time-period/${id}/status`,
  EDIT: (id) => `${SERVICES.WEBSITE_MASTER_SERVICE}/time-period/${id}`,
};

export const COACH_URLS = {
  LIST: (page) => `${SERVICES.COACH_SERVICE}/caoches/all?page=${page}&limit=10`,
  SEARCH: (search, page) =>
    `${SERVICES.COACH_SERVICE}/caoches/all?page=${page}&limit=10&q=${search}`,
  STATUS: (slug, id) =>
    `${SERVICES.COACH_SERVICE}/settings/live?slug=${slug}&id=${id}`,
  DELETE: (slug, id) =>
    `${SERVICES.COACH_SERVICE}/caoches/delete?slug=${slug}&id=${id}`,
  FILTER: `${SERVICES.COACH_SERVICE}/caoches/filtered`,
};

export const COACHING_TYPE_URLS = {
  LIST: `${SERVICES.WEBSITE_MASTER_SERVICE}/coaching-type?sort=-priority`,
  CREATE: `${SERVICES.WEBSITE_MASTER_SERVICE}/coaching-type/create`,
  EDIT: (id) => `${SERVICES.WEBSITE_MASTER_SERVICE}/coaching-type/${id}`,
  UPDATE_STATUS: (id) =>
    `${SERVICES.WEBSITE_MASTER_SERVICE}/coaching-type/${id}/status`,
};

export const SPORTS_CATEGORY_URLS = {
  LIST: `${SERVICES.WEBSITE_MASTER_SERVICE}/sport-category?sort=-priority`,
  CREATE: `${SERVICES.WEBSITE_MASTER_SERVICE}/sport-category/create`,
  EDIT: (id) => `${SERVICES.WEBSITE_MASTER_SERVICE}/sport-category/${id}`,
  UPDATE_STATUS: (id) =>
    `${SERVICES.WEBSITE_MASTER_SERVICE}/sport-category/${id}/status`,
};

export const LANGUAGE_URLS = {
  LIST: `${SERVICES.WEBSITE_MASTER_SERVICE}/language?sort=-priority`,
  CREATE: `${SERVICES.WEBSITE_MASTER_SERVICE}/language/create`,
  EDIT: (id) => `${SERVICES.WEBSITE_MASTER_SERVICE}/language/${id}`,
  UPDATE_STATUS: (id) =>
    `${SERVICES.WEBSITE_MASTER_SERVICE}/language/${id}/status`,
};

export const GENDER_URLS = {
  LIST: `${SERVICES.WEBSITE_MASTER_SERVICE}/gender?sort=-priority`,
  CREATE: `${SERVICES.WEBSITE_MASTER_SERVICE}/gender/create`,
  EDIT: (id) => `${SERVICES.WEBSITE_MASTER_SERVICE}/gender/${id}`,
  UPDATE_STATUS: (id) =>
    `${SERVICES.WEBSITE_MASTER_SERVICE}/gender/${id}/status`,
};

export const COACH_PROFILE = {
  GET: (slug, id) => `${SERVICES.COACH_SERVICE}/profile?slug=${slug}&id=${id}`,
  UPDATE: (slug, id) =>
    `${SERVICES.COACH_SERVICE}/profile/update?slug=${slug}&id=${id}`,
};

export const USER_URLS = {
  LIST: (page) => `${SERVICES.RBAC_SERVICE}/users/all?page=${page}&limit=10`,
  SEARCH: (search, page) =>
    `${SERVICES.RBAC_SERVICE}/users/all?page=${page}&limit=10&q=${search}`,
};

export const OFFER_URLS = {
  CREATE: "crms/create-offer",
  LIST: "crms/offer-list",
};

export const CATEGORY_URLS = {
  CREATE: "crms/create-category",
  LIST: "crms/category-list",
};

export const BRAND_URLS = {
  LIST: (page, search) =>
    `${
      SERVICES.MASTER_SERVICE
    }/brand?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.MASTER_SERVICE}/brand`,
  EDIT: (id) => `${SERVICES.MASTER_SERVICE}/brand/${id}`,
  UPDATE: (id) => `${SERVICES.MASTER_SERVICE}/brand/${id}`,
  DELETE: (id) => `${SERVICES.MASTER_SERVICE}/brand/${id}`,
};

export const CATEGORY_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.PRODUCT_SERVICE
    }/category?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.PRODUCT_SERVICE}/category`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/category/${id}`, // get element by id
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/category/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/category/${id}`,
  DROP: `${SERVICES.PRODUCT_SERVICE}/category?paginate=true&page=1&limit=100000`,
};

export const SPORT_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.MASTER_SERVICE
    }/sport?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.MASTER_SERVICE}/sport`,
  EDIT: (id) => `${SERVICES.MASTER_SERVICE}/sport/${id}`,
  UPDATE: (id) => `${SERVICES.MASTER_SERVICE}/sport/${id}`,
  DELETE: (id) => `${SERVICES.MASTER_SERVICE}/sport/${id}`,
  DROP: `${SERVICES.MASTER_SERVICE}/sport?paginate=true&page=1&limit=100000`,
};

export const SEGMENT_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.PRODUCT_SERVICE
    }/segment?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.PRODUCT_SERVICE}/segment`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/segment/${id}`,
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/segment/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/segment/${id}`,
  DROP: `${SERVICES.PRODUCT_SERVICE}/segment?paginate=true&page=1&limit=100000`,
};

export const GROUP_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.PRODUCT_SERVICE
    }/group?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.PRODUCT_SERVICE}/group`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/group/${id}`, // get element by id
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/group/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/group/${id}`,
  DROP: `${SERVICES.PRODUCT_SERVICE}/group?paginate=true&page=1&limit=100000`,
};

export const SUBCATEGORY_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.PRODUCT_SERVICE
    }/sub-category?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.PRODUCT_SERVICE}/sub-category`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/sub-category/${id}`,
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/sub-category/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/sub-category/${id}`,
  DROP: `${SERVICES.PRODUCT_SERVICE}/sub-category?paginate=true&page=1&limit=100000`,
};

export const REASONS_URLS1 = {
  LIST: `${SERVICES.ORDER_SERVICE}/return-reason`,
};

export const OFFER_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.PRODUCT_SERVICE
    }/offer?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.PRODUCT_SERVICE}/offer`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/offer/${id}`,
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/offer/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/offer/${id}`,
  DROP: `${SERVICES.PRODUCT_SERVICE}/offer?paginate=true&page=1&limit=100000`,
};

export const SIZE_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.PRODUCT_SERVICE
    }/size?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.PRODUCT_SERVICE}/size`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/size/${id}`,
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/size/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/size/${id}`,
};

export const PRICE_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.PRODUCT_SERVICE
    }/price?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.PRODUCT_SERVICE}/price`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/price/${id}`,
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/price/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/price/${id}`,
};

export const PLATFORM_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.MASTER_SERVICE
    }/platform?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.MASTER_SERVICE}/platform`,
  EDIT: (id) => `${SERVICES.MASTER_SERVICE}/platform/${id}`,
  UPDATE: (id) => `${SERVICES.MASTER_SERVICE}/platform/${id}`,
  DELETE: (id) => `${SERVICES.MASTER_SERVICE}/platform/${id}`,
};

export const SPECIFICATION_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.PRODUCT_SERVICE
    }/specification?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.PRODUCT_SERVICE}/specification`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/specification/${id}`,
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/specification/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/specification/${id}`,
};

export const COLOR_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.PRODUCT_SERVICE
    }/color?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.PRODUCT_SERVICE}/color`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/color/${id}`,
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/color/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/color/${id}`,
};

export const DISCOUNT_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.PRODUCT_SERVICE
    }/discount?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.PRODUCT_SERVICE}/discount`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/discount/${id}`,
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/discount/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/discount/${id}`,
  DROP: `${SERVICES.PRODUCT_SERVICE}/discount?paginate=true&page=1&limit=100000`,
};

export const TAXCODE_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.SELLER_SERVICE
    }/tax-code?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.SELLER_SERVICE}/tax-code`,
  EDIT: (id) => `${SERVICES.SELLER_SERVICE}/tax-code/${id}`,
  UPDATE: (id) => `${SERVICES.SELLER_SERVICE}/tax-code/${id}`,
  DELETE: (id) => `${SERVICES.SELLER_SERVICE}/tax-code/${id}`,
};

export const SHIPPINGMETHODS_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.SELLER_SERVICE
    }/shipping-method?paginate=true&page=${page}&limit=25&search=${
      search || ""
    }`,
  CREATE: `${SERVICES.SELLER_SERVICE}/shipping-method`,
  EDIT: (id) => `${SERVICES.SELLER_SERVICE}/shipping-method/${id}`,
  UPDATE: (id) => `${SERVICES.SELLER_SERVICE}/shipping-method/${id}`,
  DELETE: (id) => `${SERVICES.SELLER_SERVICE}/shipping-method/${id}`,
};

export const BRAND_ADV_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.MASTER_SERVICE
    }/brand-advertisment?paginate=true&page=${page}&limit=25&search=${
      search || ""
    }`,
  CREATE: `${SERVICES.MASTER_SERVICE}/brand-advertisment`,
  EDIT: (id) => `${SERVICES.MASTER_SERVICE}/brand-advertisment/${id}`,
  UPDATE: (id) => `${SERVICES.MASTER_SERVICE}/brand-advertisment/${id}`,
  DELETE: (id) => `${SERVICES.MASTER_SERVICE}/brand-advertisment/${id}`,
};

export const REVIEW_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.PRODUCT_SERVICE
    }/review?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.PRODUCT_SERVICE}/review`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/review/${id}`,
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/review/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/review/${id}`,
};

export const PRODUCT_URLS1 = {
  //LIST : (page , search)=>`${SERVICES.PRODUCT_SERVICE}/product?paginate=true&page=${page}&limit=25&search=${search||""}`,
  LIST: `${SERVICES.PRODUCT_SERVICE}/product`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/product/${id}`,
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/product/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/product/${id}`,
};

export const ATTRIBUTE_URLS1 = {
  LIST: (page, search) =>
    `${
      SERVICES.PRODUCT_SERVICE
    }/attribute?paginate=true&page=${page}&limit=25&search=${search || ""}`,
  CREATE: `${SERVICES.PRODUCT_SERVICE}/attribute`,
  EDIT: (id) => `${SERVICES.PRODUCT_SERVICE}/attribute/${id}`,
  UPDATE: (id) => `${SERVICES.PRODUCT_SERVICE}/attribute/${id}`,
  DELETE: (id) => `${SERVICES.PRODUCT_SERVICE}/attribute/${id}`,
};

export const SELLER_URLS = {
  LIST: (page) => `${SERVICES.SELLER_SERVICE}/seller?page=${page}&limit=10`,
  GET: (id) => `${SERVICES.SELLER_SERVICE}/seller/${id}`,
  GET_ORDERS: (id) => `${SERVICES.ORDER_SERVICE}/order/getAllOrders?sellerId=${id}`,
  GET_PRODUCTS: (id) => `${SERVICES.PRODUCT_SERVICE}/product?sellerId=${id}`,
  GET_BRANDS: (id) => `${SERVICES.MASTER_SERVICE}/brand?sellerId=${id}`,
  APPROVE_PROD_BRAND: (requestId) => `${SERVICES.SELLER_SERVICE}/approval-request/${requestId}`,
  APPROVAL_REQUESTS: (id) => `${SERVICES.SELLER_SERVICE}/approval-request?sellerId=${id}`,
  GET_APPROVAL_REQUEST: (id) => `${SERVICES.SELLER_SERVICE}/approval-request/${id}`,
};
