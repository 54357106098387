import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "./styles";
import CreateSportFormField from "./createSportFormField";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomSportsSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch } from "react-redux";
import { imageUpload } from "../../../../Redux/slice/thunks";
import { sportEcomCreateAPI } from "../../../../Redux/slice/sportEcomList";

const SportEcomCreatePage = () => {
  const dispatch = useDispatch();
  // const [sports, setSports] = useState("");
  // const [sportsCategoryId, setSportsCategoryId] = useState([]);

  const initialValues = {
    name:"",
    title:"",
    description:"",
    icon:"",
    banner:"",
    // sportCategory:""
  };

  const handleSubmit = async (values) => {
    try {
      await formik.validateForm();
      const finalResponse = await dispatch(sportEcomCreateAPI(values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomSportsSchema, handleSubmit);

  // const handleChange = (event) => {
  //   setSports(event.target.value);
  //   formik.setFieldValue("sportCategory", event.target.value);
  // };

  const handleFileChange = async (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("platform", "CRMS");

      try {
        const response = await dispatch(imageUpload(formData)).unwrap();
        const filePath = response?.fileLocations?.[0];
        if (type === "icon") {
          formik.setFieldValue("icon", filePath);
        } else if (type === "banner") {
          formik.setFieldValue("banner", filePath);
        }
        console.log({ response });
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };


  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Sports</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <CreateSportFormField
          label={"Name"}
          name="name"
          placeholder={"Enter Name"}
          handleChange={formik.handleChange}
          value={formik.values.name}
          errors={formik.errors.name}
          touched={formik.touched.name}
        />

       <CreateSportFormField
          label={"Title"}
          name="title"
          placeholder={"Enter Name"}
          handleChange={formik.handleChange}
          value={formik.values.title}
          errors={formik.errors.title}
          touched={formik.touched.title}
        />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <CreateSportFormField
              label={"Icon"}
              name="icon"
              placeholder={"Icon"}
              handleChange={formik.handleChange}
              value={formik.values.icon}
              errors={formik.errors.icon}
              touched={formik.touched.icon}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
            component="label"
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, "icon")}
            />
          </CustomButtonComponent>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <CreateSportFormField
              label={"Banner"}
              name="banner"
              placeholder={"Banner"}
              handleChange={formik.handleChange}
              value={formik.values.banner}
              errors={formik.errors.banner}
              touched={formik.touched.banner}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
            component="label"
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, "banner")}
            />
          </CustomButtonComponent>
        </Box>

        <CreateSportFormField
          label={"Description"}
          name="description"
          placeholder={"Enter Description"}
          handleChange={formik.handleChange}
          value={formik.values.description}
          errors={formik.errors.description}
          touched={formik.touched.description}
        />

       {/* <Box>
          <Box sx={{ minWidth: 120, marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Sports Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sports}
                label="Sports Category"
                onChange={handleChange}
              >
                {sportsCategoryId?.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box> */}


<Box sx={{marginTop: "38px"}}></Box> 

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Create
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default SportEcomCreatePage;
