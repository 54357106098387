import { Avatar, Box, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import OrdersList from "./OrdersList";
import ProductsList from "./ProductsList";
import BrandApprovalList from "./BrandApprovalList";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sellerProfileAPI } from "../../../Redux/slice/sellerSlice";
import moment from "moment/moment";

function SellerProfile() {
  const [tab, setTab] = useState("1");
  const [searchParams] = useSearchParams();
  const sellerData = useSelector((state) => state.seller?.seller);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      dispatch(sellerProfileAPI({ id }));
    }
  }, [dispatch, searchParams]);

  return (
    <Box
      sx={{
        marginTop: "20px",
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        width: "75vw"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          padding: "10px",
        }}
      >
        <ArrowBackIcon onClick={() => navigate(-1)} />
        <Typography variant="h5">Seller Profile</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          padding: "10px",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            padding: "10px",
            alignItems: "center",
          }}
        >
          <Avatar
            alt="Remy Sharp"
            sx={{
              width: "50px",
              height: "50px",
              bgcolor: "deepOrange[500]",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "150px",
            }}
          >
            <Typography
              variant="h6"
              lineHeight={"24px"}
              overflow={"hidden"}
              noWrap
              textOverflow={"ellipsis"}
            >
              {sellerData?.user?.fullName}
            </Typography>
            <Typography
              variant="subtitle2"
              overflow={"hidden"}
              noWrap
              textOverflow={"ellipsis"}
            >
              {sellerData?.user?.email}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#2E8760",
              backgroundColor: "#E3FFF3",
              padding: "5px 10px",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                width: "10px",
                height: "10px",
                display: "flex",
                backgroundColor: "#2E8760",
              }}
            />
            Online
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#8A8A8A",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              gap: "5px",
            }}
          >
            <ScheduleIcon />
            Joined {moment(sellerData?.user?.createdAt).format("DD MMM YYYY")}
          </Typography>
          <Typography
            sx={{
              color: "#2E8760",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              gap: "5px",
            }}
          >
            Logged in Since 8:30 | 23 Sep 2023
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#242424",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              gap: "5px",
            }}
          >
            Total Orders : 245
          </Typography>
          <Typography
            sx={{
              color: "#F87E0E",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              gap: "5px",
            }}
          >
            Returns Processed : 120
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#55DE64",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              gap: "5px",
            }}
          >
            Processed : 25
          </Typography>
          <Typography
            sx={{
              color: "#E2A925",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              gap: "5px",
            }}
          >
            Pending : 125
          </Typography>
          <Typography
            sx={{
              color: "#F93333",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              gap: "5px",
            }}
          >
            Cancelled : 25
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "15px",
          flexDirection: "column",
        }}
      >
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Orders" value="1" color="#3D70F5" />
              <Tab label="Products" value="2" />
              <Tab label="Brand Approvals" value="3" />
            </TabList>
          </Box>
          <Box >
            <TabPanel value="1">
              <OrdersList />
            </TabPanel>
            <TabPanel value="2">
              <ProductsList />
            </TabPanel>
            <TabPanel value="3">
              <BrandApprovalList />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
}

export default SellerProfile;
