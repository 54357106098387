import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import TimeInput from "./timeInput";
import InputError from "./inputError";

function SelectTimeSlot({
  formik,
  heading,
  timeSlotList,
  coachingType,
  addressIndex,
}) {
  const isChecked = (slotId) => {
    return formik.values.addresses[addressIndex].timeSlots?.find(
      (slot) => slot.slotPeriod === slotId && slot.coachingType === coachingType
    )
      ? true
      : false;
  };

  const handleTimeSlot = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      const slot = {
        coachingType: coachingType,
        slotPeriod: name,
        timing: {
          from: "",
          to: "",
        },
      };
      const values = [...formik.values.addresses[addressIndex].timeSlots, slot];
      formik.setFieldValue(`addresses[${addressIndex}].timeSlots`, [...values]);
    } else {
      const values = formik.values.addresses[addressIndex].timeSlots.filter(
        (slot) => slot.slotPeriod !== name || slot.coachingType !== coachingType
      );
      formik.setFieldValue(`addresses[${addressIndex}].timeSlots`, [...values]);
    }
  };

  const handleTimeChange = (find) => (time, name) => {
    const index = formik.values.addresses[addressIndex].timeSlots?.findIndex(
      (slot) => slot.slotPeriod === find && slot.coachingType === coachingType
    );
    if (index === null || index < 0) return;
    const values = [...formik.values.addresses[addressIndex].timeSlots];

    values[index] = {
      ...values[index],
      timing: { ...values[index].timing, [name]: time },
    };

    formik.setFieldValue(`addresses[${addressIndex}].timeSlots`, [...values]);
  };

  const getTimeValue = (find) => {
    const index = formik.values?.addresses[addressIndex].timeSlots?.findIndex(
      (slot) => slot.slotPeriod === find && slot.coachingType === coachingType
    );
    if (index === null || index < 0) return;
    return formik.values.addresses[addressIndex]?.timeSlots[index]?.timing;
  };

  const handleBlur = (find) => (e) => {};

  return (
    <Box sx={{ marginTop: "45px", marginBottom: "24px" }}>
      <Typography
        sx={{
          fontWeight: 600,
        }}
      >
        {heading}
      </Typography>
      <Typography sx={{ marginBottom: "24px" }}>
        *You can select multiple time slots
      </Typography>
      <FormGroup>
        {timeSlotList?.map((timeS, i) => (
          <div key={i}>
            <FormControlLabel
              label={timeS.label?.toLowerCase()}
              sx={{ textTransform: "capitalize" }}
              control={
                <Checkbox
                  disabled={!formik.values.addresses[addressIndex].coachingType}
                  name={timeS._id}
                  checked={isChecked(timeS._id)}
                  onChange={handleTimeSlot}
                  sx={{
                    "&.Mui-checked": {
                      color: "#242424",
                    },
                  }}
                />
              }
            />
            <Grid
              container
              direction={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "flex-start", sm: "center" }}
              gap={{ xs: "0px", sm: "16px" }}
              sx={{
                width: "100%",
                marginLeft: { xs: "24px", sm: "16px" },
              }}
            >
              <TimeInput
                name={`from`}
                disabled={!isChecked(timeS._id)}
                setTime={handleTimeChange(timeS._id)}
                value={getTimeValue(timeS._id)?.from || ""}
                handleBlur={handleBlur(timeS._id)}
                timeLimit={timeS.slot}
              />
              to
              <TimeInput
                name={`to`}
                disabled={!isChecked(timeS._id)}
                setTime={handleTimeChange(timeS._id)}
                value={getTimeValue(timeS._id)?.to || ""}
                handleBlur={handleBlur(timeS._id)}
                timeLimit={timeS.slot}
              />
            </Grid>
          </div>
        ))}
      </FormGroup>
      {(formik.touched.addresses?.length > 0 &&
        formik.errors.addresses?.length > 0 &&
        formik.touched.addresses[addressIndex]?.timeSlots &&
        formik.errors.addresses[addressIndex]?.timeSlots) ||
        (formik.values.addresses[addressIndex]?.timeSlots.length === 0 && (
          <InputError error={"* This field is mandatory"} />
        ))}
    </Box>
  );
}

export default SelectTimeSlot;
