import React, { useEffect, useState } from "react";
import { Box, Typography, MenuItem, FormControl, InputLabel, Select, OutlinedInput, Checkbox, ListItemText } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "./styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch, useSelector } from "react-redux";
import { productListAPI } from "../../../../Redux/slice/productEcom";
//import { collectionCreateAPI} from "../../../../Redux/slice/collectionSlice";
import CreateFormField from "../../CustomFormFields/CreateFormField";
import { CollectionFormSchema } from "../../../../Schemas";

const collectionTypes = [
  "LATEST", "FEATURED", "POPULAR", "TRENDING", "RECOMMENDED", "BESTSELLER", "OFFER", "SALE", "NEW", "HOT", "MONTHLY PRODUCT"
];

const CollectionCreatePage = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);


  const dispatch = useDispatch();
  const productData = useSelector((state) => state.productEcomList.data);

  const initialValues = {
    collectionType: "",
    products: [],
  };

  const handleSubmit = async (values) => {
    try {
      await formik.validateForm();
     // const finalResponse = await dispatch(collectionCreateAPI(values));
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, CollectionFormSchema, handleSubmit);


  const handleProductSelection = (event) => {
    const { target: { value } } = event;
    setSelectedProducts(typeof value === "string" ? value.split(",") : value);
    formik.setFieldValue("products", typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    dispatch(productListAPI());
  }, [dispatch]);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Collection</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
      <Box>
          <FormControl fullWidth sx={{ marginTop: "20px" }}>
            <InputLabel id="collection-type-label">Collection Type</InputLabel>
            <Select
              labelId="collection-type-label"
              id="collection-type-select"
              value={formik.values.collectionType}
              name="collectionType"
              label="Collection Type"
              onChange={formik.handleChange}
            >
              {collectionTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box>
          <FormControl fullWidth sx={{ marginTop: "20px" }}>
            <InputLabel id="product-select-label">Products</InputLabel>
            <Select
              labelId="product-select-label"
              id="product-select"
              multiple
              value={formik.values.products}
              onChange={formik.handleChange}
              name="products"
              input={<OutlinedInput label="Products" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {productData?.map((product) => (
                <MenuItem key={product._id} value={product._id}>
                  <Checkbox checked={formik.values.products.includes(product._id)}/>
                  <ListItemText primary={product.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
       
        <Box sx={{marginTop: "38px"}}></Box> 

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Create
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default CollectionCreatePage;