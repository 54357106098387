import { useDispatch, useSelector } from "react-redux";
import Layout from "../Layout";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { authenticateAPI } from "../Redux/loginSlice";

const PrivateRoute = ({ children, ...rest }) => {

  const userData = useSelector((state) => state.login);
 const dispatch = useDispatch() ; 
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData?.currentUser?.accessToken) {
      navigate("/");
    }
    
  }, [navigate, userData]);

  useEffect(()=>{
      dispatch(authenticateAPI())
  }, [dispatch]) ;

  return (
    <Layout>{children}</Layout>
  )
 };

export default PrivateRoute;
