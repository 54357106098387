import React, { useState } from "react";
import { Box, Typography, IconButton, Switch } from "@mui/material";
import { styles } from "./styles";
import CustomButtonComponent from "../../../../../Components/CustomButton";
import StoreTextFormField from "./storeTextFormField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomFormikHook from "../../../../../Hooks/CustomFormikHook";
import { storeDetailFormSchema } from "../../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch } from "react-redux";
import { createDetailsAPI } from "../../../../../Redux/slice/createDetails";


const CreateStoreDetails = ({ handleNext }) => {
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
    title: "",
    description: "",
    type: "",
    rtoLimit: "",
    rtoPeriod: "",
    icon: "",
    banner: "",
    avhText: "",
    avh: "",
    trackingTimeText: "",
    trackingTime: "",
    trackingTimeUnits: "",
    subCategories: "",
    previewUrl: "",
    advertiseUrl: "",
    advertiseID: "",
    priority: "",
    status: false,
  };
 
  const handleChange = () => {
    handleNext();
  };

  const formik = CustomFormikHook(initialValues, storeDetailFormSchema);

 

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await formik.validateForm();
      formik.handleSubmit();
      const finalResponse = await dispatch(createDetailsAPI(formik.values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box sx={styles.createFormBox}>
        <StoreTextFormField
          label={"Name"}
          name={"name"}
          placeholder={"Enter Name"}
          handleChange={formik.handleChange}
          value={formik.values.name}
          errors={formik.errors.name}
          touched={formik.touched.name}
        />
        <StoreTextFormField
          label={"Title"}
          name={"title"}
          placeholder={"Enter Title"}
          handleChange={formik.handleChange}
          value={formik.values.title}
          errors={formik.errors.title}
          touched={formik.touched.title}
        />
        <StoreTextFormField
          label={"Description"}
          name={"description"}
          placeholder={"Enter Description"}
          handleChange={formik.handleChange}
          value={formik.values.description}
          errors={formik.errors.description}
          touched={formik.touched.description}
        />
        <StoreTextFormField
          label={"Type"}
          name={"type"}
          placeholder={"Enter Type"}
          handleChange={formik.handleChange}
          value={formik.values.type}
          errors={formik.errors.type}
          touched={formik.touched.type}
        />
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "50%" }}>
            <StoreTextFormField
              label={"RTO Limit"}
              name={"rtoLimit"}
              placeholder={"Enter RTO Limit"}
              handleChange={formik.handleChange}
              value={formik.values.rtoLimit}
              errors={formik.errors.rtoLimit}
              touched={formik.touched.rtoLimit}
            />
          </Box>
          <Box sx={{ marginLeft: "20px", width: "50%" }}>
            <StoreTextFormField
              label={"RTO Period"}
              name={"rtoPeriod"}
              placeholder={"Enter RTO Period"}
              handleChange={formik.handleChange}
              value={formik.values.rtoPeriod}
              errors={formik.errors.rtoPeriod}
              touched={formik.touched.rtoPeriod}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <StoreTextFormField
              label={"Icon"}
              name={"icon"}
              placeholder={"Upload Icon"}
              handleChange={formik.handleChange}
              value={formik.values.icon}
              errors={formik.errors.icon}
              touched={formik.touched.icon}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
          </CustomButtonComponent>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <StoreTextFormField
              label={"Banner"}
              name={"banner"}
              placeholder={"Upload Banner"}
              handleChange={formik.handleChange}
              value={formik.values.banner}
              errors={formik.errors.banner}
              touched={formik.touched.banner}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
          </CustomButtonComponent>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "50%" }}>
            <StoreTextFormField
              label={"Average Hold Time (AVH) text"}
              name={"avhText"}
              placeholder={"Enter AVH Text"}
              handleChange={formik.handleChange}
              value={formik.values.avhText}
              errors={formik.errors.avhText}
              touched={formik.touched.avhText}
            />
          </Box>
          <Box sx={{ width: "50%", marginLeft: "20px" }}>
            <StoreTextFormField
              label={"AVH"}
              name={"avh"}
              placeholder={"Enter AVH"}
              handleChange={formik.handleChange}
              value={formik.values.avh}
              errors={formik.errors.avh}
              touched={formik.touched.avh}
            />
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "33%" }}>
            <StoreTextFormField
              label={"Tracking Time text"}
              name={"trackingTimeText"}
              placeholder={"Enter Tracking Time Text"}
              handleChange={formik.handleChange}
              value={formik.values.trackingTimeText}
              errors={formik.errors.trackingTimeText}
              touched={formik.touched.trackingTimeText}
            />
          </Box>
          <Box sx={{ width: "33%", marginLeft: "20px" }}>
            <StoreTextFormField
              label={"Tracking Time"}
              name={"trackingTime"}
              placeholder={"Enter Tracking Time"}
              handleChange={formik.handleChange}
              value={formik.values.trackingTime}
              errors={formik.errors.trackingTime}
              touched={formik.touched.trackingTime}
            />
          </Box>
          <Box sx={{ width: "33%", marginLeft: "20px" }}>
            <StoreTextFormField
              label={"Tracking Time Units"}
              name={"trackingTimeUnits"}
              placeholder={"Enter Tracking Time Units"}
              handleChange={formik.handleChange}
              value={formik.values.trackingTimeUnits}
              errors={formik.errors.trackingTimeUnits}
              touched={formik.touched.trackingTimeUnits}
            />
          </Box>
        </Box>

        <StoreTextFormField
          label={"Sub Categories"}
          name={"subCategories"}
          placeholder={"Enter Sub Categories"}
          handleChange={formik.handleChange}
          value={formik.values.subCategories}
          errors={formik.errors.subCategories}
          touched={formik.touched.subCategories}
        />

        <StoreTextFormField
          label={"Preview Url"}
          name={"previewUrl"}
          placeholder={"Enter URL"}
          handleChange={formik.handleChange}
          value={formik.values.previewUrl}
          errors={formik.errors.previewUrl}
          touched={formik.touched.previewUrl}
        />

        <StoreTextFormField
          label={"Advertise Url"}
          name={"advertiseUrl"}
          placeholder={"Enter URL"}
          handleChange={formik.handleChange}
          value={formik.values.advertiseUrl}
          errors={formik.errors.advertiseUrl}
          touched={formik.touched.advertiseUrl}
        />

        <StoreTextFormField
          label={"Advertise ID"}
          name={"advertiseID"}
          placeholder={"Enter Advertise ID"}
          handleChange={formik.handleChange}
          value={formik.values.advertiseID}
          errors={formik.errors.advertiseID}
          touched={formik.touched.advertiseID}
        />

        <CustomButtonComponent
          variant={"outlined"}
          sx={{
            height: "45px",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Typography>+Add TnC</Typography>
        </CustomButtonComponent>

        <StoreTextFormField
          label={"Priority"}
          name={"priority"}
          placeholder={"Enter Your Priority"}
          handleChange={formik.handleChange}
          value={formik.values.priority}
          errors={formik.errors.priority}
          touched={formik.touched.priority}
        />
        <Box sx={{ marginTop: "30px" }}>
          <Typography sx={styles.status}>Status</Typography>
          <Switch
            checked={formik.values.status}
            onChange={() =>
              formik.setFieldValue("status", !formik.values.status)
            }
          />
        </Box>
        <Box sx={styles.formButtonMainBox}>
          <CustomButtonComponent
            variant="contained"
            sx={styles.formUpdateButton}
            onClick={() => {
              handleChange();
            }}
          >
            <BorderColorOutlinedIcon sx={styles.formUpdateButtonIcon} />
            Next Targeting
          </CustomButtonComponent>
        </Box>
        <Box sx={styles.formButtonMainBox}>
          <CustomButtonComponent
            variant="contained"
            sx={styles.formUpdateButton}
            onClick={handleSubmit}
          >
            <BorderColorOutlinedIcon sx={styles.formUpdateButtonIcon} />
            Update
          </CustomButtonComponent>
          <CustomButtonComponent
            variant="contained"
            sx={styles.formUpdateButton}
          >
            <BorderColorOutlinedIcon sx={styles.formUpdateButtonIcon} />
            Update Url
          </CustomButtonComponent>
          <CustomButtonComponent
            variant="contained"
            sx={styles.formResetButton}
           
          >
            <RefreshOutlinedIcon sx={styles.formResetButtonIcon} />
            Reset
          </CustomButtonComponent>
        </Box>
      </Box>
    </>
  );
};

export default CreateStoreDetails;
