import {
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  Switch,
} from "@mui/material";
import React, { useState } from "react";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { styles } from "./styles";
import { styled } from "@mui/system";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { createSportCategorysSchema } from "../../../../Schemas";
import { useDispatch } from "react-redux";
import { sportsCategoryCreateAPI } from "../../../../Redux/slice/sportsCategoryList";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { imageUpload } from "../../../../Redux/slice/thunks";
import CustomButtonComponent from "../../../../Components/CustomButton";

const RequiredIndicator = styled("span")({
  color: "red",
  marginRight: "2px",
});

const SportCategoryCreatePage = () => {
  const dispatch = useDispatch();
  const [banner, setBanner] = useState("");

  const initialValues = {
    name: "",
    description: "",
    banner: "",
    priority: 0,
    active: true,
  };

  const handleSubmit = async (values) => {
    try {
      const finalResponse = await dispatch(sportsCategoryCreateAPI(values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(
    initialValues,
    createSportCategorysSchema,
    handleSubmit
  );

  const formFields = [
    {
      label: "Name",
      placeHolder: "Enter Your Name",
      name: "name",
      type: "text",
    },
    {
      label: "Description",
      placeHolder: "Enter Your Sport Category",
      name: "description",
      type: "text",
    },
    {
      label: "Priority",
      placeHolder: "eg. 123",
      name: "priority",
      type: "number",
    },
  ];

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("platform", "CRMS");

      try {
        const response = await dispatch(imageUpload(formData)).unwrap();
        const url = response?.fileUrls?.[0];
        const filePath = url?.split("/").slice(3).join("/");
        formik.setFieldValue("banner", filePath);
        setBanner(filePath);
        console.log({ response });
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Create Sport Category</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
              i
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.editGenderFormMainBox}>
        {formFields.map((field, index) => (
          <Box key={index} sx={{ marginTop: "30px" }}>
            <Typography sx={styles.editGenderFormLabel}>
              <RequiredIndicator>*</RequiredIndicator>
              {field.label}
            </Typography>
            <TextField
              sx={styles.editGenderFormTextField}
              placeholder={field.placeHolder}
              name={field.name}
              type={field.type}
              value={formik.values[field.name]}
              onChange={formik.handleChange}
            />
            {formik.errors[field.name] && formik.touched[field.name] ? (
              <Typography
                sx={{ color: "red", fontSize: "12px", paddingX: "2px" }}
              >
                {formik.errors[field.name]}
              </Typography>
            ) : null}
          </Box>
        ))}
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
          <Box sx={{ width: "95%" }}>
            <Typography sx={styles.editGenderFormLabel}>Banner</Typography>
            <TextField
              sx={styles.editGenderFormTextField}
              placeholder={"Enter Banner"}
              name="banner"
              value={formik.values.banner}
              onChange={formik.handleChange}
            />
            <CustomButtonComponent
              sx={{
                marginLeft: "10px",
                width: "12%",
                paddingY: "9px",
                marginTop: "0px",
                color: "primary.light",
              }}
              component="label"
            >
              <CloudUploadIcon sx={{ mr: "8px" }} />
              Upload
              <input type="file" hidden onChange={handleFileChange} />
            </CustomButtonComponent>
          </Box>
        </Box>
        <Box sx={{ marginTop: "30px" }}>
          <Typography sx={styles.editGenderFormStatusText}>Status</Typography>
          <Switch
            checked={formik.values.active}
            onChange={() =>
              formik.setFieldValue("active", !formik.values.active)
            }
          />
        </Box>
        <Box sx={styles.editGenderFormButtonMainBox}>
          <Button
            variant="contained"
            sx={styles.editGenderFormUpdateButton}
            onClick={formik.handleSubmit}
          >
            <BorderColorOutlinedIcon
              sx={styles.editGenderFormUpdateButtonIcon}
            />
            Create
          </Button>
          <Button
            variant="contained"
            sx={styles.editGenderFormResetButton}
            onClick={() => console.log("Reset clicked")}
          >
            <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
            Reset
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SportCategoryCreatePage;
