import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Typography,
    Switch,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
  import AddIcon from "@mui/icons-material/Add";
  import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
  import CustomPagination from "../../../../Components/CustomPagination";
  import DataInsertIntoTable from "../../../../Components/CustomTableComponent/dataInsertIntoTable";
  import { styles, tableHead } from "./styles";
  import { useDispatch, useSelector } from "react-redux";
  import editIcon from "../../../../Assets/Icons/Drawer/editIcon.svg";
  import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
  import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
  import {
    sportEcomListAPI,
    sportEcomListDeleteAPI,
    updateStatus,
    sportEcomUpdateAPI
  } from "../../../../Redux/slice/sportEcomList";
  import { downloadCSV } from "../../../../utils/utils";
  import moment from "moment";
  import { useSearchParams } from "react-router-dom";
  
  const SportEcomListPage = () => {
    
    const [sportsEcom, setSportsEcom] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [searchParams]= useSearchParams() ;
  
    const [keyOfSort, setKeyOfSort] = useState("priority");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItems, setSelectedItems] = useState([]);
    const head = tableHead;
    const sportGroupData = useSelector((state) => state.sportEcomList.data);
    const pageInfo = useSelector((state) => state.sportEcomList.page);
  
    const totalItems = pageInfo?.itemCount;
    const totalPages = pageInfo?.pageCount;
    const itemsPerPage = pageInfo?.perPage;
  
    const handleExport = () => {
      const csvData = sportGroupData?.map((row) => ({
        id: row?._id,
        name: row?.name,
        icon: row?.icon,
        description: row?.description,
        banner: row?.banner,
        code : row?.code ,
        createdAt: moment(row?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
        updatedAt: moment(row?.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
        status : row?.status ,
      }));
      downloadCSV(csvData, "sportGroupData");
    };
  
    useEffect(() => {

      const handleStatus = (brand) => (event) => {
        console.log(event?.target?.checked);
       // dispatch(updateStatus({ id : brand.id , status: event?.target?.checked }));
        dispatch(sportEcomUpdateAPI({id : brand._id , formData : {name : brand.name , active : event?.target?.checked}})) ;
        dispatch(updateStatus({ id : brand._id , active : event?.target?.checked }));
      };

      if (sportGroupData) {
        let arr = sportGroupData?.map((brand) => {
          return {
            actions: [
              {
                content: (
                  <Switch
                  checked={brand?.active}
                  onChange={handleStatus(brand)}
                  />
                ),
              },
              {
                content: (
                  <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />
                ),
              },
              { type: "ButtonWithImg", content: editIcon },
            ],
            id: [{ type: "text", content: brand?._id }],
            name: [{ type: "text", content: brand?.name }],
            icon: [{ type: "image", content: brand?.icon }],
            banner: [{ type: "image", content: brand?.banner }],
            description: [{ type: "text", content: brand?.description }],
            code: [{ type: "text", content: brand?.code }],
            status: [{ type: "text", content: brand?.status }],
            createdAt: [{ type: "text", content:  moment(brand?.createdAt).format("DD/MM/YYYY  HH:mm:ss") }],
            updatedAt: [{ type: "text", content:  moment(brand?.updatedAt).format("DD/MM/YYYY  HH:mm:ss") }],
          };
        });
        setSportsEcom(arr);
        setLoading(false);
      }
    }, [sportGroupData]);
  
    useEffect(() => {
      let q = searchParams.get("q");
      console.log("Here is the query", q);
        setLoading(true) ;
        dispatch(sportEcomListAPI({ page: currentPage , search : q}));
    }, [dispatch, currentPage , searchParams]);
  
    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
    
    if ((!sportsEcom || sportsEcom.length === 0) && !loading) {
      return (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "red",
            fontFamily: "Avenir",
            fontSize: "40px",
            alignItems: "center",
          }}
        >
          No data available
        </Typography>
      );
    }

    const sortedBody = sportsEcom.slice().sort((a, b) => {
      const nameA = a[keyOfSort]?.content;
      const nameB = b[keyOfSort]?.content;
  
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
  
      return 0;
    });
  
    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };
  
    const handleDelete = async () => {
      try {
        let arr1 = [...sportsEcom];
        arr1 = arr1.filter(
          (Object) => !selectedItems.includes(String(Object?.id[0].content))
        );
        setSportsEcom(arr1);
        for (let id of selectedItems) {
          dispatch(sportEcomListDeleteAPI({ id }));
        }
        selectedItems([]);
      } catch (error) {
        console.error("Error deleting:", error);
      }
    };
  
    return (
      <>
        <Box sx={styles.mainBox}>
          <Box sx={styles.headingMainBox}>
            <Box sx={styles.headingTextBox}>Sport List</Box>
            <Box sx={styles.headingButtonBox}>
              <Button sx={styles.filterButtonBox}>
                <Typography sx={styles.filterTypoText}>Filters</Typography>
                <img src={DownArrowIcon} alt="downArrowIcon" />
              </Button>
              <Button sx={styles.exportButtonBox} onClick={handleExport}>
                <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
                <Typography sx={styles.exportTypoText}>Export</Typography>
              </Button>
              <IconButton sx={styles.addIconButtonBox}>
                <AddIcon
                  sx={{
                    color: "primary.light",
                    padding: "0px",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box sx={styles.pagesDetailMainBox}>
            <Typography>
              <span style={{ color: "#171A1F", fontWeight: "700" }}>
                {sportsEcom.length}
              </span>
              <span style={{ color: "#6E7787", marginLeft: "6px" }}>
                in total
              </span>
            </Typography>
  
            <Typography sx={{ marginLeft: "70px" }}>
              <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
              <span style={styles.pagesDetailNumber}>
                {(currentPage - 1) * itemsPerPage}
              </span>
              <span style={styles.pagesDetailText}>to</span>
              <span style={styles.pagesDetailNumber}>
                {currentPage * itemsPerPage}
              </span>
              <span style={styles.pagesDetailText}>of</span>
              <span style={styles.pagesDetailNumber}>{totalItems}</span>
              <span style={styles.pagesDetailText}>results</span>
            </Typography>
  
            <Box textAlign="center" ml="auto">
              {selectedItems.length > 0 ? (
                <IconButton
                  onClick={handleDelete}
                  aria-label="delete"
                  sx={{ color: "red" }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            pl: "6px",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <DataInsertIntoTable
            checkData={selectedItems}
            setCheckData={setSelectedItems}
            headingOfTable={head}
            bodyOfTable={sportsEcom}
            headingRowColor={"teal"}
            setKeyOfSort={setKeyOfSort}
            headingTextColor={"#FFFFFF"}
            tableBodyColor={"white"}
            tableBodyTextColor={"#171A1F"}
            sportsEcom={true}
          />
        </Box>
        <Box
          sx={{
            pl: "6px",
            marginTop: "20px",
          }}
        >
          <CustomPagination
            type={"text"}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
          />
        </Box>
      </>
    );
  };
  
  export default SportEcomListPage;
  