import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { GROUP_URLS1 } from "../../constants";
import { toast } from 'sonner';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};


export const groupListAPI = createAsyncThunk("groupListAPI", async ({page , search}) => {
  try {
    const response = await instance.get(`${GROUP_URLS1.LIST(page , search)}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});


export const groupListAllAPI = createAsyncThunk("groupListAllAPI", async () => {
    try {
      const response = await instance.get(`${GROUP_URLS1.DROP}`);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  });

export const groupDeleteAPI = createAsyncThunk(
  "groupDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${GROUP_URLS1.DELETE(data.id)}`);
      toast.success('Deleted successfully!');
      return response.data;
    } catch (error) {
      console.log("Error", error);
      toast.error('Failed to delete.');
      return error;
    }
  }
);


export const groupGetByIdAPI = createAsyncThunk(
  "groupGetByIdAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(GROUP_URLS1.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);



export const groupUpdateAPI = createAsyncThunk(
  "groupUpdateAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        GROUP_URLS1.UPDATE(data.id),
        data.formData
      );
      toast.success('Updated successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to update.');
      return rejectWithValue(err);
    }
  }
);


export const groupCreateAPI = createAsyncThunk(
  "groupCreateAPI",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${GROUP_URLS1.CREATE}`, formdata);
      // console.log(response);
      toast.success('Group created successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to create group.');
      return rejectWithValue(err);
    }
  }
);

const groupList = createSlice({
  name: "groupList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state?.data?.map((item) => {
        if (String(item._id) === String(action.payload.id)) {
          return {
            ...item,
            active : action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(groupListAPI.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(groupListAPI.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action?.payload?.data;
        state.page = action?.payload?.pageInfo;
      })
      .addCase(groupListAPI.rejected, (state, action) => {
        console.log("Error", action.payload);
        state.isError = true;
      })
      .addCase(groupListAllAPI.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action?.payload?.data;
      })
      .addCase(groupDeleteAPI.fulfilled, (state, action) => {
        state.isLoading = false;
        // Handle delete response if needed
      })
      .addCase(groupGetByIdAPI.fulfilled, (state, action) => {
        state.isLoading = false;
        // Handle get by ID response if needed
      })
      .addCase(groupUpdateAPI.fulfilled, (state, action) => {
        state.isLoading = false;
        // Handle update response if needed
      })
      .addCase(groupCreateAPI.fulfilled, (state, action) => {
        state.isLoading = false;
        // Handle create response if needed
      });
  },
});

export const { updateStatus } = groupList.actions;
export default groupList.reducer;
