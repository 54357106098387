import axios from "axios";
import { store } from "../Redux";
import { BASE_URL } from "../constants";

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

instance.interceptors.request.use(function (config) {
  const accessToken = store.getState()?.login?.currentUser?.accessToken;

  config.headers["x-access-token"] = accessToken?.data;
  config.headers["x-access-iv"] = accessToken?.iv;

  return config;
});



export default instance;
