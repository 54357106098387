import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import {DISCOUNT_URLS1 } from "../../constants";
import { toast } from 'sonner';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};



export const discountListAPI = createAsyncThunk("discountListAPI", async ({page , search}) => {
  try {
    const response = await instance.get(`${DISCOUNT_URLS1.LIST(page , search)}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const discountListDeleteAPI = createAsyncThunk(
  "discountListDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${DISCOUNT_URLS1.DELETE(data.id)}`);
      toast.success('Deleted successfully!');
      return response.data;
    } catch (error) {
      console.log("Error", error);
      toast.error('Failed to delete.');
      return error;
    }
  }
);

export const discountAllAPI = createAsyncThunk("discountAllAPI", async () => {
  try {
    const response = await instance.get(`${DISCOUNT_URLS1.DROP}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    toast.error('Failed to delete.');
    return error;
  }
});


export const discountGetByIdAPI = createAsyncThunk(
  "discountGetByIdAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(DISCOUNT_URLS1.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);



export const discountUpdateAPI = createAsyncThunk(
  "discountUpdateAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        DISCOUNT_URLS1.UPDATE(data.id),
        data.formData
      );
      toast.success('Updated successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to update.');
      return rejectWithValue(err);
    }
  }
);


export const discountCreateAPI = createAsyncThunk(
  "discountCreateAPI",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${DISCOUNT_URLS1.CREATE}`, formdata);
      // console.log(response);
      toast.success('Discount created successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to create discount.');
      return rejectWithValue(err);
    }
  }
);


const discountList = createSlice({
  name: "discountList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state?.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(discountListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(discountListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
      state.page = action?.payload?.pageInfo;
    });
    builder.addCase(discountAllAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
    });
    builder.addCase(discountListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { updateStatus } = discountList.actions;
export default discountList.reducer;
