import { Box, Typography } from "@mui/material";
import React from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import LeftIconInput from "./LeftIconInput";
import InputError from "./inputError";

function DescribeYourSelf({ formik }) {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        Describe yourself <InfoRoundedIcon sx={{ fontSize: "18px" }} />
      </Typography>
      <LeftIconInput multiline name="description" label={"Type"} formik={formik} />
      {formik.touched.description && formik.errors.description && (
        <InputError error={formik.errors.description} />
      )}
    </Box>
  );
}

export default DescribeYourSelf;
