import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { LANGUAGE_URLS } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const languageListAPI = createAsyncThunk("languageListAPI", async () => {
  try {
    const response = await instance.get(`${LANGUAGE_URLS.LIST}`);
  console.log(response.data);
  return response.data;
  } catch (error) {
    console.log("Error", error);
      return error;
  }  
});


export const languageDeleteAPI = createAsyncThunk(
  "languageDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${LANGUAGE_URLS.LIST}/${data.id}`);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);



export const LanguageGetById = createAsyncThunk(
  "languageGetById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(LANGUAGE_URLS.EDIT(data.id));
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

export const LanguageEditAPI = createAsyncThunk(
  "languageEdit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        LANGUAGE_URLS.EDIT(data.id),
        data.formdata
      );
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);
export const languageStatusUpdate = createAsyncThunk(
  "languageStatusUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        LANGUAGE_URLS.UPDATE_STATUS(data.id),
        data.body
      );
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);



export const createLanguageAPI = createAsyncThunk(
  "createLanguage",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${LANGUAGE_URLS.CREATE}`, formdata);
      // console.log(response);;
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);




const language = createSlice({
  name: "language",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.status,
          };
        }
        return item;
      });
      state.data = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(languageListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(languageListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
    });
    builder.addCase(languageListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});
export const { updateStatus } = language.actions;
export default language.reducer;
