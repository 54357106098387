import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { SPORTS_URLS} from "../../constants";

const initialState = {
  isLoading: false,
  data: null ,
  isError: false,
};


//CREATE
export const sportsCreateAPI = createAsyncThunk(
  "sportsCreate",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${SPORTS_URLS.CREATE}`, formdata);
      // console.log(response);;
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

// GET  
export const SportsGetById = createAsyncThunk(
  "SportsGetById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(SPORTS_URLS.GET(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


//UPDATE_STATUS
export const sportsStatusUpdate = createAsyncThunk(
  "SportsStatusUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        SPORTS_URLS.UPDATE_STATUS(data.id),
        data.body
      );
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


//EDIT
export const SportsEditAPI = createAsyncThunk(
  "sportsEdit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        SPORTS_URLS.EDIT(data.id),
        data.formdata
      );
      console.log(data.formdata);
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


export const sportsListAPI = createAsyncThunk("sportsListAPI", async ({page}) => {
  const response = await instance.get(`${SPORTS_URLS.LIST(page)}`);
  console.log(response.data);
  return response.data;
});

export const sportsSearchAPI = createAsyncThunk("sportsSearchAPI", async ({search , page}) => {
  const response = await instance.get(`${SPORTS_URLS.SEARCH(search , page)}`);
  console.log("data sports " , response.data);
  return response.data;
});

export const sportDeleteAPI = createAsyncThunk(
  "sportDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${SPORTS_URLS.LIST()}/${data.id}`);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);


const sports = createSlice({
  name: "sports",
  initialState,
  reducers: {

    updateStatus: (state, action) => {
      let data = state?.data?.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.status,
          };
        }
        return item;
      });
      state.data.data = data;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(sportsListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sportsListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload;
      console.log(action.payload);
    });
    builder.addCase(sportsListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    builder.addCase(sportsSearchAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload;
      console.log(action.payload);
    });
  },
});
export const { updateStatus } = sports.actions;
export default sports.reducer;
