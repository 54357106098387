import { initialEditProfileFormikValues } from "./initialFormikProfileEditValues";

export const fillInitialValues = (academy, formik) => {
    // if (!academy?.length) return;
    // academy = academy[0];

    let avail = academy?.parmanentAvailability;

    let addresses = [{
        ...initialEditProfileFormikValues.addresses[0],
        address: {
            _id: avail?.address?._id,
            address: avail?.address?.address,
            pinCode: avail?.address?.pinCode,
            city: avail?.address?.city,
        },
        _id: avail?._id,
        coachingPeriods: {
            days: avail?.coachingPeriods?.days || initialEditProfileFormikValues.addresses[0].coachingPeriods.days,
            weeks: avail?.coachingPeriods?.weeks || initialEditProfileFormikValues.addresses[0].coachingPeriods.weeks,
            months: avail?.coachingPeriods?.months || initialEditProfileFormikValues.addresses[0].coachingPeriods.months,
        },
        coachingType: avail?.coachingType?._id,
        ageGroup: avail?.ageGroup?.map(age => age?._id),
        genderYouTeach: avail?.genderYouTeach?.map(gender => gender?._id),
        timeSlots: avail?.timeSlots?.filter(t => t?.coachingType && t?.slotPeriod && t?.timing).map(slot => ({
            coachingType: slot?.coachingType?._id,
            slotPeriod: slot?.slotPeriod?._id,
            timing: slot?.timing,
        })),
    }]

    let sports = academy?.sports?.map(sport => ({ id: sport?._id, label: sport?.name }))


    formik.setValues({
        ...initialEditProfileFormikValues,
        name: academy?.name || initialEditProfileFormikValues.name,
        fullName: academy?.createrName || initialEditProfileFormikValues.fullName,
        phoneNumber: academy?.phoneNumber || initialEditProfileFormikValues.phoneNumber,
        email: academy?.email || initialEditProfileFormikValues.email,
        description: academy?.description || initialEditProfileFormikValues.description,
        groupCoachingPriceRange: academy?.groupCoachingPriceRange || initialEditProfileFormikValues.groupCoachingPriceRange,
        privateCoachingPriceRange: academy?.privateCoachingPriceRange || initialEditProfileFormikValues.privateCoachingPriceRange,
        addresses: addresses?.length ? addresses : initialEditProfileFormikValues.addresses,
        sports: sports?.length ? sports : initialEditProfileFormikValues.sports,
        coaches: initialEditProfileFormikValues.coaches
    });
}