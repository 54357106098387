export const styles = {
  container: {
    pl: "6px",
    marginBottom: "10px",
  },
  header: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "48px",
  },
  buttonContainer: {
    alignItems: "center",
    display: "flex",
  },
  button: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": "none",
    width: "140px",
    justifyContent: "space-between",
    pl: "12px",
    pr: "20px",
    py: "10px",
    mx: "20px",
  },
  exportButton: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": "none",
    width: "114px",
    justifyContent: "space-between",
    py: "10px",
    pl: "16px",
    pr: "16px",
    mx: "20px",
  },
  addButton: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    justifyContent: "center",
    paddingX: "10px",
    paddingY: "10px",
    borderRadius: "4px",
    ml: "12px",
  },
  createFormBox: {
    width: "75%",
    marginY: "50px",
    backgroundColor: "#FFFFFF",
    ml: "6px",
    paddingY: "20px",
    paddingX: "40px",
    boxShadow: "8px 8px 9px 0px #0000001A",
  },
  settingSwitchButton: {
    color: "primary.main",
    "& .MuiSwitch-thumb": {
      backgroundColor: "#3CB0AD",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "transparent",
      border: "1px solid #3CB0AD",
    },
  },
  editGenderFormStatusText: {
    fontSize: "14px",
    fontWeight: "500",
    marginY: "6px",
  },
  editGenderFormButtonMainBox: {
    marginTop: "30px",
    display: "flex",
  },
  editGenderFormUpdateButtonIcon: { marginRight: "5px", fontSize: "18px" },
  editGenderFormUpdateButton: {
    color: "primary.light",
    marginRight: "40px",
    textTransform: "capitalize",
    alignItems: "center",
  },
  
  formButtonMainBox: {
    marginTop: "30px",
    display: "flex",
  },
  formUpdateButton: {
    color: "primary.light",
    marginRight: "10px",
    textTransform: "capitalize",
    alignItems: "center",
  },
  formUpdateButtonIcon: {
    marginRight: "5px",
    fontSize: "18px",
  },
  formResetButtonIcon: {
    marginRight: "5px",
    fontSize: "18px",
    transform: "rotate(270deg)",
  },
  formResetButton: {
    color: "primary.light",
    backgroundColor: "#FF5B5B",
    textTransform: "capitalize",
  },
};
