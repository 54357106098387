import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomPagination from "../../../../Components/CustomPagination";
import DataInsertIntoTable from "../../../../Components/CustomTableComponent/dataInsertIntoTable";
import { styles, tableHead } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../../../Assets/Icons/Drawer/editIcon.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  offerListAPI,
  offerListDeleteAPI,
  updateStatus,
  offerUpdateAPI,
} from "../../../../Redux/slice/offerEcom.js";
import { downloadCSV } from "../../../../utils/utils";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

const OfferEcomListPage = () => {

  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const [keyOfSort, setKeyOfSort] = useState("sellerSKU");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const head = tableHead;
  const [searchParams]= useSearchParams() ;

  const offerData = useSelector((state) => state.offerEcomList.data);
  const pageInfo = useSelector((state) => state.offerEcomList.page);

  const totalItems = pageInfo?.itemCount;
  const totalPages = pageInfo?.pageCount;
  const itemsPerPage = pageInfo?.perPage;

  const handleExport = () => {
    const csvData = offerData?.map((row) => ({
      sellerSKU: row?.sellerSKU,
      productPrice: row?.productPrice,
      quantity: row?.quantity,
      condition: row?.condition,
      shippingChannel: row?.shippingChannel,
      countryOfOrigin: row?.countryOfOrigin,
      hsnCode: row?.hsnCode,
      productMRP: row?.productMRP,
      offerAvailabilityFrom:  moment(row?.offerAvailabilityFrom).format("DD/MM/YYYY HH:mm:ss"),
      offerAvailabilityTo:  moment(row?.offerAvailabilityTo).format("DD/MM/YYYY HH:mm:ss"),
      status : row?.status , 
      createdAt: moment(row?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
      updatedAt: moment(row?.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
      id : row?._id ,
      
    }));
    downloadCSV(csvData, "offerData");
  };

  useEffect(() => {
    const handleStatus = (offer) => (event) => {
      console.log(event?.target?.checked);
      dispatch(offerUpdateAPI({id : offer._id , formData : {name : offer.name , active : event?.target?.checked}})) ;
      dispatch(updateStatus({ id : offer._id , active : event?.target?.checked }));
    };

    if (offerData) {
      let arr = offerData?.map((offer) => {
        return {
          actions: [
            {
              content: (
                <Switch
                  checked={offer?.active}
                  onChange={handleStatus(offer)}
                />
              ),
            },
            {
              content: (
                <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />
              ),
            },
            { type: "ButtonWithImg", content: editIcon },
          ],
          id : [{ type: "text", content: offer?._id }],
          sellerSKU: [{ type: "text", content: offer?.sellerSKU }],
          productPrice: [{ type: "text", content: offer?.productPrice }],
          quantity: [{ type: "text", content: offer?.quantity }],
          condition: [{ type: "text", content: offer?.condition }],
          shippingChannel: [{ type: "text", content: offer?.shippingChannel }],
          countryOfOrigin: [{ type: "text", content: offer?.countryOfOrigin }],
          hsnCode: [{ type: "text", content: offer?.hsnCode }],
          productMRP: [{ type: "text", content: offer?.productMRP }],
          status: [{ type: "text", content: offer?.status }],
          offerAvailabilityFrom: [{ type: "text", content: moment(offer?.createdAt).format("DD/MM/YYYY  HH:mm:ss") }],
          offerAvailabilityTo: [{ type: "text", content: moment(offer?.createdAt).format("DD/MM/YYYY  HH:mm:ss") }],
          createdAt: [{ type: "text", content:  moment(offer?.createdAt).format("DD/MM/YYYY  HH:mm:ss") }],
          updatedAt: [{ type: "text", content:  moment(offer?.updatedAt).format("DD/MM/YYYY  HH:mm:ss") }],
        };
      });
      setOffers(arr);
      setLoading(false);
    }
  }, [offerData]);


  useEffect(() => {
    let q = searchParams.get("q");
    console.log("Here is the query", q);
      setLoading(true) ;
      dispatch(offerListAPI({ page: currentPage , search : q}));
  }, [dispatch, currentPage , searchParams]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!offers || offers.length === 0) {
    return (
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "red",
          fontFamily: "Avenir",
          fontSize: "40px",
          alignItems: "center",
        }}
      >
        No data available
      </Typography>
    );
  }

  const sortedBody = offers.slice().sort((a, b) => {
    const nameA = a[keyOfSort]?.content;
    const nameB = b[keyOfSort]?.content;

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDelete = async () => {
    try {
      let arr1 = [...offers];
      arr1 = arr1.filter(
        (Object) => !selectedItems.includes(String(Object?.id[0].content))
      );
      setOffers(arr1);
      for (let id of selectedItems) {
        dispatch(offerListDeleteAPI({ id }));
      }
      setSelectedItems([]);
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Offer List</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox} onClick={handleExport}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.pagesDetailMainBox}>
          <Typography>
            <span style={{ color: "#171A1F", fontWeight: "700" }}>
              {offers.length}
            </span>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>
              in total
            </span>
          </Typography>

          <Typography sx={{ marginLeft: "70px" }}>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
            <span style={styles.pagesDetailNumber}>
              {(currentPage - 1) * itemsPerPage}
            </span>
            <span style={styles.pagesDetailText}>to</span>
            <span style={styles.pagesDetailNumber}>
              {currentPage * itemsPerPage}
            </span>
            <span style={styles.pagesDetailText}>of</span>
            <span style={styles.pagesDetailNumber}>{totalItems}</span>
            <span style={styles.pagesDetailText}>results</span>
          </Typography>

          <Box textAlign="center" ml="auto">
            {selectedItems.length > 0 ? (
              <IconButton
                onClick={handleDelete}
                aria-label="delete"
                sx={{ color: "red" }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          pl: "6px",
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <DataInsertIntoTable
          checkData={selectedItems}
          setCheckData={setSelectedItems}
          headingOfTable={head}
          bodyOfTable={offers}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
          offers={true}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>
    </>
  );
};

export default OfferEcomListPage;
