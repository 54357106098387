import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "../Create/styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomPriceSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { imageUpload } from "../../../../Redux/slice/thunks";
import EditFormField from "../../CustomFormFields/EditFormField";
import { priceGetByIdAPI, priceUpdateAPI } from "../../../../Redux/slice/priceEcom";
import moment from "moment";  

const PriceEcomEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const initialValues = {
    listPrice: "",
    sellPrice: "",
    currency: "",
    startDate: "",
    endDate: "",
  };

  const handleSubmit = async (values) => {
    try {
      const finalResponse = await dispatch(
        priceUpdateAPI({
          id: searchParams.get("id"),
          formData: values,
        })
      );
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomPriceSchema, handleSubmit);

  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(priceGetByIdAPI({ id: searchParams.get("id") }));
      data = data?.payload?.data?.data;
      formik.setValues({
        listPrice: data?.listPrice || "",
        sellPrice: data?.sellPrice || "",
        currency: data?.currency || "",
        startDate: moment(data?.startDate ).format("YYYY-MM-DD") || "",
        endDate: moment(data?.endDate ).format("YYYY-MM-DD") || "",
      });
    };

    fetchData();
  }, [dispatch, searchParams]);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Edit Price</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <EditFormField
          label={"List Price"}
          name="listPrice"
          placeholder={"Enter List Price"}
          handleChange={formik.handleChange}
          value={formik.values.listPrice}
          errors={formik.errors.listPrice}
          touched={formik.touched.listPrice}
        />

        <EditFormField
          label={"Sell Price"}
          name="sellPrice"
          placeholder={"Enter Sell Price"}
          handleChange={formik.handleChange}
          value={formik.values.sellPrice}
          errors={formik.errors.sellPrice}
          touched={formik.touched.sellPrice}
        />

        <EditFormField
          label={"Currency"}
          name="currency"
          placeholder={"Enter Currency"}
          handleChange={formik.handleChange}
          value={formik.values.currency}
          errors={formik.errors.currency}
          touched={formik.touched.currency}
        />

        <EditFormField
          label={"Start Date"}
          name="startDate"
          placeholder={"Enter Start Date"}
          handleChange={formik.handleChange}
          value={formik.values.startDate}
          errors={formik.errors.startDate}
          touched={formik.touched.startDate}
          type="date"
        />

        <EditFormField
          label={"End Date"}
          name="endDate"
          placeholder={"Enter End Date"}
          handleChange={formik.handleChange}
          value={formik.values.endDate}
          errors={formik.errors.endDate}
          touched={formik.touched.endDate}
          type="date"
        />

        <Box sx={{ marginTop: "38px" }}></Box> 

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Update
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default PriceEcomEditPage;
