import React, { useEffect } from "react";
import CustomFormikHook from "../../../Hooks/CustomFormikHook";
import { fillInitialValues } from "./fillInitialValues";
// import { useDispatch } from "react-redux";
import { initialEditProfileFormikValues } from "./initialFormikProfileEditValues";
import { editAcademyProfileSchema } from "../../../Schemas";
import { Box, TextField, Typography, useMediaQuery } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import InputFieldsWithSearch from "../../../Components/EditProfileComponents/inputFieldsWithSearch";
import AddressFields from "../../../Components/EditProfileComponents/addressFields";
import CoachingPeriodDropdown from "../../../Components/EditProfileComponents/coachingPeriodDropdown";
import CoachingTypeAndTimeSlot from "../../../Components/EditProfileComponents/coachingTypeAndTimeSlot";
import SelectAgeGroup from "../../../Components/EditProfileComponents/selectAgeGroup";
import SelectGenderUTeach from "../../../Components/EditProfileComponents/selectGenderUTeach";
import CoachingPrices from "../../../Components/EditProfileComponents/coachingPrices";
import DescribeYourSelf from "../../../Components/EditProfileComponents/describeYourSelf";
import CustomButton from "../../../Components/EditProfileComponents/CustomButton";
import LeftIconInput from "../../../Components/EditProfileComponents/LeftIconInput";
import { useDispatch, useSelector } from "react-redux";
import {
  getAcademyProfile,
  updateAcademyprofile,
} from "../../../Redux/slice/editProfile";
import { toast } from "sonner";
import { BASE_URL, SPORTS_URLS } from "../../../constants";

function EditAcademyProfile() {
  const { academy } = useSelector((state) => state.editProfile);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1000px)");

  const SPORTSLIST_URL = SPORTS_URLS.LIST() ; 

  const handleSubmit = async (values) => {
    let data = {
      ...values,
      sports:
        values?.sports.filter((sport) => sport.id).map((sport) => sport.id) ||
        [],
      coaches:
        values?.coaches.filter((coach) => coach.id).map((coach) => coach.id) ||
        [],
      address: values?.addresses[0] || {},
    };
    delete data.addresses;
    let res = await dispatch(
      updateAcademyprofile({ ...data, slug: searchParams.get("slug") })
    ).unwrap();

    if (res?.data?.message) {
      toast.success("Profile Updated Successfully");
      navigate(-1);
    } else {
      toast.error("Something went wrong");
    }
  };

  const formik = CustomFormikHook(
    initialEditProfileFormikValues,
    editAcademyProfileSchema,
    handleSubmit
  );

  console.log(formik.values);

  useEffect(() => {
    formik.validateForm();
  }, []);

  useEffect(() => {
    dispatch(getAcademyProfile({ slug: searchParams.get("slug") }));
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (academy) fillInitialValues(academy, formik);
  }, [academy]);

  const webStyle = {
    position: "relative",
    top: "160px",
    margin: "auto",
    width: "65%",
    padding: 0,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 0,
    outline: "none",
    border: "none",
  };

  const textFieldStyles = {
    width: "100%",
    margin: "10px 0",
    // ...textFields.dottedBottomBorder,
  };

  const handleClose = () => {
    navigate(-1);
  };

  const serachCoaches = async (query) => {
    let res = await fetch(`${BASE_URL}${SPORTSLIST_URL}/active?q=${query}`, {
      method: "GET",
    }).then((res) => res.json());

    let list = res?.data?.map((academy) => ({
      id: academy.id,
      label: academy.name,
      image: "",
    }));

    return { data: { list: list } };
  };

  const searchSports = async (query) => {
    let res = await fetch(`${BASE_URL}${SPORTSLIST_URL}/active?q=${query}`, {
      method: "GET",
    }).then((res) => res.json());

    let list = res?.data?.map((sport) => ({
      _id: sport._id,
      label: sport.name,
      image: "",
    }));
    return { data: { list: list } };
  };
  
  return (
    <section>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflowY: "hidden",
          backgroundColor: "#fff",
        }}
      >
        <Box sx={{ ...(!isMobile && webStyle) }}>
          <CloseIcon
            onClick={handleClose}
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: "38px",
              right: "42px",
            }}
          />
          <Box
            sx={{
              padding: "60px 50px",
              ...(!isMobile && {
                padding: "60px 30px",
                textAlign: "center",
                borderBottom: "1px solid #D3D1D1",
              }),
            }}
          >
            <span
              style={{
                fontSize: "32px",
                fontWeight: 600,
              }}
            >
              Edit Profile
            </span>
          </Box>

          <Box
            sx={{
              width: isMobile ? "80%" : "65%",
              margin: "20px auto",
              marginBottom: "50px",
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "80%" }, mb: "2rem" }}>
              <Box sx={{ margin: "25px 0" }}>
                <Typography fontSize={"14px"} fontWeight={600}>
                  Enter Academy Name
                </Typography>
                <LeftIconInput
                  name={`name`}
                  label={"Academy Name"}
                  formik={formik}
                />
              </Box>
              <TextField
                id="fullName"
                variant="standard"
                type="text"
                name="fullName"
                label="Full Name"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                error={
                  formik.touched.fullName && Boolean(formik.errors.fullName)
                }
                helperText={formik.touched.fullName && formik.errors.fullName}
                InputProps={{
                  style: {
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "500",
                  },
                }}
                sx={{ ...textFieldStyles, mb: "1.5rem" }}
              />
              <TextField
                id="phoneNumber"
                variant="standard"
                type="text"
                name="phoneNumber"
                label="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                InputProps={{
                  style: {
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "500",
                  },
                }}
                sx={{ ...textFieldStyles, mb: "1.5rem" }}
              />
              <TextField
                variant="standard"
                type="text"
                id="email"
                name="email"
                label="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  style: {
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "500",
                  },
                }}
                sx={{ ...textFieldStyles, mb: "1.5rem" }}
              />
              <DescribeYourSelf formik={formik} />
              <InputFieldsWithSearch
                formik={formik}
                formikFieldName={"sports"}
                label={"Enter Sport"}
                title={"Enter the sport that you teach"}
                search={searchSports}
                buttonLabel={"Add Another Sport"}
              />
              {formik.values.addresses?.map((_, index) => (
                <React.Fragment key={index}>
                  <AddressFields
                    key={index}
                    formik={formik}
                    addressIndex={index}
                    title={
                      index === 0
                        ? "Primary Coaching Address"
                        : "Another Coaching Address"
                    }
                  />

                  <CoachingPeriodDropdown
                    formik={formik}
                    addressIndex={index}
                  />
                  <CoachingTypeAndTimeSlot
                    formik={formik}
                    addressIndex={index}
                  />
                  <SelectAgeGroup formik={formik} addressIndex={index} />
                  <SelectGenderUTeach formik={formik} addressIndex={index} />
                </React.Fragment>
              ))}
              <CoachingPrices
                formik={formik}
                addresses={formik.values.addresses}
              />
              <Box
                sx={{
                  width: "100%",
                  height: "2px",
                  bgcolor: "#D3D1D1",
                  display: { xs: "flex", sm: "none" },
                  my: "1.5rem",
                }}
              ></Box>

              <InputFieldsWithSearch
                title={"Add Coach to your Academy (optional)"}
                formik={formik}
                formikFieldName={"coaches"}
                label={"Enter Coach Name"}
                search={serachCoaches}
                buttonLabel={"Add Another Coach"}
              />
              <CustomButton
                width="100%"
                // type="submit"
                clickEvent={formik.handleSubmit}
                disabled={!formik.isValid}
              >
                Submit
              </CustomButton>
              <Box
                sx={{ width: "100%", height: "100px", marginTop: "250px" }}
              ></Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </section>
  );
}

export default EditAcademyProfile;
