import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import { styles } from "./styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomTaxSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import EditFormField from "../../CustomFormFields/EditFormField";
import { taxGetByIdAPI, taxUpdateAPI } from "../../../../Redux/slice/taxEcom";

const TaxEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const initialValues = {
    taxCode: "",
    description: "",
  };

  const handleSubmit = async (values) => {
    try {
      const finalResponse = await dispatch(
        taxUpdateAPI({
          id: searchParams.get("id"),
          formData: values,
        })
      );
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomTaxSchema, handleSubmit);

  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(taxGetByIdAPI({ id: searchParams.get("id") }));
      data = data?.payload?.data?.data;
      formik.setValues({
        taxCode : data?.taxCode || "",
        description : data?.description || "",
      });
    };

    fetchData();
  }, [dispatch, searchParams]);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Edit Tax</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <EditFormField
          label={"Tax Code"}
          name="taxCode"
          placeholder={"Enter Tax Code"}
          handleChange={formik.handleChange}
          value={formik.values.taxCode}
          errors={formik.errors.taxCode}
          touched={formik.touched.taxCode}
        />

        <EditFormField
          label={"Tax Description"}
          name="taxDescription"
          placeholder={"Enter Tax Description"}
          handleChange={formik.handleChange}
          value={formik.values.description}
          errors={formik.errors.description}
          touched={formik.touched.description}
        />

        <EditFormField
          label={"Rate"}
          name="rate"
          placeholder={"Enter Rate"}
          handleChange={formik.handleChange}
          value={formik.values.rate}
          errors={formik.errors.rate}
          touched={formik.touched.rate}
        />

        <Box sx={{ marginTop: "38px" }}></Box>

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Update
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default TaxEditPage;
