import React from "react";
import { Box, Typography } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { styles } from "./styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import {ecomDiscountSchema } from "../../../../Schemas";
import CreateFormField from "../../CustomFormFields/CreateFormField";
import { useDispatch } from "react-redux";
import { discountCreateAPI } from "../../../../Redux/slice/discountEcom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const DiscountCreatePage = () => {
  const dispatch = useDispatch();

  const discountType =  ["PERCENTAGE", "FLAT" , "FIXED"] ;

  const initialValues = {
    coupon: "",
    description: "",
    discountType: "",
    discountValue: "",
    startDate: "",
    endDate: "",
    maxUsage: "",
    maxUsagePerUser: "",
    minOrderAmount: "",
  };

  const handleSubmit = async (values) => {
    try {
      await formik.validateForm();
      const finalResponse = await dispatch(discountCreateAPI(values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomDiscountSchema, handleSubmit);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Discount</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <CreateFormField
          label={"Coupon"}
          name="coupon"
          placeholder={"Enter Coupon Code"}
          handleChange={formik.handleChange}
          value={formik.values.coupon}
          errors={formik.errors.coupon}
          touched={formik.touched.coupon}
        />

        <CreateFormField
          label={"Description"}
          name="description"
          placeholder={"Enter Description"}
          handleChange={formik.handleChange}
          value={formik.values.description}
          errors={formik.errors.description}
          touched={formik.touched.description}
        />

        {/* <CreateFormField
          label={"Discount Type"}
          name="discountType"
          placeholder={"Select Discount Type"}
          handleChange={formik.handleChange}
          value={formik.values.discountType}
          errors={formik.errors.discountType}
          touched={formik.touched.discountType}
          select
        >
          <MenuItem value="PERCENTAGE">Percentage</MenuItem>
          <MenuItem value="FLAT">Flat</MenuItem>
        </CreateFormField> */}

        <Box>
          <Box sx={{ minWidth: 120, marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Discount Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.discountType}
                label="discounts Category"
                name = "discountType"
                onChange={formik.handleChange}
              >
                {discountType?.map((option ,index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>


        <CreateFormField
          label={"Discount Value"}
          name="discountValue"
          placeholder={"Enter Discount Value"}
          handleChange={formik.handleChange}
          value={formik.values.discountValue}
          errors={formik.errors.discountValue}
          touched={formik.touched.discountValue}
        />

        <CreateFormField
          label={"Start Date"}
          name="startDate"
          placeholder={"Enter Start Date"}
          handleChange={formik.handleChange}
          value={formik.values.startDate}
          errors={formik.errors.startDate}
          touched={formik.touched.startDate}
          type="date"
        />

        <CreateFormField
          label={"End Date"}
          name="endDate"
          placeholder={"Enter End Date"}
          handleChange={formik.handleChange}
          value={formik.values.endDate}
          errors={formik.errors.endDate}
          touched={formik.touched.endDate}
          type="date"
        />

        <CreateFormField
          label={"Max Usage"}
          name="maxUsage"
          placeholder={"Enter Max Usage"}
          handleChange={formik.handleChange}
          value={formik.values.maxUsage}
          errors={formik.errors.maxUsage}
          touched={formik.touched.maxUsage}
        />

        <CreateFormField
          label={"Max Usage Per User"}
          name="maxUsagePerUser"
          placeholder={"Enter Max Usage Per User"}
          handleChange={formik.handleChange}
          value={formik.values.maxUsagePerUser}
          errors={formik.errors.maxUsagePerUser}
          touched={formik.touched.maxUsagePerUser}
        />

        <CreateFormField
          label={"Minimum Order Amount"}
          name="minOrderAmount"
          placeholder={"Enter Minimum Order Amount"}
          handleChange={formik.handleChange}
          value={formik.values.minOrderAmount}
          errors={formik.errors.minOrderAmount}
          touched={formik.touched.minOrderAmount}
        />

        <Box sx={{ marginTop: "38px" }}></Box>

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Create
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default DiscountCreatePage;
