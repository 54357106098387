import React from "react";
import { Box, Typography } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import { styles } from "./styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomShippingSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch } from "react-redux";
import CreateFormField from "../../CustomFormFields/CreateFormField";
import { shippingCreateAPI } from "../../../../Redux/slice/shippingEcom";

const ShippingCreatePage = () => {
  const dispatch = useDispatch();
  const initialValues = {
    shippingType: "",
    shippingDescription: "",
  };

  const handleSubmit = async (values) => {
    try {
      const finalResponse = await dispatch(shippingCreateAPI(values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomShippingSchema, handleSubmit);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Shipping Method</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <CreateFormField
          label={"Shipping Type"}
          name="shippingType"
          placeholder={"Enter Shipping Type"}
          handleChange={formik.handleChange}
          value={formik.values.shippingType}
          errors={formik.errors.shippingType}
          touched={formik.touched.shippingType}
        />

        <CreateFormField
          label={"Shipping Description"}
          name="shippingDescription"
          placeholder={"Enter Shipping Description"}
          handleChange={formik.handleChange}
          value={formik.values.shippingDescription}
          errors={formik.errors.shippingDescription}
          touched={formik.touched.shippingDescription}
        />

        <Box sx={{ marginTop: "38px" }}></Box> 

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Create
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      
      </Box>
    </>
  );
};

export default ShippingCreatePage;
