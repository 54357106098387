import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { SPORTS_CATEGORY_URLS } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const sportsCategoryListAPI = createAsyncThunk(
  "sportsCategoryListAPI",
  async () => {
    const response = await instance.get(`${SPORTS_CATEGORY_URLS.LIST}`);
    console.log(response.data);
    return response.data;
  }
);

export const sportCategoryDeleteAPI = createAsyncThunk(
  "sportCategoryDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(
        `${SPORTS_CATEGORY_URLS.LIST}/${data.id}`
      );
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);



export const SportsCategoryGetById = createAsyncThunk(
  "SportsCategoryGetById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(SPORTS_CATEGORY_URLS.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

export const SportsCategoryEditAPI = createAsyncThunk(
  "sportsCategoryEdit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        SPORTS_CATEGORY_URLS.EDIT(data.id),
        data.formdata
      );
      console.log(data.formdata);
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

export const sportsCategoryStatusUpdate = createAsyncThunk(
  "sportsCategoryStatusUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        SPORTS_CATEGORY_URLS.UPDATE_STATUS(data.id),
        data.body
      );
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);



export const sportsCategoryCreateAPI = createAsyncThunk(
  "sportsCategoryCreate",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(
        `${SPORTS_CATEGORY_URLS.CREATE}`,
        formdata
      );
      // console.log(response);;
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);





const sportsCategory = createSlice({
  name: "sportsCategory",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state?.data?.map((item) => {
        if (item._id === action?.payload?.id) {
          return {
            ...item,
            active: action?.payload?.status,
          };
        }
        return item;
      });
      state.data = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sportsCategoryListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sportsCategoryListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
    });
    builder.addCase(sportsCategoryListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});
export const { updateStatus } = sportsCategory.actions;
export default sportsCategory.reducer;
