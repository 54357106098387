import React, { useState } from "react";
import CreateStoreDetails from "./CreateDetails";
import Targeting from "./CreateTargeting";
import OnlinePayoutFormField from "./CreatePayout/onlinePayoutFormField";
import { Box, Typography, IconButton } from "@mui/material";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomTimelineComponent from "../../../../Components/CustomTimeline";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CreateOnlineTracking from "./CreateOnlineTracking/index";
import CreatePayout from "./CreatePayout";
import CreateTargeting from "./CreateTargeting";
import { styles } from "./CreateDetails/styles";
import AddIcon from "@mui/icons-material/Add";
import CustomButtonComponent from "../../../../Components/CustomButton";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import OnlinePayoutDetails from "./CreatePayout";

const Index = () => {
  const [page, setPage] = useState(0);
  
  const handleNext = () => {
    if (page < titles.length - 1) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };
  const titles = [
    { heading: "Details" },
    { heading: "Targeting" },
    { heading: "Payout" },
    { heading: "Online Tracking" },
  ];
  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography sx={styles.title}>Edit Store</Typography>
          <Box sx={styles.buttonContainer}>
            <CustomButtonComponent sx={styles.button}>
              <Typography
                sx={{ textTransform: "capitalize", fontWeight: "500" }}
              >
                Filters
              </Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </CustomButtonComponent>
            <CustomButtonComponent sx={styles.exportButton}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography
                sx={{ textTransform: "capitalize", fontWeight: "500" }}
              >
                Export
              </Typography>
            </CustomButtonComponent>
            <IconButton sx={styles.addButton}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <CustomTimelineComponent titles={titles} />

      {page === 0 && <CreateStoreDetails handleNext={handleNext} />}
      {page === 1 && <Targeting handleNext={handleNext} />}
      {page === 2 && <OnlinePayoutDetails handleNext={handleNext} />}
      {page === 3 && <CreateOnlineTracking />}

     
    </>
  );
};

export default Index;
