import * as Yup from "yup";

// E-Commerce-Pannel
export const storeDetailFormSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  title: Yup.string().required("Please enter title"),
  description: Yup.string().required("Please enter description"),
  type: Yup.string().required("Please enter Type"),
  rtoLimit: Yup.string().required("Please enter RTO Limit"),
  rtoPeriod: Yup.string().required("Please enter RTO Period"),
  icon: Yup.mixed().required("Please upload an icon"),
  banner: Yup.mixed().required("Please upload a banner"),
  avhText: Yup.string().required("Please enter AVH Text"),
  avh: Yup.number()
    .required("Please enter AVH")
    .positive("AVH must be positive"),
  trackingTimeText: Yup.string().required("Please enter Tracking Time Text"),
  trackingTime: Yup.number()
    .required("Please enter Tracking Time")
    .positive("Tracking Time must be positive"),
  trackingTimeUnits: Yup.string().required("Please enter Tracking Time Units"),
  subCategories: Yup.string().required("Please enter Sub Categories"),
  previewUrl: Yup.string()
    .url("Please enter a valid URL")
    .required("Please enter Preview URL"),
  advertiseUrl: Yup.string()
    .url("Please enter a valid URL")
    .required("Please enter Advertise URL"),
  advertiseID: Yup.string().required("Please Enter Your Advertise ID"),
  priority: Yup.number()
    .required("Please enter priority")
    .positive("Priority must be a positive number"),
});

export const offerDetailFormSchema = Yup.object({
  offerName: Yup.string().required("Please enter your offer name"),

  store: Yup.string().required("Please enter store"),
  description: Yup.string().required("Please enter description"),
  priority: Yup.number()
    .required("Please enter priority")
    .positive("Priority must be a positive number"),
  icon: Yup.mixed().required("Please upload an icon"),
  offerType: Yup.string().required("Please enter RTO offertype"),
  ctaText: Yup.string().required("Please enter RTO CTA Text"),
  deepLink: Yup.string()
    .url("Please enter a valid URL")
    .required("Please enter Deeplink URL"),
  price: Yup.string().required("Please enter Price"),
  oldPrice: Yup.string().required("Please enter Old Price"),
  discountPercentage: Yup.string().required("Please enter Discount Percentage"),
});

export const targetingFormSchema = Yup.object({
  gender: Yup.string().required("Please Select Your Gender"),
  ageRange: Yup.number()
    .required("Please Select Your Age Range")
    .positive("Market Place must be a Positive Number"),
  country: Yup.string().required("Please Select Your Country"),
  state: Yup.string().required("Please Select Your state"),
  city: Yup.string().required("Please Select Your City"),
  excludeState: Yup.boolean().required("Please select any option"),
  excludeCity: Yup.boolean().required("Please select any option"),
});

export const payoutFormSchema = Yup.object({
  paymentType: Yup.string().required("Please Select Your PaymentType"),
  currency: Yup.string().required("Please Select Your Currency"),
  marketplacePayout: Yup.number()
    .required("Please Enter market Place Payout")
    .positive("Market Place must be a Positive Number"),
  minOrderText: Yup.string().required("Enter Your Min Order text"),
  minOrderAmount: Yup.number()
    .required("Please Enter Min Order Amount")
    .positive("Min Order Must be Positive Number"),
});

export const onlineTrackingFormSchema = Yup.object({
  saleUrlPattern: Yup.string()
    .url("Please enter a valid URL")
    .required("Please enter Sale Url Pattern"),
  orderIdRegex: Yup.string().required("Please Select Your Order Id Regex"),
  sampleOrderId: Yup.string().required("Please Select Your Sample Order Id"),
  missingSalesCount: Yup.number().required(
    "Please Select Your Missing Sales Count"
  ),
  onlineTracking: Yup.boolean().required("Please select any option"),
});

export const editCategorySchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  icon: Yup.mixed().required("Please upload an icon"),
  banner: Yup.mixed().required("Please upload a banner"),
  description: Yup.string().required("Please enter a description"),
  priority: Yup.number()
    .required("Please enter priority")
    .positive("Priority must be a positive number"),
});

export const genderCreateFormSchema = Yup.object({
  name: Yup.string().min(3).required("Please enter your name"),
  title: Yup.string().min(2).required("Please enter title"),
  description: Yup.string().required("Please enter description"),
  priority: Yup.number().default(0).required("Please enter the priority"),
  active: Yup.boolean().default(true),
});
export const languageCreateFormSchema = Yup.object({
  name: Yup.string().min(3).required("Please enter your name"),
  description: Yup.string().required("Please enter description"),
  priority: Yup.number().default(0).required("Please enter the priority"),
  active: Yup.boolean().default(true),
});

export const settingsFormSchema = Yup.object({
  storeName: Yup.string().min(3).required("Please enter Store Name"),
  siteEmail: Yup.string().email().required("Please enter Site Email"),
  siteCopyright: Yup.string().required("Please enter Site Copyright"),
  allowRegistration: Yup.string().required("Please select any option"),
  mainWebsite: Yup.string()
    .url("Please enter a valid URL")
    .required("Please enter Main Website Url"),
  maintenanceMode: Yup.boolean().required("Please select any option"),
  active: Yup.boolean().default(true),
});

export const ageGroupCreateFormSchema = Yup.object({
  label: Yup.string().min(3).required("Please enter your label"),
  description: Yup.string().required("Please enter description"),
  active: Yup.boolean().default(true),
  priority: Yup.number().default(0).required("Please enter the priority"),
});

export const createSportsSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  title: Yup.string().required("Please enter your title"),
  icon: Yup.string(),
  banner: Yup.string(),
  priority: Yup.number().default(0).required("Please enter the priority"),
  description: Yup.string().required("Please enter a description"),
  active: Yup.boolean().default(true),
});

export const createSportCategorysSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  description: Yup.string().required("Please enter your description"),
  banner: Yup.string(),
  active: Yup.boolean().default(true),
  priority: Yup.number().default(0).required("Please enter the priority"),
});

export const coachingTypeCreateFormSchema = Yup.object({
  label: Yup.string().required("Please enter your name"),
  type: Yup.string().required("Please enter your type"),
  maxprice: Yup.number().required("Please enter minimum price"),
  minprice: Yup.number().required("Please enter maximum price"),
  priority: Yup.number().default(0).required("Please enter the priority"),
  active: Yup.boolean().default(true),
});

export const timeSlotCreateFormSchema = Yup.object({
  label: Yup.string().min(3).required("Please enter your name"),
  fromslot: Yup.string().required("Please enter your slot"),
  toslot: Yup.string().required("Please enter your slot"),
  active: Yup.boolean().default(true),
  priority: Yup.number().default(0).required("Please enter the priority"),
});

export const createPinSchema = Yup.object({
  city: Yup.string().required("Please enter the city name"),
  circleName: Yup.string().required("Please enter the circle name"),
  regionName: Yup.string().required("Please enter the region name"),
  divisionName: Yup.string().required("Please enter the division name"),
  pincode: Yup.string()
    .required("Please enter the pincode")
    .matches(/^\d{6}$/, "Pincode must be exactly 6 digits"),
  district: Yup.string().required("Please enter the district name"),
  stateName: Yup.string().required("Please enter the state name"),
  lat: Yup.number()
    .required("Please enter the latitude")
    .min(-90, "Latitude must be between -90 and 90")
    .max(90, "Latitude must be between -90 and 90"),
  lng: Yup.number()
    .required("Please enter the longitude")
    .min(-180, "Longitude must be between -180 and 180")
    .max(180, "Longitude must be between -180 and 180"),
  priority: Yup.number().default(0).required("Please enter the priority"),
  active: Yup.boolean().default(true),
});

export const editCoachProfileSchema = Yup.object().shape({
  fullName: Yup.string().required("* This field is mandatory"),
  sports: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("* This field is mandatory"),
        label: Yup.string().required("* This field is mandatory"),
      })
    )
    .required("* This field is mandatory")
    .min(1, "* This field is mandatory"),

  addresses: Yup.array()
    .of(
      Yup.object().shape({
        address: Yup.object().shape({
          address: Yup.string().required(
            "* Please enter valid address and pin code"
          ),
          pinCode: Yup.string()
            .required("* Please enter valid address and pin code")
            .matches(/^\d{6}$/, "Pincode should be 6 digits"),
          city: Yup.string().required("City is required"),
        }),
        coachingPeriods: Yup.object()
          .shape({
            days: Yup.array()
              .of(Yup.string())
              .required("* This field is mandatory"),
            weeks: Yup.array()
              .of(Yup.string())
              .required("* This field is mandatory"),
            months: Yup.array()
              .of(Yup.string())
              .required("* This field is mandatory"),
          })
          .required("* This field is mandatory"),
        coachingType: Yup.string().required("* This field is mandatory"),
        timeSlots: Yup.array()
          .of(
            Yup.object().shape({
              coachingType: Yup.string().required("* This field is mandatory"),
              slotPeriod: Yup.string().required(),
              timing: Yup.object()
                .shape({
                  from: Yup.string().required(),
                  to: Yup.string().required(),
                })
                .required(),
            })
          )
          .required("* This field is mandatory")
          .min(1, "* This field is mandatory"),

        ageGroup: Yup.array()
          .of(Yup.string().required())
          .required("* This field is mandatory")
          .min(1, "* This field is mandatory"),

        genderYouTeach: Yup.array()
          .of(Yup.string().required())
          .required("* This field is mandatory")
          .min(1, "* This field is mandatory"),
      })
    )
    .min(1, "* This field is mandatory"),

  description: Yup.string().required("* This field is mandatory"),
  experience: Yup.number().required("* This field is mandatory"),
  groupCoachingPriceRange: Yup.object().shape({
    min: Yup.number().nullable(),
    max: Yup.number().nullable(),
  }),
  privateCoachingPriceRange: Yup.object().shape({
    min: Yup.number().nullable(),
    max: Yup.number().nullable(),
  }),
  academies: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().nullable(),
      label: Yup.string().nullable(),
    })
  ),
});

export const editAcademyProfileSchema = Yup.object().shape({
  name: Yup.string().required("* This field is mandatory"),
  fullName: Yup.string().required("* This field is mandatory"),
  phoneNumber: Yup.string().required("* This field is mandatory"),
  email: Yup.string().optional().email("Invalid email"),
  description: Yup.string().required("* This field is mandatory"),
  sports: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("* This field is mandatory"),
        label: Yup.string().required("* This field is mandatory"),
      })
    )
    .required("* This field is mandatory")
    .min(1, "* This field is mandatory"),

  addresses: Yup.array()
    .of(
      Yup.object().shape({
        address: Yup.object().shape({
          address: Yup.string().required(
            "* Please enter valid address and pin code"
          ),
          pinCode: Yup.string()
            .required("* Please enter valid address and pin code")
            .matches(/^\d{6}$/, "Pincode should be 6 digits"),
          city: Yup.string().required("City is required"),
        }),
        coachingPeriods: Yup.object()
          .shape({
            days: Yup.array()
              .of(Yup.string())
              .required("* This field is mandatory"),
            weeks: Yup.array()
              .of(Yup.string())
              .required("* This field is mandatory"),
            months: Yup.array()
              .of(Yup.string())
              .required("* This field is mandatory"),
          })
          .required("* This field is mandatory"),
        coachingType: Yup.string().required("* This field is mandatory"),
        timeSlots: Yup.array()
          .of(
            Yup.object().shape({
              coachingType: Yup.string().required("* This field is mandatory"),
              slotPeriod: Yup.string().required(),
              timing: Yup.object()
                .shape({
                  from: Yup.string().required(),
                  to: Yup.string().required(),
                })
                .required(),
            })
          )
          .required("* This field is mandatory")
          .min(1, "* This field is mandatory"),

        ageGroup: Yup.array()
          .of(Yup.string().required())
          .required("* This field is mandatory")
          .min(1, "* This field is mandatory"),

        genderYouTeach: Yup.array()
          .of(Yup.string().required())
          .required("* This field is mandatory")
          .min(1, "* This field is mandatory"),
      })
    )
    .min(1, "* This field is mandatory"),

  groupCoachingPriceRange: Yup.object().shape({
    min: Yup.number().nullable(),
    max: Yup.number().nullable(),
  }),
  privateCoachingPriceRange: Yup.object().shape({
    min: Yup.number().nullable(),
    max: Yup.number().nullable(),
  }),
  academies: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().nullable(),
      label: Yup.string().nullable(),
    })
  ),
});

export const brandCreateFormSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  icon: Yup.string().required("Icon is required"),
  banner: Yup.string().required("Banner is required"),
  description: Yup.string().required("Please enter a description"),
});

export const createProductCategorySchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  icon: Yup.string().required("Icon is required"),
  banner: Yup.string().required("Banner is required"),
  description: Yup.string().required("Please enter a description"),
  sportId: Yup.string().required(),
  segmentId: Yup.string().required(),
  groupId: Yup.string().required(),
  discount: Yup.string(),
});

export const ecomSportsSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  title: Yup.string(),
  icon: Yup.string(),
  banner: Yup.string(),
  description: Yup.string().required("Please enter a description"),
  //sportCategory: Yup.string().required(),
});

export const ecomSegmentSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  sportId: Yup.string().required(),
  icon: Yup.string(),
  description: Yup.string().required("Please enter a description"),
});

export const ecomGroupSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  icon: Yup.string(),
  description: Yup.string().required("Please enter a description"),
});

export const ecomSubCategorySchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  categoryId : Yup.string().required("Please select a category"),
  icon: Yup.string(),
  description: Yup.string(),
});

export const ecomOfferSchema = Yup.object({
  sellerSKU: Yup.string().required("Please enter seller SKU"),
  productPrice: Yup.number()
    .required("Please enter product price")
    .positive("Product price must be positive"),
  quantity: Yup.number()
    .required("Please enter quantity")
    .positive("Quantity must be positive"),
  condition: Yup.string().required("Please enter product condition"),
  shippingChannel: Yup.string(),
  countryOfOrigin: Yup.string().required("Please enter country of origin"),
  hsnCode: Yup.string().required("Please enter HSN code"),
  productMRP: Yup.number()
    .required("Please enter product MRP")
    .positive("Product MRP must be positive"),
  offerAvailabilityFrom: Yup.date()
    .required("Please enter offer availability start date")
    .typeError("Please enter a valid date format"),
  offerAvailabilityTo: Yup.date()
    .required("Please enter offer availability end date")
    .typeError("Please enter a valid date format"),
});

export const ecomSizeSchema = Yup.object({
  sizeFor: Yup.string().required("Please enter sizeFor"),
  size: Yup.string().required("Please enter size"),
  sizeType: Yup.string().required("Please enter sizeType"),
  name: Yup.string().required("Please enter name"),
  subcategoryId: Yup.string().required("Subcategory ID is required"),
});

export const ecomPriceSchema = Yup.object({
  listPrice: Yup.number()
    .required("Please enter list price")
    .positive("List price must be positive"),
  sellPrice: Yup.number()
    .required("Please enter sell price")
    .positive("Sell price must be positive"),
  currency: Yup.string()
    .required("Please enter currency")
    .length(3, "Currency must be a 3-letter code"),
  startDate: Yup.date()
    .required("Please enter start date")
    .typeError("Please enter a valid date format"),
  endDate: Yup.date()
    .required("Please enter end date")
    .typeError("Please enter a valid date format"),
});

export const ecomSpecificationSchema = Yup.object({
  specKey: Yup.string().required("Please enter specification key"),
  specValue: Yup.string().required("Please enter specification value"),
});

export const ecomPlatformSchema = Yup.object({
  imageUrl: Yup.string().required("Please enter an image URL"),
  title: Yup.string().required("Please enter a title"),
  description: Yup.string().required("Please enter a description"),
  buttonText: Yup.string().required("Please enter button text"),
  buttonUrl: Yup.string().required("Please enter a button URL"),
});

export const ecomColorSchema = Yup.object({
  imageUrl: Yup.string()
    .url("Invalid URL format"),
  name: Yup.string().required("Please enter a title"),
  hexValue: Yup.string()
    .required("Please enter a description")
    .length(6, "Code must be a 6-length code"),
});

export const ecomDiscountSchema = Yup.object({
  coupon: Yup.string().required("Please enter a coupon code"),
  description: Yup.string(),
  discountType: Yup.string()
    .oneOf(
      ["PERCENTAGE", "FLAT", "FIXED"],
      "Discount type must be either 'PERCENTAGE' or 'FLAT'"
    )
    .required("Please select a discount type"),
  discountValue: Yup.number()
    .required("Please enter a discount value")
    .positive("Discount value must be positive"),
  startDate: Yup.date()
    .required("Please enter start date")
    .typeError("Please enter a valid date format"),
  endDate: Yup.date()
    .required("Please enter end date")
    .typeError("Please enter a valid date format"),
  maxUsage: Yup.number()
    .required("Please enter the maximum usage")
    .positive("Maximum usage must be positive")
    .integer("Maximum usage must be an integer"),
  maxUsagePerUser: Yup.number()
    .required("Please enter the maximum usage per user")
    .positive("Maximum usage per user must be positive")
    .integer("Maximum usage per user must be an integer"),
  minOrderAmount: Yup.number()
    .required("Please enter the minimum order amount")
    .positive("Minimum order amount must be positive"),
});

export const ecomShippingSchema = Yup.object({
  shippingType: Yup.string()
    .required("Please enter a shipping type")
    .max(50, "Shipping type can't be longer than 50 characters"),
  shippingDescription: Yup.string()
    .required("Please enter a shipping description")
    .max(500, "Description can't be longer than 500 characters"),
});

export const ecomTaxSchema = Yup.object({
  taxCode: Yup.string().required("Please enter the tax code"),
  description: Yup.string().required("Please enter a description"),
});

export const AdvertisementCreateFormSchema = Yup.object({
  title: Yup.string().required("Please enter your name"),
  icon: Yup.string(),
  banner: Yup.string(),
  description: Yup.string().required("Please enter a description"),
});

export const ReviewCreateFormSchema = Yup.object({
  productId: Yup.string().required("Product ID is required"),
  userId: Yup.string().required("User ID is required"),
  score: Yup.number()
    .required("Score is required")
    .min(1, "Score must be at least 1")
    .max(5, "Score can be at most 5"),
  review: Yup.string().required("Review is required"),
});

export const AttributeCreateFormSchema = Yup.object({
  type: Yup.string().required("Type is required"),
  placeholder: Yup.string().required("Placeholder is required"),
  options: Yup.array()
    .of(Yup.string()),
  inputType: Yup.string(),
  title: Yup.string().required("Title is required"),
  subCategoryId: Yup.string().required("Subcategory ID is required"),
});


export const CollectionFormSchema = Yup.object({
  collectionType: Yup.string().required("Collection type is required"),
  products: Yup.array()
    .of(Yup.string().required("Product ID is required"))
    .min(1, "At least one product must be selected")
});