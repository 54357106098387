
import React from "react";
import Button from "@mui/material/Button";

const TotalPagesMoreThanFour = ({
  currentPage,
  visitedPages,
  handlePageChange,
  totalPages,
}) => {
  const renderButton = (page) => (
    <Button
      key={page}
      onClick={() => handlePageChange(page)}
      sx={{
        color: currentPage === page ? "#4a00e0" : "#000",
        border: currentPage === page ? "1px solid #4a00e0" : "1px solid #d3d3d3",
        fontFamily: "Avenir",
        fontSize: "13px",
        fontWeight: "400",
        lineHeight: "16px",
        backgroundColor: "white",
        borderRadius: "8px",
        minWidth: "32px",
        padding: "8px",
        margin: "0 4px",
        "&:hover": { border: "1px solid #4a00e0" },
      }}
    >
      {page}
    </Button>
  );

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {renderButton(1)}
      {renderButton(2)}
      {currentPage > 3 && <span style={{ margin: "0 4px" }}>...</span>}
      {currentPage > 2 && currentPage < totalPages - 1 && renderButton(currentPage)}
      {currentPage < totalPages - 2 && <span style={{ margin: "0 4px" }}>...</span>}
      {renderButton(totalPages - 1)}
      {renderButton(totalPages)}
    </div>
  );
};

export default TotalPagesMoreThanFour;
