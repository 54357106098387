import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Link,
  IconButton,
  TextField,
} from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink"; // Link icon for the view file button
import moment from "moment/moment";
import { useSelector } from "react-redux";
import instance from "../../../API/BaseUrl";
import { SELLER_URLS } from "../../../constants";

const ApprovalModal = ({ open, handleClose, approvalReq }) => {
  const [reason, setReason] = useState(approvalReq?.reason || "");
  const brandOrProduct = approvalReq.brands || approvalReq.products;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <Typography variant="h6">✖</Typography>
        </IconButton>

        {/* File Preview Section */}
        <Box
          sx={{
            display: "flex",
            gap: "40px",
            height: "300px",
            width: "800px",
            padding: "10px",
          }}
        >
          <Box
            sx={{
              border: "1px dashed #C4C4C4",
              padding: 2,
              textAlign: "center",
              marginBottom: 2,
              width: "50%",
              display: "flex-columns",
            }}
          >
            <Typography variant="subtitle1" color="primary">
              Preview of Updated file
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              Click below to previw the file
            </Typography>

            {/* View File Button */}
            <Button
              variant="outlined"
              endIcon={<InsertLinkIcon />}
              sx={{ mt: 2 }}
              component={Link}
              href={approvalReq?.documentUrl || "#"} // Provide actual file link here
              target="_blank"
            >
              View File
            </Button>
          </Box>

          <Box sx={{ display: "flex-column", width: "50%" }}>
            {/* Product Information */}
            <Box sx={{ textAlign: "left" }}>
              <Typography variant="h6" fontWeight="bold">
                {brandOrProduct?.name}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                <strong>Seller Name</strong> -{" "}
                {approvalReq?.users?.fullName || "NA"}
              </Typography>
              <Typography variant="body2">
                <strong>Date of Creation</strong> -{" "}
                {moment(brandOrProduct?.createdAt).format("DD/MM/YYYY") ||
                  "##/##/####"}
              </Typography>
              <Typography variant="body2">
                <strong>Date of Updation</strong> -{" "}
                {moment(brandOrProduct?.updatedAt).format("DD/MM/YYYY") ||
                  "##/##/####"}
              </Typography>
            </Box>

            {/* Approval Status */}
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">
                <strong>Approval Status</strong> -{" "}
                <Typography
                  component="span"
                  sx={{
                    color:
                      brandOrProduct?.approvalStatus === "APPROVED"
                        ? "green"
                        : brandOrProduct?.approvalStatus === "REJECTED"
                        ? "red"
                        : "orange",
                  }}
                >
                  {brandOrProduct?.approvalStatus}
                </Typography>
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                variant="outlined"
                fullWidth
                placeholder={"Reason of Rejection"}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                sx={{ mt: 1 }}
              />
            </Box>
          </Box>
        </Box>

        {/* Approve/Unapprove Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "50px",
            mt: 3,
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={() => handleClose("approve", approvalReq._id)}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleClose("reject", approvalReq._id, reason)}
          >
            Unapprove
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApprovalModal;
