import React, { useEffect , useState } from "react";
import { Box, Typography, Select, MenuItem, Autocomplete, TextField } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { attributeCreateAPI } from "../../../../Redux/slice/attributeEcom";
import { useDispatch, useSelector } from "react-redux";
import CreateFormField from "../../CustomFormFields/CreateFormField";
import { AttributeCreateFormSchema } from "../../../../Schemas";
import { styles } from "./styles";
import { subCategoryAllAPI } from "../../../../Redux/slice/subCategoryEcomList";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";


const AttributeCreatePage = () => {
 
  const dispatch = useDispatch();

  const initialValues = {
    type: "",
    title: "",
    placeholder: "",
    options: [],
    inputType: "",
    subCategoryId: "",
  };

  const subCatData = useSelector((state)=>state.subCategoryList.data) ;

  const handleSubmit = async (values) => {
    try {
      await formik.validateForm();
      const finalResponse = await dispatch(attributeCreateAPI(values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(subCategoryAllAPI());
  }, [dispatch]);


  const formik = CustomFormikHook(initialValues, AttributeCreateFormSchema, handleSubmit) ;

  return (

    <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Attribute</Typography>
        </Box>
        <Box sx={{'marginTop' : '20px'}}></Box>
        <Box sx={styles.categoryFormBox}>
      <form onSubmit={formik.handleSubmit}>
      <InputLabel id="demo-simple-select-label">Select Attribute</InputLabel>
        <Select
          label="Type"
          name="type"
          value={formik.values.type}
          onChange={formik.handleChange}
          error={formik.touched.type && Boolean(formik.errors.type)}
          sx={styles.selectField}
        >
          <MenuItem value="DROPDOWN">DROPDOWN</MenuItem>
          <MenuItem value="TEXT">TEXT</MenuItem>
        </Select> 


        <Box>
          <Box sx={{ minWidth: 120, marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">SubCategory</InputLabel>
              <Select
                name="subCategoryId"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.subCategoryId}
                label="Sports Category"
                onChange={formik.handleChange}
              >
                {subCatData?.map((category , index) => (
                  <MenuItem key={index} value={category?._id}>
                    {category?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>


        {formik.values.type === "TEXT" && ( 
          <>
        <CreateFormField
          label="Title"
          name="title"
          placeholder="Enter title"
          handleChange={formik.handleChange}
          value={formik.values.title}
          errors={formik.errors.title}
          touched={formik.touched.title}
        />
        <CreateFormField
          label="Placeholder"
          name="placeholder"
          placeholder="Enter placeholder"
          handleChange={formik.handleChange}
          value={formik.values.placeholder}
          errors={formik.errors.placeholder}
          touched={formik.touched.placeholder}
        />
         <CreateFormField
          label="inputType"
          name="inputType"
          placeholder="Enter inputType"
          handleChange={formik.handleChange}
          value={formik.values.inputType}
          errors={formik.errors.inputType}
          touched={formik.touched.inputType}
        />
        </>
   )}

        {formik.values.type === "DROPDOWN" && (
          <>
           
        <CreateFormField
          label="Title"
          name="title"
          placeholder="Enter title"
          handleChange={formik.handleChange}
          value={formik.values.title}
          errors={formik.errors.title}
          touched={formik.touched.title}
        />
        <CreateFormField
          label="Placeholder"
          name="placeholder"
          placeholder="Enter placeholder"
          handleChange={formik.handleChange}
          value={formik.values.placeholder}
          errors={formik.errors.placeholder}
          touched={formik.touched.placeholder}
        />

    <Box sx={{'marginTop' : '38px'}}></Box>
          
          <Autocomplete
            multiple
            options={[]}
            freeSolo
            value={formik.values.options}
            onChange={(event, newValue) => formik.setFieldValue("options", newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Options"
                placeholder="Add option"
                sx={styles.autocompleteField}
                error={formik.touched.options && Boolean(formik.errors.options)}
                helperText={formik.touched.options && formik.errors.options}
              />
            )}
          />
          <Box sx={{'marginTop' : '10px'}}></Box>

        </>
        )}

        <CustomButtonComponent type="submit" variant="contained" sx={{ marginTop: 2 }}>
          Create
        </CustomButtonComponent>
      </form>
    </Box>
    </Box>
  );
};

export default AttributeCreatePage;
