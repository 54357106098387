import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Typography,
    Switch,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
  import AddIcon from "@mui/icons-material/Add";
  import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
  import CustomPagination from "../../../../Components/CustomPagination";
  import DataInsertIntoTable from "../../../../Components/CustomTableComponent/dataInsertIntoTable";
  import { styles, tableHead } from "./styles";
  import { useDispatch, useSelector } from "react-redux";
  import editIcon from "../../../../Assets/Icons/Drawer/editIcon.svg";
  import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
  import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
  import {
    discountListAPI,
    discountListDeleteAPI,
    discountUpdateAPI,
    updateStatus
  } from "../../../../Redux/slice/discountEcom";
  import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
 import { downloadCSV } from "../../../../utils/utils";
 import moment from "moment";  
 import { useSearchParams } from "react-router-dom";

  const DiscountListPage = () => {
    const [discounts, setDiscounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
  
    const [keyOfSort, setKeyOfSort] = useState("coupon");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItems, setSelectedItems] = useState([]);
    const head = tableHead;
    const [searchParams]= useSearchParams() ;

    const discountData = useSelector((state) => state.discountEcomList.data);
    const pageInfo = useSelector((state) => state.discountEcomList.page);
  
    const totalItems = pageInfo?.itemCount;
    const totalPages = pageInfo?.pageCount;
    const itemsPerPage = pageInfo?.perPage;
  
    const handleExport = () => {
      const csvData = discountData?.map((row) => ({
        id: row?._id,
        status: row?.status,
        coupon: row?.coupon,
        discountType: row?.discountType,
        discountValue: row?.discountValue,
        startDate: moment(row?.startDate).format("DD/MM/YYYY HH:mm:ss"),
        endDate: moment(row?.endDate).format("DD/MM/YYYY HH:mm:ss"),
        maxUsage: row?.maxUsage,
        maxUsagePerUser: row?.maxUsagePerUser,
        minOrderAmount: row?.minOrderAmount,
        createdAt: moment(row?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
        updatedAt: moment(row?.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
        code: row?.code,
      }));
      downloadCSV(csvData, "discountData");
    };
  
    useEffect(() => {
      const handleStatus = (discount) => (event) => {
        console.log(event?.target?.checked);
        dispatch(discountUpdateAPI({id : discount._id , formData : {name : discount.name , active : event?.target?.checked}})) ;
        dispatch(updateStatus({ id : discount._id , active : event?.target?.checked }));
      };
  
      if (discountData) {
        let arr = discountData?.map((discount) => {
          return {
            actions: [
              {
                content: (
                  <Switch
                    checked={discount?.active}
                    onChange={handleStatus(discount)}
                  />
                ),
              },
              {
                content: (
                  <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />
                ),
              },
              { type: "ButtonWithImg", content: editIcon },
            ],
            id: [{ type: "text", content: discount?._id }],
            coupon: [{ type: "text", content: discount?.coupon }],
            discountType: [{ type: "text", content: discount?.discountType }],
            discountValue: [{ type: "text", content: discount?.discountValue }],
            startDate: [{ type: "text", content: moment(discount?.startDate).format("DD/MM/YYYY  HH:mm:ss") }],
            endDate: [{ type: "text", content:moment(discount?.endDate).format("DD/MM/YYYY  HH:mm:ss") }],
            maxUsage: [{ type: "text", content: discount?.maxUsage }],
            maxUsagePerUser: [
              { type: "text", content: discount?.maxUsagePerUser },
            ],
            minOrderAmount: [
              { type: "text", content: discount?.minOrderAmount },
            ],
            status: [{ type: "text", content: discount?.status }],
            createdAt: [{ type: "text", content:  moment(discount?.createdAt).format("DD/MM/YYYY  HH:mm:ss") }],
            updatedAt: [{ type: "text", content:  moment(discount?.updatedAt).format("DD/MM/YYYY  HH:mm:ss") }],
            code: [{ type: "text", content: discount?.code }],
          };
        });
        setDiscounts(arr);
        setLoading(false);
      }
    }, [discountData]);
  

    useEffect(() => {
      let q = searchParams.get("q");
      console.log("Here is the query", q);
        setLoading(true) ;
        dispatch(discountListAPI({ page: currentPage , search : q}));
    }, [dispatch, currentPage , searchParams]);
  
    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
  
    if (!discounts || discounts.length === 0) {
      return (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "red",
            fontFamily: "Avenir",
            fontSize: "40px",
            alignItems: "center",
          }}
        >
          No data available
        </Typography>
      );
    }
  
    const sortedBody = discounts.slice().sort((a, b) => {
      const nameA = a[keyOfSort]?.content;
      const nameB = b[keyOfSort]?.content;
  
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
  
      return 0;
    });
  
    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };
  
    const handleDelete = async () => {
      try {
        let arr1 = [...discounts];
        arr1 = arr1.filter(
          (Object) => !selectedItems.includes(String(Object?.id[0].content))
        );
        setDiscounts(arr1);
        for (let id of selectedItems) {
          dispatch(discountListDeleteAPI({ id }));
        }
        setSelectedItems([]);
      } catch (error) {
        console.error("Error deleting:", error);
      }
    };
  
    return (
      <>
        <Box sx={styles.mainBox}>
          <Box sx={styles.headingMainBox}>
            <Box sx={styles.headingTextBox}>Discounts List</Box>
            <Box sx={styles.headingButtonBox}>
              <Button sx={styles.filterButtonBox}>
                <Typography sx={styles.filterTypoText}>Filters</Typography>
                <img src={DownArrowIcon} alt="downArrowIcon" />
              </Button>
              <Button sx={styles.exportButtonBox} onClick={handleExport}>
                <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
                <Typography sx={styles.exportTypoText}>Export</Typography>
              </Button>
              <IconButton sx={styles.addIconButtonBox}>
                <AddIcon
                  sx={{
                    color: "primary.light",
                    padding: "0px",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box sx={styles.pagesDetailMainBox}>
            <Typography>
              <span style={{ color: "#171A1F", fontWeight: "700" }}>
                {discounts.length}
              </span>
              <span style={{ color: "#6E7787", marginLeft: "6px" }}>
                in total
              </span>
            </Typography>
  
            <Typography sx={{ marginLeft: "70px" }}>
              <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
              <span style={styles.pagesDetailNumber}>
                {(currentPage - 1) * itemsPerPage}
              </span>
              <span style={styles.pagesDetailText}>to</span>
              <span style={styles.pagesDetailNumber}>
                {currentPage * itemsPerPage}
              </span>
              <span style={styles.pagesDetailText}>of</span>
              <span style={styles.pagesDetailNumber}>{totalItems}</span>
              <span style={styles.pagesDetailText}>results</span>
            </Typography>
  
            <Box textAlign="center" ml="auto">
              {selectedItems.length > 0 ? (
                <IconButton
                  onClick={handleDelete}
                  aria-label="delete"
                  sx={{ color: "red" }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            pl: "6px",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <DataInsertIntoTable
            checkData={selectedItems}
            setCheckData={setSelectedItems}
            headingOfTable={head}
            bodyOfTable={discounts}
            headingRowColor={"teal"}
            setKeyOfSort={setKeyOfSort}
            headingTextColor={"#FFFFFF"}
            tableBodyColor={"white"}
            tableBodyTextColor={"#171A1F"}
            discounts={true}
          />
        </Box>
        <Box
          sx={{
            pl: "6px",
            marginTop: "20px",
          }}
        >
          <CustomPagination
            type={"text"}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
          />
        </Box>
      </>
    );
  };
  
  export default DiscountListPage;
  