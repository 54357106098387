import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { COACH_URLS } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const coachListAPI = createAsyncThunk("coachListAPI", async ({ page }) => {
  try {
    const response = await instance.get(`${COACH_URLS.LIST(page)}`);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const coachSearchAPI = createAsyncThunk("coachSearchAPI", async ({ search, page }) => {
  try {
    const response = await instance.get(`${COACH_URLS.SEARCH(search, page)}`);
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});


export const coachFilterAPI = createAsyncThunk("coachFilterAPI", async (data) => {
  try {
    const response = await instance.post(COACH_URLS.FILTER , data) ;
  return response.data;
  } catch (error) {
    console.log("Error", error) ; 
    return error;
  }
});


export const coachStatusUpdateAPI = createAsyncThunk(
  "coachStatusUpdateAPI",
  async (data) => {
    try {
      const response = await instance.put(COACH_URLS.STATUS(data.slug, data.id), data.body);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
)

export const coachDeleteAPI = createAsyncThunk(
  "coachStatusUpdateAPI",
  async (data) => {
    try {
      const response = await instance.delete(COACH_URLS.DELETE(data.slug, data.id), data.body);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
)

const coach = createSlice({
  name: "coachList",
  initialState,
  reducers: {
    deleteCoach: (state, action) => {
      const dataArray = [...state.data.data];
      // state.data.data = dataArray.filter((coach) => coach?.coachProfile?.slug !== action.payload.slug);
      state.data.data = dataArray.map((coach) =>
        coach?.coachProfile?.slug !== action.payload.slug
          ? coach
          : { ...coach, coachProfile: { ...coach.coachProfile, deleted: true, active: false } }
      );
    },
    updateCoachStatus: (state, action) => {
      const dataArray = [...state.data.data];
      state.data.data = dataArray.map((coach) =>
        coach?.coachProfile?.slug === action.payload.slug
          ? { ...coach, coachProfile: { ...coach.coachProfile, active: action.payload.status } }
          : coach
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(coachListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(coachListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(coachListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    builder.addCase(coachSearchAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(coachFilterAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
  },
});

export const { deleteCoach, updateCoachStatus } = coach.actions;
export default coach.reducer;
