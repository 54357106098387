import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { styles, tableHead, tableBody } from "./styles";
import CustomButtonComponent from "../../../../Components/CustomButton";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomPagination from "../../../../Components/CustomPagination";
import DataInsertIntoTable from "../../../../Components/CustomTableComponent/dataInsertIntoTable";
import OfferFormField from "./offerFormField";
import { useDispatch } from "react-redux";
import { fetchCategory } from "../../../../Redux/slice/offerList";

const OfferList = () => {
  const dispatch = useDispatch();
  const itemsPerPage = 5;
  const [keyOfSort, setKeyOfSort] = useState("priority");
  const [currentPage, setCurrentPage] = useState(1);
  const head = tableHead;
  const body = tableBody;
  const sortedBody = body.sort((a, b) => {
    const nameA = a[keyOfSort][0].content.toUpperCase();
    const nameB = b[keyOfSort][0].content.toUpperCase();
    return nameA.localeCompare(nameB);
  });
  const totalItems = sortedBody.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedBody.slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await dispatch(fetchCategory()).unwrap();
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [dispatch]);
  

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Box sx={styles.title}>Offers List</Box>
          <Box sx={styles.buttonContainer}>
            <Button sx={styles.button}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.createFormBox}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: "25%",
              }}
            >
              <OfferFormField />
            </Box>
            <Box
              sx={{
                width: "25%",
                marginLeft: "25px",
              }}
            >
              <OfferFormField />
            </Box>
            <Box
              sx={{
                width: "25%",
                marginLeft: "25px",
              }}
            >
              <OfferFormField />
            </Box>
            <Box
              sx={{
                width: "25%",
                marginLeft: "25px",
              }}
            >
              <OfferFormField />
            </Box>
          </Box>
          <Box sx={{ display: "flex", marginBottom: "30px" }}>
            <Box sx={{ marginRight: "100px", width: "33%" }}>
              <OfferFormField label={"Created At"} />
            </Box>
            <Box sx={{ width: "33%" }}>
              <OfferFormField label={"Last Updated"} />
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ marginRight: "25px" }}>
              <CustomButtonComponent sx={{ color: "white" }}>
                Clear
              </CustomButtonComponent>
            </Box>
            <Box>
              <CustomButtonComponent
                sx={{ color: "white", backgroundColor: "#FF9800" }}
              >
                Search
              </CustomButtonComponent>
            </Box>
          </Box>
        </Box>

        <Box sx={styles.pagesDetailMainBox}>
          <Typography>
            <span style={{ color: "#171A1F", fontWeight: "700" }}>
              {body.length}
            </span>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>
              in total
            </span>
          </Typography>

          <Typography sx={{ marginLeft: "70px" }}>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
            <span style={styles.pagesDetailNumber}>{startIndex + 1}</span>
            <span style={styles.pagesDetailText}>to</span>
            <span style={styles.pagesDetailNumber}>
              {endIndex < totalItems ? endIndex : totalItems}
            </span>
            <span style={styles.pagesDetailText}>of</span>
            <span style={styles.pagesDetailNumber}>{body.length}</span>
            <span style={styles.pagesDetailText}>results</span>
          </Typography>
        </Box>
        <Box>
          <DataInsertIntoTable
            headingOfTable={head}
            bodyOfTable={currentItems}
            headingRowColor={"teal"}
            setKeyOfSort={setKeyOfSort}
            headingTextColor={"#FFFFFF"}
            tableBodyColor={"white"}
            tableBodyTextColor={"#171A1F"}
          />
        </Box>
        <Box
          sx={{
            pl: "6px",
            marginTop: "20px",
          }}
        >
          <CustomPagination
            type={"text"}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default OfferList;
