import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { sellerOrdersListAPI } from "../../../Redux/slice/sellerSlice";
import CustomPagination from "../../../Components/CustomPagination";
import DataInsertIntoTable from "../../../Components/CustomTableComponent/dataInsertIntoTable";
import moment from "moment";

const tableHead = [
  {
    heading: [{ type: "text", content: "Order id" }],
    key: "id",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Product Name" }],
    key: "name",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Order/ Delivery time (EXP) " }],
    key: "OD_time",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Payment mode" }],
    key: "p_mode",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Delivery Mode" }],
    key: "d_mode",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Order price" }],
    key: "price",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Current Status of the Order" }],
    key: "status",
    sort: false,
  },
];

function OrdersList() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const sellerOrders = useSelector((state) => state.seller.orders);
  const [selectedItems, setSelectedItems] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [keyOfSort, setKeyOfSort] = useState("name");
  const [currentPage, setCurrentPage] = useState(1);

  const sortedBody = orders?.sort((a, b) => {
    const nameA = a[keyOfSort][0]?.content?.toUpperCase();
    const nameB = b[keyOfSort][0]?.content?.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const totalItems = sellerOrders?.pageInfo?.itemCount;
  const totalPages = sellerOrders?.pageInfo?.pageCount;
  const itemsPerPage = sellerOrders?.pageInfo?.perPage;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      setLoading(true);
      dispatch(sellerOrdersListAPI({ id, page: currentPage }));
    }
  }, [searchParams, dispatch, currentPage]);

  useEffect(() => {
    if (sellerOrders) {
      const arr = sellerOrders?.data?.map((order) => {
        return {
          id: [{ content: order.orderId, type: "text" }],
          name: [{ content: order.name, type: "text" }],
          OD_time: [{ content: moment(order.orderDate).format("DD MMM YYYY"), type: "text" }],
          p_mode: [{ content: order.payment_mode, type: "text" }],
          d_mode: [{ content: order.delivery_mode, type: "text" }],
          price: [{ content: order.paidAmount, type: "text" }],
          status: [{ content: order.status, type: "text" }],
        };
      });

      setOrders(arr);
      setLoading(false);
    }
  }, [sellerOrders]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      <Box
        sx={{
          pl: "6px",
          display: "flex",
          //overflowX: "hidden",
          // width : "calc(99vw - 310px)" ,
          overflowX: "auto",
          whiteSpace: "wrap",
        }}
      >
        <DataInsertIntoTable
          checkData={selectedItems}
          setCheckData={setSelectedItems}
          headingOfTable={tableHead}
          bodyOfTable={sortedBody}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>
    </Box>
  );
}

export default OrdersList;
