import { configureStore, combineReducers } from "@reduxjs/toolkit";
import loginReducer from "./loginSlice";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import categorySliceReducer from "./slice/categorySlice";
import offerListReducer from "./slice/offerList";
import storeListReducer from "./slice/storeList";
import createCategoryReducer from "./slice/createCategory";
import editeOfferReducer from "./slice/editeOffer";
import createPayoutReducer from "./slice/createPayout";
import createTargetingReducer from "./slice/createTargeting";
import createTrackingReducer from "./slice/createTracking";
import createGenderReducer from "./slice/creategender";
import genderListReducer from "./slice/genderList";
import uesersListReducer from "./slice/usersList";
import editeSettingReducer from "./slice/editeSetting";
import createDetailsReducer from "./slice/createDetails";
import acadmyListReducer from "./slice/acadmyList";
import coachListReducer from "./slice/coachList";
import createLanguageReducer from "./slice/languageCreate";
import languageListReducer from "./slice/languageList";
import ageGroupCreateReducer from "./slice/ageGroupCreate";
import ageGroupListReducer from "./slice/ageGroupList";
import sportsCreateReducer from "./slice/sportsCreate";
import sportsListReducer from "./slice/sportsList";
import sportsCategoryCreateReducer from "./slice/sportsCategoryCreate";
import sportsCategoryListReducer from "./slice/sportsCategoryList";
import coachingTypeCreateReducer from "./slice/coachingTypeCreate";
import coachingTypeListReducer from "./slice/coachingTypeList";
import timeSlotCreateReducer from "./slice/timeSlotCreate";
import timeSlotListReducer from "./slice/timeSlotList";
import languageEditReducer from "./slice/languageEdit";
import editProfileReducer from "./slice/editProfile";
import agegoupEditReducer from "./slice/ageGroupEdit";
import coachingTypeEditReducer from "./slice/coachingTypeEdit";
import genderEditReducder from "./slice/genderEdit";
import timeSlotEditReducer from "./slice/timeSlotEdit";
import ageGroupStatusUpdateReducer from "./slice/ageGroupEdit";
import pinListReducer from './slice/pinList'

import brandListReducer from './slice/brandList'
import ProductCategoryListReducer from './slice/categoryEcomList'
import sportEcomListReducer from './slice/sportEcomList'
import segmentListReducer from "./slice/segmentEcomList";
import groupListReducer from './slice/groupEcomList'
import subCategoryListReducer from './slice/subCategoryEcomList'
import returnListReducer from './slice/returnReasonEcom'
import offerListEcomReducer from './slice/offerEcom'
import sizeEcomListReducer from './slice/sizeEcom'
import priceEcomListReducer from './slice/priceEcom'
import specEcomListReducer from './slice/specificationsEcom'
import colorEcomListReducer from './slice/colorEcom'
import discountEcomListReducer from './slice/discountEcom'
import shippingEcomListReducer from './slice/shippingEcom'
import taxEcomListReducer from './slice/taxEcom'
import advertisementEcomListReducer from './slice/advEcom'
import platformEcomListReducer from './slice/platformEcom'
import reviewEcomListReducer from './slice/reviewEcom'
import productEcomListReducer from './slice/productEcom'
import attributeEcomListReducer from './slice/attributeEcom'
import sellerSlice from "./slice/sellerSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["login"],
};

const rootReducer = (state, action) => {
  
  return combineReducers({
    login: loginReducer,
    categorySlice: categorySliceReducer,
    offerList: offerListReducer,
    editOffer: editeOfferReducer,
    createCategory: createCategoryReducer,
    storeList: storeListReducer,
    createDetails: createDetailsReducer,
    createPayout: createPayoutReducer,
    createTargeting: createTargetingReducer,
    createTracking: createTrackingReducer,
    createGender: createGenderReducer,
    genderList: genderListReducer,
    userList: uesersListReducer,
    editeSetting: editeSettingReducer,
    acadmyList: acadmyListReducer,
    coachList: coachListReducer,
    createLanguage: createLanguageReducer,
    languageList: languageListReducer,
    ageGropuCreate: ageGroupCreateReducer,
    ageGroupList: ageGroupListReducer,
    sportsCreate: sportsCreateReducer,
    sportsList: sportsListReducer,
    pinList : pinListReducer , 
    sportsCategoryCreate: sportsCategoryCreateReducer,
    sportsCategoryList: sportsCategoryListReducer,
    coachingTypeCreate: coachingTypeCreateReducer,
    coachingTypeList: coachingTypeListReducer,
    timeSlotCreate: timeSlotCreateReducer,
    timeSlotList: timeSlotListReducer,
    languageEdit: languageEditReducer,
    editProfile: editProfileReducer,
    ageGroupEdit: agegoupEditReducer,
    coachingTypeEdit: coachingTypeEditReducer,
    genderEdit: genderEditReducder,
    timeSlotEdit: timeSlotEditReducer,
    ageGroupStatusUpdate: ageGroupStatusUpdateReducer,

    brandList : brandListReducer,
    ProductCategoryList : ProductCategoryListReducer ,
    sportEcomList : sportEcomListReducer , 
    segmentList : segmentListReducer,
    groupList : groupListReducer,
    subCategoryList : subCategoryListReducer , 
    returnList : returnListReducer , 
    offerEcomList : offerListEcomReducer , 
    sizeEcomList  :  sizeEcomListReducer,
    priceEcomList : priceEcomListReducer,
    specEcomList : specEcomListReducer ,
    colorEcomList : colorEcomListReducer , 
    discountEcomList :  discountEcomListReducer , 
    shippingEcomList : shippingEcomListReducer ,
    taxEcomList : taxEcomListReducer ,
    advertisementList : advertisementEcomListReducer ,
    platformList : platformEcomListReducer ,
    reviewEcomList : reviewEcomListReducer ,
    productList : productEcomListReducer ,
    attributeEcomList : attributeEcomListReducer,
    
    seller: sellerSlice
  })(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.MODE !== "production",

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});



export const persistor = persistStore(store);

export const ProviderWrapper = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </Provider>
  );
};


export { store };
