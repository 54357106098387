import { IMG_URL } from "../../constants";
import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";

export const imageUpload = createAsyncThunk("imageUpload", async (data) => {
    try {
      const response = await instance.post(IMG_URL.UPLOAD , data , {
        headers : {
            "Content-Type" : "Multipart/form-data" ,
        }
      });
       return response.data;
    } catch (error) {
      console.log("Error", error) ; 
      return error;
    }
  });
  
  