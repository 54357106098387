import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "../Create/styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomSegmentSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch  , useSelector} from "react-redux";
import { useSearchParams } from "react-router-dom";
import { imageUpload } from "../../../../Redux/slice/thunks"; 
import EditSegmentFormField from './editSegmentFormField'
import { sportEcomAllAPI} from "../../../../Redux/slice/sportEcomList";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { segmentGetByIdAPI, segmentUpdateAPI } from "../../../../Redux/slice/segmentEcomList";

const SegmentEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const initialValues = {
    name: "",
    icon: "",
    description: "",
    sportId : "" 
  };

  
  const sportGroupData = useSelector((state) => state.sportEcomList.data);
  

  const handleSubmit = async (values) => {
    try {
      const finalResponse = await dispatch(
        segmentUpdateAPI({
          id: searchParams.get("id"),
          formData: values,
        })
      );
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomSegmentSchema , handleSubmit);

  useEffect(() => {
     function fetchData() {
        dispatch(sportEcomAllAPI()) ;
    }
    fetchData();
  }, [dispatch]);


  const handleFileChange = async (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("platform", "CRMS");
      try {
        const response = await dispatch(imageUpload(formData)).unwrap();
        const filePath = response?.fileLocations?.[0];
        if (type === "icon") {
          formik.setFieldValue("icon", filePath);
        } 
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(segmentGetByIdAPI({ id: searchParams.get("id") }));
      data = data?.payload?.data?.data;
      formik.setValues({
        name: data?.name || "",
        icon: data?.icon || "",
        description: data?.description || "",
        sportId : data?.sportId || "",
      });
    };

    fetchData();
  }, [dispatch, searchParams]);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Edit Segment</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <EditSegmentFormField
          label={"Name"}
          name="name"
          placeholder={"Enter Name"}
          handleChange={formik.handleChange}
          value={formik.values.name}
          errors={formik.errors.name}
          touched={formik.touched.name}
        />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <EditSegmentFormField
              label={"Icon"}
              name="icon"
              placeholder={"Icon"}
              handleChange={formik.handleChange}
              value={formik.values.icon}
              errors={formik.errors.icon}
              touched={formik.touched.icon}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
            component="label"
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, "icon")}
            />
          </CustomButtonComponent>
        </Box>

      
        <EditSegmentFormField
          label={"Description"}
          name="description"
          placeholder={"Enter Description"}
          handleChange={formik.handleChange}
          value={formik.values.description}
          errors={formik.errors.description}
          touched={formik.touched.description}
        />

      <Box>
          <Box sx={{ minWidth: 120, marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Sports Category
              </InputLabel>
              <Select
                name="sportCategory"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.sportId || ""}
                label="Sports Category"
                onChange={formik.handleChange}
              >
                {sportGroupData?.map((sport) => (
                  <MenuItem
                    key={sport._id}
                    value={sport._id}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {sport?.name?.toLowerCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

<Box sx={{marginTop: "38px"}}></Box> 

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Update
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default SegmentEditPage;
