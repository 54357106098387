
import React, { useState, useEffect } from 'react';
import { Box, TextField, MenuItem, Paper, Typography , IconButton} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { usersSearchAPI } from '../../../../Redux/slice/usersList';
import useDebounce from '../../../../Hooks/debouncingHook';
import { useSearchParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Margin } from '@mui/icons-material';

const UserSearchDropdown = ({ formik }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.userList.data);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(formik.values.userName , 300);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDropdownOpen, setDropdownOpen] = useState(true);

    useEffect(() => {
    // Using the debounced search term to fetch the users list
    if (debouncedSearchTerm) {
      dispatch(usersSearchAPI({ search: debouncedSearchTerm, page: 1 }));
      setDropdownOpen(true);
      setSearchParams({ q: debouncedSearchTerm }); // Update URL with search term
    }

  }, [debouncedSearchTerm, dispatch]);

  const handleCloseDropdown = () => {
    setDropdownOpen(false);
  };

  const onChangeHandler = (user)=>{
      formik.setFieldValue("userId" , user._id) ;
      formik.setFieldValue("userName" , user.fullName) ;
  }

  const queryChangeHandler = (e)=>{
    formik.setFieldValue("userId" , "") ;
    formik.setFieldValue("userName" , e.target.value) ;
  }

  return (
    <Box sx={{ position: 'relative', width: '300px' }}>
      <TextField
        label="Type a user"
        placeholder="Type a user"
        value={formik.values.userName}
        name='userName'
        onChange={queryChangeHandler}
        fullWidth
        InputProps={{
          endAdornment: (
            <Box component="span" sx={{ cursor: 'pointer', paddingRight: '8px' }}>
              &#128269;
            </Box>
          ),
        }}
      />
      {isDropdownOpen && users?.data?.length > 0 && formik.values.userId === "" && (
        <Paper sx={{
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
          maxHeight: '200px',
          overflowY: 'auto',
          zIndex: 10,
        }}>
          <Typography variant="body2" sx={{ padding: '8px', backgroundColor: '#f0f0f0' }}>
            Please select:
            <IconButton size="small" sx={{marginLeft : "145px"}} onClick={handleCloseDropdown}>
              <CloseIcon fontSize="small" />
         </IconButton>
          </Typography>
        
          {users?.data?.map((user , index) => (
            <MenuItem
              key={index}
              value={user._id}
              onClick={()=>onChangeHandler(user)}
              sx={{ padding: '8px 16px' }}
            >
              {user.fullName}
            </MenuItem>
          ))}
        </Paper>
      )}
    </Box>
  );
};

export default UserSearchDropdown;
