import React from "react";
import { Box, Typography, Switch, IconButton } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "./styles";
import EditCategoryFormField from "./editCategoryFormField";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { editCategorySchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch } from "react-redux";
import { createCategoryAPI } from "../../../../Redux/slice/categorySlice";

const EditCategory = () => {
  const dispatch = useDispatch();

  const initialValues = {
    name: "",
    icon: "",
    banner: "",
    description: "",
    priority: "",
    status: false,
  };

  const formik = CustomFormikHook(initialValues, editCategorySchema);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await formik.validateForm();
      formik.handleSubmit();
      const finalResponse = await dispatch(createCategoryAPI(formik.values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Add Category</Typography>
          <Box sx={styles.filterButtonContainer}>
            <CustomButtonComponent sx={styles.filterButton}>
              <Typography sx={styles.filterButtonText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </CustomButtonComponent>
            <CustomButtonComponent sx={styles.exportButton}>
              <VerticalAlignBottomIcon />
              <Typography sx={styles.exportButtonText}>Export</Typography>
            </CustomButtonComponent>
            <IconButton sx={styles.addButton}>
              <AddIcon sx={styles.addButtonIcon} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <EditCategoryFormField
          label={"Name"}
          name="name"
          placeholder={"Enter Name"}
          handleChange={formik.handleChange}
          value={formik.values.name}
          errors={formik.errors.name}
          touched={formik.touched.name}
        />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <EditCategoryFormField
              label={"Icon"}
              name="icon"
              placeholder={"Icon"}
              handleChange={formik.handleChange}
              value={formik.values.icon}
              errors={formik.errors.icon}
              touched={formik.touched.icon}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
          </CustomButtonComponent>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <EditCategoryFormField
              label={"Banner"}
              name="banner"
              placeholder={"Banner"}
              handleChange={formik.handleChange}
              value={formik.values.banner}
              errors={formik.errors.banner}
              touched={formik.touched.banner}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
          </CustomButtonComponent>
        </Box>

        <EditCategoryFormField
          label={"Description"}
          name="description"
          placeholder={"Enter Description"}
          handleChange={formik.handleChange}
          value={formik.values.description}
          errors={formik.errors.description}
          touched={formik.touched.description}
        />
        <EditCategoryFormField
          label={"Priority"}
          name="priority"
          placeholder={"Enter"}
          handleChange={formik.handleChange}
          value={formik.values.priority}
          errors={formik.errors.priority}
          touched={formik.touched.priority}
        />
        <Box sx={{ marginTop: "30px" }}>
          <Typography sx={styles.editGenderFormStatusText}>Status</Typography>
          <Switch
            checked={formik.values.status}
            onChange={() =>
              formik.setFieldValue("status", !formik.values.status)
            }
          />
        </Box>

     
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Update
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => console.log("Reset clicked")}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
     
    </>
  );
};

export default EditCategory;
