import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  approvalGetByIdAPI,
  approvalRequestListAPI,
  ApproveSellerAPI,
  sellerBrandListAPI,
} from "../../../Redux/slice/sellerSlice";
import { Box, CircularProgress, Switch } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import editIcon from "../../../Assets/Icons/Drawer/editIcon.svg";
import CustomPagination from "../../../Components/CustomPagination";
import DataInsertIntoTable from "../../../Components/CustomTableComponent/dataInsertIntoTable";
import ApprovalModal from "./ApprovalModal";

const tableHead = [
  {
    heading: [{ type: "text", content: "Actions" }],
    key: "action",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Brand ID" }],
    key: "id",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Brand Name" }],
    key: "name",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Type" }],
    key: "type",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Brand Info" }],
    key: "info",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Approval Status" }],
    key: "approval_status",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Created At" }],
    key: "createdAt",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Updated At" }],
    key: "updatedAt",
    sort: false,
  },
];

function BrandApprovalList() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const sellerBrands = useSelector((state) => state.seller.brands);
  const approvalRequests = useSelector(
    (state) => state.seller.approvalRequests
  );
  const approvalReq = useSelector((state) => state.seller.approvalRequest);

  const [selectedItems, setSelectedItems] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [keyOfSort, setKeyOfSort] = useState("name");
  const [currentPage, setCurrentPage] = useState(1);

  const sortedBody = brands?.sort((a, b) => {
    const nameA = a[keyOfSort][0]?.content?.toUpperCase();
    const nameB = b[keyOfSort][0]?.content?.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const totalItems = sellerBrands?.pageInfo?.itemCount;
  const totalPages = sellerBrands?.pageInfo?.pageCount;
  const itemsPerPage = sellerBrands?.pageInfo?.perPage;

  const [modalOpen, setModalOpen] = useState(false); // Modal state

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleModalOpen = (id) => {
    dispatch(approvalGetByIdAPI({ id }));
    setModalOpen(true);
  };

  const handleModalClose = (action, id, reason) => {
    setModalOpen(false);
    if (action === "approve") {
      dispatch(
        ApproveSellerAPI({
          id,
          status: "APPROVED",
        })
      ).then((res) => {
        setBrands([])
        getData();
      });
      // Add your logic for approval here
    } else if (action === "reject") {
      // Add your logic for rejection here
      dispatch(
        ApproveSellerAPI({
          id,
          status: "REJECTED",
          reason,
        })
      ).then((res) => {
        setBrands([])
        getData();
      });
    }
  };

  const getData = () => {
    const id = searchParams.get("id");
    if (id) {
      setLoading(true);
      dispatch(sellerBrandListAPI({ id, page: currentPage }));
      dispatch(approvalRequestListAPI({ id, page: currentPage }));
    }
  };

  useEffect(() => {
    setBrands([])
    getData();
  }, [searchParams, dispatch, currentPage]);

  useEffect(() => {
    if (sellerBrands) {
      const arr = sellerBrands?.data?.map((brand) => {
        return {
          action: [
            {
              content: (
                <VisibilityOutlinedIcon
                  sx={{ color: "primary.main", cursor: "pointer" }}
                  onClick={() => handleModalOpen(brand.approvalReqId)} // Open modal on click
                />
              ),
            },
            { type: "ButtonWithImg", content: editIcon },
          ],
          type: [{ type: "text", content: "Brand" }],
          id: [{ type: "text", content: brand._id }],
          name: [{ type: "text", content: brand.name }],
          info: [{ type: "text", content: brand.description }],
          approval_status: [{ type: "text", content: brand.approvalStatus }],
          createdAt: [{ type: "text", content: brand.createdAt }],
          updatedAt: [{ type: "text", content: brand.updatedAt }],
        };
      });
      setBrands((prev) => [...prev, ...arr]);
      setLoading(false);
    }
  }, [dispatch, sellerBrands]);

  useEffect(() => {
    if (approvalRequests) {
      const arr = approvalRequests?.data?.map((req) => {
        return {
          action: [
            {
              content: (
                <VisibilityOutlinedIcon
                  sx={{ color: "primary.main" }}
                  onClick={() => handleModalOpen(req?._id)}
                />
              ),
            },
            { type: "ButtonWithImg", content: editIcon },
          ],
          type: [{ type: "text", content: "Request" }],
          id: [{ type: "text", content: req.brands?._id }],
          name: [{ type: "text", content: req.brands?.name }],
          info: [{ type: "text", content: req.brands?.description }],
          approval_status: [
            { type: "text", content: req.brands?.approvalStatus },
          ],
          createdAt: [{ type: "text", content: req.brands?.createdAt }],
          updatedAt: [{ type: "text", content: req.brands?.updatedAt }],
        };
      });
      setBrands((prev) => [...prev, ...arr]);
      setLoading(false);
    }
  }, [approvalRequests, dispatch]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          pl: "6px",
          display: "flex",
          overflowX: "auto",
          whiteSpace: "wrap",
        }}
      >
        <DataInsertIntoTable
          checkData={selectedItems}
          setCheckData={setSelectedItems}
          headingOfTable={tableHead}
          bodyOfTable={brands}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
          totalPages={totalItems}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>

      {/* Modal for showing brand details */}
      {approvalReq && (
        <ApprovalModal
          open={modalOpen}
          handleClose={handleModalClose}
          approvalReq={approvalReq}
        />
      )}
    </Box>
  );
}

export default BrandApprovalList;
