import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../Pages/Login";
import PrivateRoute from "./privateRoutes";
import Dashboard from "../Pages/Dashboard";
import CoachingPage from "../Pages/Coaching";
import AcademyPage from "../Pages/Academy";
import SettingsPage from "../Pages/Settings/index";
import UsersList from "../Pages/Users";
// E-CommercePannel
import StorePage from "../Pages/ECommercePannel/Store/StoreList/index";
import EditStore from "../Pages/ECommercePannel/Store/EditStore/index";
import OfferList from "../Pages/ECommercePannel/Offer/OfferList";
import CategoryList from "../Pages/ECommercePannel/Category/CategoryList";
import EditCategory from "../Pages/ECommercePannel/Category/EditCategory";
import CreateOfferDetails from "../Pages/ECommercePannel/Offer/EditOffer";

// -----------------

import UserProfile from "../Pages/UserProfile";
import ProfileWeb from "../Pages/Coaching/CoachProfile";
import GenderCreatePage from "../Pages/WebsiteData/Gender/Create";
import GenderListPage from "../Pages/WebsiteData/Gender/List";
import AgeCreatePage from "../Pages/WebsiteData/Age/Create";
import LanguageCreatePage from "../Pages/WebsiteData/Language/Create";
import CoachingTypeCreatePage from "../Pages/WebsiteData/CoachingType/Create";
import TimeSlotCreatePage from "../Pages/WebsiteData/TimeSlot/Create";
import SportCreatePage from "../Pages/WebsiteData/Sport/Create";
import SportCategoryCreatePage from "../Pages/WebsiteData/SportCategory/Create";
import AgeListPage from "../Pages/WebsiteData/Age/List";
import LanguageListPage from "../Pages/WebsiteData/Language/List";
import TimeSlotListPage from "../Pages/WebsiteData/TimeSlot/List";
import SportCategoryListPage from "../Pages/WebsiteData/SportCategory/List";
import CoachingTypeListPage from "../Pages/WebsiteData/CoachingType/List";
import SportListPage from "../Pages/WebsiteData/Sport/List";
import EditCoachProfile from "../Pages/Coaching/EditCoachProfile";
import EditAcademyProfile from "../Pages/Academy/EditAcademyProfile";
import LanguageEditPAge from "../Pages/WebsiteData/Language/Edit";
import AgeGroupEditPage from "../Pages/WebsiteData/Age/Edit";
import CoachingTypeEditPage from "../Pages/WebsiteData/CoachingType/Edit";
import GenderEditPage from "../Pages/WebsiteData/Gender/Edit";
import TimeSlotEditPage from "../Pages/WebsiteData/TimeSlot/Edit";
import SportsCategoryEditPage from "../Pages/WebsiteData/SportCategory/Edit";
import SportsEditPage from "../Pages/WebsiteData/Sport/Edit";

import PinCodeCreatePage from "../Pages/WebsiteData/PinCode/Create";
import PinListPage from "../Pages/WebsiteData/PinCode/List";
import PinCodeEditPage from "../Pages/WebsiteData/PinCode/Edit";
import BrandCreatePage from "../Pages/EcomData/Brand/Create";
import BrandEditPage from "../Pages/EcomData/Brand/Edit";
import BrandListPage from "../Pages/EcomData/Brand/List";
import ProductCategoryCreatePage from "../Pages/EcomData/Category/Create";
import ProductCategoryEditPage from "../Pages/EcomData/Category/Edit";
import ProductCategoryListPage from "../Pages/EcomData/Category/List";
import SportsEcomEditPage from "../Pages/EcomData/Sport/Edit";
import SportEcomListPage from "../Pages/EcomData/Sport/List";
import SportEcomCreatePage from "../Pages/EcomData/Sport/Create";
import SegmentListPage from "../Pages/EcomData/Segment/List";
import SegmentEditPage from "../Pages/EcomData/Segment/Edit";
import SegmentCreatePage from "../Pages/EcomData/Segment/Create";
import GroupListPage from "../Pages/EcomData/Group/List";
import GroupEditPage from "../Pages/EcomData/Group/Edit";
import GroupCreatePage from "../Pages/EcomData/Group/Create";
import SubCategoryCreatePage from "../Pages/EcomData/SubCategory/Create";
import SubCategoryListPage from "../Pages/EcomData/SubCategory/List";
import SubCategoryEditPage from "../Pages/EcomData/SubCategory/Edit";
import ReturnListPage from "../Pages/EcomData/ReturnReasons/List";
import OfferEcomListPage from "../Pages/EcomData/Offer/List";
import OfferEcomCreatePage from "../Pages/EcomData/Offer/Create";
import OfferEcomEditPage from "../Pages/EcomData/Offer/Edit";
import SizeEcomEditPage from "../Pages/EcomData/Size/Edit";
import SizeEcomListPage from "../Pages/EcomData/Size/List";
import SizeEcomCreatePage from "../Pages/EcomData/Size/Create";
import PriceEcomEditPage from "../Pages/EcomData/Price/Edit";
import PriceEcomListPage from "../Pages/EcomData/Price/List";
import PriceEcomCreatePage from "../Pages/EcomData/Price/Create";
import SpecificationCreatePage from "../Pages/EcomData/Specifications/Create";
import SpecificationEditPage from "../Pages/EcomData/Specifications/Edit";
import SpecificationsListPage from "../Pages/EcomData/Specifications/List";
import ColorEcomCreatePage from "../Pages/EcomData/Color/Create";
import ColorEcomEditPage from "../Pages/EcomData/Color/Edit";
import ColorListPage from "../Pages/EcomData/Color/List";
import DiscountCreatePage from "../Pages/EcomData/Discount/Create";
import DiscountListPage from "../Pages/EcomData/Discount/List";
import DiscountEditPage from "../Pages/EcomData/Discount/Edit";
import ShippingEditPage from "../Pages/EcomData/Shipping/Edit";
import ShippingCreatePage from "../Pages/EcomData/Shipping/Create";
import ShippingListPage from "../Pages/EcomData/Shipping/List";
import TaxEditPage from "../Pages/EcomData/TaxCodes/Edit";
import TaxCreatePage from "../Pages/EcomData/TaxCodes/Create";
import TaxListPage from "../Pages/EcomData/TaxCodes/List";
import AdvertisementListPage from "../Pages/EcomData/BrandAdv/List";
import AdvertisementCreatePage from "../Pages/EcomData/BrandAdv/Create";
import AdvertisementEditPage from "../Pages/EcomData/BrandAdv/Edit";
import PlatformListPage from "../Pages/EcomData/Platform/List";
import PlatformCreatePage from "../Pages/EcomData/Platform/Create";
import PlatformEditPage from "../Pages/EcomData/Platform/Edit";
import ReviewEditPage from "../Pages/EcomData/Reviews/Edit";
import ReviewCreatePage from "../Pages/EcomData/Reviews/Create";
import ReviewListPage from "../Pages/EcomData/Reviews/List";
import AttributeEditPage from "../Pages/EcomData/Attributes/Edit";
import AttributeListPage from "../Pages/EcomData/Attributes/List";
import AttributeCreatePage from "../Pages/EcomData/Attributes/Create";
import SellersList from "../Pages/Seller/List/index"
import SellerProfile from "../Pages/Seller/profile/index"
import CollectionCreatePage from "../Pages/EcomData/Collections/Create";
import ProductListPage from "../Pages/ECommercePannel/Product/List";


//E-COM MASTERDATA



const routes = [
  {
    path: "/",
    element: <Login />,
    isPrivate: false,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    isPrivate: true,
  },

  // E-Commerce_Pannel first
  {
    path: "/ecommercepanel/store",
    element: <StorePage />,
    isPrivate: true,
  },
  {
    path: "/ecommercepanel/store/list",
    element: <StorePage />,
    isPrivate: true,
  },
  {
    path: "/ecommercepanel/store/editstore",
    element: <EditStore />,
    isPrivate: true,
  },
  {
    path: "/ecommercepanel/offer/list",
    element: <OfferList />,
    isPrivate: true,
  },

  {
    path: "/ecommercepanel/offer/create",
    element: <CreateOfferDetails />,
    isPrivate: true,
  },

  {
    path: "/ecommercepanel/category/list",
    element: <CategoryList />,
    isPrivate: true,
  },
  {
    path: "/ecommercepanel/category/editcategory",
    element: <EditCategory />,
    isPrivate: true,
  },
  {
    path: "/sellers",
    element: <SellersList />,
    isPrivate: true,
  },
  {
    path: "/sellers/profile",
    element: <SellerProfile />,
    isPrivate: true,
  },

  {
    path: "/ecommercepanel/product/list",
    element: <ProductListPage/>,
    isPrivate: true,
  },


  {
    path: "/ecom/brand/create",
    element: <BrandCreatePage />,
    isPrivate: true,
  },
  {
    path: "/ecom/brand/list/edit",
    element: <BrandEditPage />,
    isPrivate: true,
  },


  {
    path: "/ecom/brand/list",
    element: <BrandListPage />,
    isPrivate: true,
  },


  {
    path: "/ecom/category/create",
    element: <ProductCategoryCreatePage />,
    isPrivate: true,
  },

  {
    path: "/ecom/category/create",
    element: <ProductCategoryCreatePage />,
    isPrivate: true,
  },

  {
    path: "/ecom/category/list",
    element: < ProductCategoryListPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/category/list/edit",
    element: <ProductCategoryEditPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/sport/create",
    element: <SportEcomCreatePage />,
    isPrivate: true,
  },


  {
    path: "/ecom/sport/list",
    element: < SportEcomListPage />,
    isPrivate: true,
  },
  
  {
    path: "/ecom/sport/list/edit",
    element: <SportsEcomEditPage />,
    isPrivate: true,
  },


  {
    path: "/ecom/segment/create",
    element: <SegmentCreatePage />,
    isPrivate: true,
  },


  {
    path: "/ecom/segment/list",
    element: < SegmentListPage />,
    isPrivate: true,
  },
  
  {
    path: "/ecom/segment/list/edit",
    element: <SegmentEditPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/group/create",
    element: <GroupCreatePage />,
    isPrivate: true,
  },

  {
    path: "/ecom/group/list",
    element: < GroupListPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/group/list/edit",
    element: <GroupEditPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/sub-category/create",
    element: <SubCategoryCreatePage />,
    isPrivate: true,
  },

  {
    path: "/ecom/sub-category/list",
    element: < SubCategoryListPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/sub-category/list/edit",
    element: <SubCategoryEditPage />,
    isPrivate: true,
  },

  
  {
    path: "/ecom/offer/create",
    element: <OfferEcomCreatePage />,
    isPrivate: true,
  },

  {
    path: "/ecom/offer/list",
    element: < OfferEcomListPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/offer/list/edit",
    element: <OfferEcomEditPage />,
    isPrivate: true,
  },


  {
    path: "/ecom/size/create",
    element: <SizeEcomCreatePage />,
    isPrivate: true,
  },

  {
    path: "/ecom/size/list",
    element: < SizeEcomListPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/size/list/edit",
    element: <SizeEcomEditPage />,
    isPrivate: true,
  },


  {
    path: "/ecom/price/create",
    element: <PriceEcomCreatePage />,
    isPrivate: true,
  },

  {
    path: "/ecom/price/list",
    element: < PriceEcomListPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/price/list/edit",
    element: <PriceEcomEditPage />,
    isPrivate: true,
  },


  {
    path: "/ecom/specification/create",
    element: <SpecificationCreatePage />,
    isPrivate: true,
  },

  {
    path: "/ecom/specification/list",
    element: < SpecificationsListPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/specification/list/edit",
    element: <SpecificationEditPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/color/create",
    element: <ColorEcomCreatePage />,
    isPrivate: true,
  },

  {
    path: "/ecom/color/list",
    element: < ColorListPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/color/list/edit",
    element: <ColorEcomEditPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/discount/create",
    element: <DiscountCreatePage />,
    isPrivate: true,
  },

  {
    path: "/ecom/discount/list",
    element: < DiscountListPage />,
    isPrivate: true,
  },

  {
    path: "/ecom/discount/list/edit",
    element: <DiscountEditPage />,
    isPrivate: true,
  },
  

  {
    path: "/ecom/shipping/list/edit",
    element: <ShippingEditPage />,
    isPrivate: true,
  },

  
  {
    path: "/ecom/shipping/create",
    element: <ShippingCreatePage />,
    isPrivate: true,
  },

  
  {
    path: "/ecom/shipping/list",
    element: <ShippingListPage/>,
    isPrivate: true,
  },
  

{  
  path: "/ecom/tax/list/edit",
  element: <TaxEditPage />,
  isPrivate: true,
},


{
  path: "/ecom/tax/create",
  element: <TaxCreatePage />,
  isPrivate: true,
},


{
  path: "/ecom/tax/list",
  element: <TaxListPage/>,
  isPrivate: true,
},




{  
  path: "/ecom/platform/list/edit",
  element: <PlatformEditPage />,
  isPrivate: true,
},


{
  path: "/ecom/platform/create",
  element: <PlatformCreatePage />,
  isPrivate: true,
},


{
  path: "/ecom/platform/list",
  element: <PlatformListPage/>,
  isPrivate: true,
},



{  
  path: "/ecom/adv/list/edit",
  element: <AdvertisementEditPage />,
  isPrivate: true,
},


{
  path: "/ecom/adv/create",
  element: <AdvertisementCreatePage />,
  isPrivate: true,
},


{
  path: "/ecom/adv/list",
  element: <AdvertisementListPage/>,
  isPrivate: true,
},



{  
  path: "/ecom/review/list/edit",
  element: <ReviewEditPage />,
  isPrivate: true,
},


{
  path: "/ecom/review/create",
  element: <ReviewCreatePage />,
  isPrivate: true,
},


{
  path: "/ecom/review/list",
  element: <ReviewListPage/>,
  isPrivate: true,
},

{
path: "/ecom/attribute/create",
element: <AttributeCreatePage />,
isPrivate: true,
},

{  
  path: "/ecom/attribute/list/edit",
  element: <AttributeEditPage />,
  isPrivate: true,
},

{
path: "/ecom/attribute/list",
element: <AttributeListPage/>,
isPrivate: true,
},

{
  path: "/ecom/collection/create",
  element: <CollectionCreatePage />,
  isPrivate: true,
  },
  
  {  
    path: "/ecom/collection/list/edit",
    element: <collectionEditPage />,
    isPrivate: true,
  },
  
  {
  path: "/ecom/collection/list",
  element: <AttributeListPage/>,
  isPrivate: true,
  },
  

  {
    path: "/ecom/return-reasons/list",
    element: < ReturnListPage />,
    isPrivate: true,
  },


  {
    path: "/coaching",
    element: <CoachingPage />,
    isPrivate: true,
  },
  // {
  //   path: "/coaching/coachprofile",
  //   element: <ProfileWeb />,
  //   isPrivate: true,
  // },
  {
    path: "/coaching/editprofile",
    element: <EditCoachProfile />,
    isPrivate: true,
  },
  {
    path: "/master/language/create",
    element: <LanguageCreatePage />,
    isPrivate: true,
  },
  {
    path: "/master/agegroup/create",
    element: <AgeCreatePage />,
    isPrivate: true,
  },
  {
    path: "/master/coachingtype/create",
    element: <CoachingTypeCreatePage />,
    isPrivate: true,
  },
  {
    path: "/master/coachingtype/list",
    element: <CoachingTypeListPage />,
    isPrivate: true,
  },
  {
    path: "/master/coachingtype/list/edit",
    element: <CoachingTypeEditPage />,
    isPrivate: true,
  },

  {
    path: "/master/timeslot/create",
    element: <TimeSlotCreatePage />,
    isPrivate: true,
  },
  {
    path: "/master/sport/create",
    element: <SportCreatePage />,
    isPrivate: true,
  },
  {
    path: "/master/sport/list",
    element: <SportListPage />,
    isPrivate: true,
  },
  {
    path: "/master/sport/list/edit",
    element: <SportsEditPage />,
    isPrivate: true,
  },


  {
    path: "/master/pincode/create",
    element: <PinCodeCreatePage />,
    isPrivate: true,
  },
  {
    path: "/master/pincode/list",
    element: <PinListPage />,
    isPrivate: true,
  },
  {
    path: "/master/pincode/list/edit",
    element: <PinCodeEditPage />,
    isPrivate: true,
  },


  {
    path: "/master/sportcategory/create",
    element: <SportCategoryCreatePage />,
    isPrivate: true,
  },
  {
    path: "/academy",
    element: <AcademyPage />,
    isPrivate: true,
  },
  // {
  //   path: "/academy/academyprofile",
  //   element: <AcademyProfile />,
  //   isPrivate: true,
  // },
  {
    path: "/academy/editprofile",
    element: <EditAcademyProfile />,
    isPrivate: true,
  },
  {
    path: "/settings",
    element: <SettingsPage />,
    isPrivate: true,
  },
  {
    path: "/users",
    element: <UsersList />,
    isPrivate: true,
  },

  {
    path: "/master/gender/list",
    element: <GenderListPage />,
    isPrivate: true,
  },
  {
    path: "/master/gender/list/edit",
    element: <GenderEditPage />,
    isPrivate: true,
  },
  {
    path: "/master/agegroup/list",
    element: <AgeListPage />,
    isPrivate: true,
  },
  {
    path: "/master/agegroup/list/edit",
    element: <AgeGroupEditPage />,
    isPrivate: true,
  },
  {
    path: "/master/language/list",
    element: <LanguageListPage />,
    isPrivate: true,
  },
  {
    path: "/master/language/list/edit",
    element: <LanguageEditPAge />,
    isPrivate: true,
  },

  {
    path: "/master/timeslot/list",
    element: <TimeSlotListPage />,
    isPrivate: true,
  },
  {
    path: "/master/timeslot/list/edit",
    element: <TimeSlotEditPage />,
    isPrivate: true,
  },
  {
    path: "/master/sportcategory/list",
    element: <SportCategoryListPage />,
    isPrivate: true,
  },
  {
    path: "/master/sportcategory/list/edit",
    element: <SportsCategoryEditPage />,
    isPrivate: true,
  },

  {
    path: "/master/gender/create",
    element: <GenderCreatePage />,
    isPrivate: true,
  },
  {
    path: "/userprofile",
    element: <UserProfile />,
    isPrivate: true,
  },
];

const AllRoutes = () => {
  return (
    <Router>
      <Routes>
        {routes &&
          routes.map((route, index) => {
            if (route.path === "/") {
              return (
                <Route key={index} path={route.path} element={route.element} />
              );
            } else {
              return (
                <Route
                  exact
                  key={index}
                  path={route.path}
                  element={<PrivateRoute>{route.element}</PrivateRoute>}
                />
              );
            }
          })}
      </Routes>
    </Router>
  );
};

export default AllRoutes;
