
import React, { useEffect, useState } from "react";
import { Box, Typography, Switch, IconButton } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "../Create/styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomSportsSchema  } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch } from "react-redux";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { sportsCategoryListAPI } from "../../../../Redux/slice/sportsCategoryList";
import EditSportsFormField from "./editSportsFormField";
import { useSearchParams } from "react-router-dom";

import { imageUpload } from "../../../../Redux/slice/thunks"; 
import { sportEcomGetByIdAPI, sportEcomUpdateAPI } from "../../../../Redux/slice/sportEcomList";

const SportsEcomEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [sportsCategoryId, setSportsCategoryId] = useState([]);

  const initialValues = {
    name: "",
    icon: "",
    banner: "",
    description: "",
    //sportCategory: "",
  };

  const handleSubmit = async (values) => {

    try {
      debugger
      const finalResponse = await dispatch(
        sportEcomUpdateAPI({
          id: searchParams.get("id"),
          formData: values,
        })
      );
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomSportsSchema  , handleSubmit);


  const handleFileChange = async (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("platform", "CRMS");
      try {
        const response = await dispatch(imageUpload(formData)).unwrap();
        const filePath = response?.fileLocations?.[0];
        if (type === "icon") {
          formik.setFieldValue("icon", filePath);
        } else if (type === "banner") {
          formik.setFieldValue("banner", filePath);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  

  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(sportEcomGetByIdAPI({ id: searchParams.get("id") }));
      data = data?.payload?.data?.data;
      formik.setValues({
        name: data?.name || "",
        icon: data?.icon || "",
        banner: data?.banner || "",
        description: data?.description || "",
       // sportCategory: data?.sportCategory?._id || "",
  
      });
    };

    fetchData();
  }, [dispatch, searchParams]);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Edit Sport</Typography>
          <Box sx={styles.filterButtonContainer}>
            <CustomButtonComponent sx={styles.filterButton}>
              <Typography sx={styles.filterButtonText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </CustomButtonComponent>
            <CustomButtonComponent sx={styles.exportButton}>
              <VerticalAlignBottomIcon />
              <Typography sx={styles.exportButtonText}>Export</Typography>
            </CustomButtonComponent>
            <IconButton sx={styles.addButton}>
              <AddIcon sx={styles.addButtonIcon} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <EditSportsFormField
          label={"Name"}
          name="name"
          placeholder={"Enter Name"}
          handleChange={formik.handleChange}
          value={formik.values.name}
          errors={formik.errors.name}
          touched={formik.touched.name}
        />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <EditSportsFormField
              label={"Icon"}
              name="icon"
              placeholder={"Icon"}
              handleChange={formik.handleChange}
              value={formik.values.icon}
              errors={formik.errors.icon}
              touched={formik.touched.icon}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
            component="label"
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, "icon")}
            />
          </CustomButtonComponent>
        </Box>
        {/* {formik.values.icon && (
          <Typography sx={styles.uploadedImageUrl}>{formik.values.icon}</Typography>
        )} */}

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <EditSportsFormField
              label={"Banner"}
              name="banner"
              placeholder={"Banner"}
              handleChange={formik.handleChange}
              value={formik.values.banner}
              errors={formik.errors.banner}
              touched={formik.touched.banner}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
            component="label"
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
            <input
              type="file"
              hidden
              onChange={(e) => handleFileChange(e, "banner")}
            />
          </CustomButtonComponent>
        </Box>
        {/* {formik.values.banner && (
          <Typography sx={styles.uploadedImageUrl}>{formik.values.banner}</Typography>
        )} */}

        <EditSportsFormField
          label={"Description"}
          name="description"
          placeholder={"Enter Description"}
          handleChange={formik.handleChange}
          value={formik.values.description}
          errors={formik.errors.description}
          touched={formik.touched.description}
        />

        {/* <Box>
          <Box sx={{ minWidth: 120, marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Sports Category
              </InputLabel>
              <Select
                name="sportCategory"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.sportCategory || ""}
                label="Sports Category"
                onChange={formik.handleChange}
              >
                {sportsCategoryId?.map((category) => (
                  <MenuItem
                    key={category._id}
                    value={category._id}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {category?.name?.toLowerCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box> */}

        <Box sx={{ marginTop: "30px" }}></Box>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Update
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => console.log("Reset clicked")}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default SportsEcomEditPage;
