import { Box, Typography } from "@mui/material";
import React from "react";
import CustomTextField from "./customTextField";
import InputError from "./inputError";

function AddressFields({ formik, addressIndex, title }) {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        {title}
      </Typography>
      <Box>
        <CustomTextField
          name={`addresses.${addressIndex}.address.address`}
          label={"Enter Address"}
          value={formik.values.addresses[addressIndex]?.address?.address}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          touched={
            formik.touched.addresses &&
            formik.touched.addresses[addressIndex]?.address?.address
          }
          error={
            formik.errors.addresses &&
            formik.errors.addresses[addressIndex]?.address?.address &&
            formik.touched.addresses &&
            formik.touched.addresses[addressIndex]?.address?.address
          }
        />
        <CustomTextField
          name={`addresses.${addressIndex}.address.pinCode`}
          label={"pincode"}
          type="number"
          value={formik.values.addresses[addressIndex]?.address?.pinCode}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          touched={
            formik.touched.addresses &&
            formik.touched.addresses[addressIndex]?.address?.pinCode
          }
          error={
            formik.errors.addresses &&
            formik.errors.addresses[addressIndex]?.address?.pinCode &&
            formik.touched.addresses &&
            formik.touched.addresses[addressIndex]?.address?.pinCode
          }
        />
        <CustomTextField
          name={`addresses.${addressIndex}.address.city`}
          label={"City"}
          value={formik.values.addresses[addressIndex]?.address?.city}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          touched={
            formik.touched.addresses &&
            formik.touched.addresses[addressIndex]?.address?.city
          }
          error={
            formik.errors.addresses &&
            formik.errors.addresses[addressIndex]?.address?.city &&
            formik.touched.addresses &&
            formik.touched.addresses[addressIndex]?.address?.city
          }
        />
        {formik.touched.addresses && formik.errors.addresses && (
          <>
            <InputError
              error={formik.errors.addresses[addressIndex]?.address?.address}
            />
            <InputError
              error={formik.errors.addresses[addressIndex]?.address?.pinCode}
            />
            <InputError
              error={formik.errors.addresses[addressIndex]?.address?.city}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

export default AddressFields;
