import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ecomColorSchema } from "../../../../Schemas";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import EditFormField from "../../CustomFormFields/EditFormField";
import { colorGetByIdAPI, colorUpdateAPI } from "../../../../Redux/slice/colorEcom";
import { styles } from "./styles";

const ColorEcomEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const initialValues = {
    imageUrl: "",
    name: "",
    hexValue: "",
  };

  const handleSubmit = async (values) => {
    try {
      const finalResponse = await dispatch(
        colorUpdateAPI({
          id: searchParams.get("id"),
          formData: values,
        })
      );
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomColorSchema, handleSubmit);

  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(colorGetByIdAPI({ id: searchParams.get("id") }));
      data = data?.payload?.data?.data;
      formik.setValues({
        imageUrl: data?.imageUrl || "",
        name: data?.name || "",
        hexValue: data?.hexValue || "",
      });
    };

    fetchData();
  }, [dispatch, searchParams]);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Edit Color</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <EditFormField
          label={"Image URL"}
          name="imageUrl"
          placeholder={"Enter Image URL"}
          handleChange={formik.handleChange}
          value={formik.values.imageUrl}
          errors={formik.errors.imageUrl}
          touched={formik.touched.imageUrl}
        />

        <EditFormField
          label={"Name"}
          name="name"
          placeholder={"Enter Name"}
          handleChange={formik.handleChange}
          value={formik.values.name}
          errors={formik.errors.name}
          touched={formik.touched.name}
        />

        <EditFormField
          label={"Hex Value"}
          name="hexValue"
          placeholder={"Enter Hex Value"}
          handleChange={formik.handleChange}
          value={formik.values.hexValue}
          errors={formik.errors.hexValue}
          touched={formik.touched.hexValue}
        />

        <Box sx={{ marginTop: "38px" }}></Box>

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Update
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default ColorEcomEditPage;
