import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { ATTRIBUTE_URLS1 } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};


export const attributeListAPI = createAsyncThunk("attributeListAPI", async ({page , search}) => {
  try {
    const response = await instance.get(`${ATTRIBUTE_URLS1.LIST(page , search)}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const attributeListDeleteAPI = createAsyncThunk(
  "attributeListDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${ATTRIBUTE_URLS1.DELETE(data.id)}`);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);


export const attributeGetByIdAPI = createAsyncThunk(
  "attributeGetByIdAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(ATTRIBUTE_URLS1.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);



export const attributeUpdateAPI = createAsyncThunk(
  "attributeUpdateAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        ATTRIBUTE_URLS1.UPDATE(data.id),
        data.formData
      );
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


export const attributeCreateAPI = createAsyncThunk(
  "attributeCreateAPI",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${ATTRIBUTE_URLS1.CREATE}`, formdata);
      // console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


const attributeEcomList = createSlice({
  name: "attributeEcomList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state?.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active : action.payload.active ,
          };
        }
        return item;
      });
      state.data = data;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(attributeListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(attributeListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
      state.page = action?.payload?.pageInfo;
    });
    builder.addCase(attributeListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { updateStatus } = attributeEcomList.actions;
export default attributeEcomList.reducer;
