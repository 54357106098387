import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import {PRODUCT_URLS1 , REVIEW_URLS1} from "../../constants";
import { toast } from 'sonner';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};


export const reviewListAPI = createAsyncThunk("reviewListAPI", async ({page , search}) => {
  try {
    const response = await instance.get(`${REVIEW_URLS1.LIST(page , search)}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const reviewListDeleteAPI = createAsyncThunk(
  "reviewListDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${REVIEW_URLS1.DELETE(data.id)}`);
      toast.success('Review deleted successfully!');
      return response.data;
    } catch (error) {
      console.log("Error", error);
      toast.error('Failed to delete Review.');
      return error;
    }
  }
);


export const reviewGetByIdAPI = createAsyncThunk(
  "reviewGetByIdAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(REVIEW_URLS1.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);



export const reviewUpdateAPI = createAsyncThunk(
  "reviewUpdateAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        REVIEW_URLS1.UPDATE(data.id),
        data.formData
      );
      toast.success('Review updated successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to update Review.');
      return rejectWithValue(err);
    }
  }
);


export const reviewCreateAPI = createAsyncThunk(
  "reviewCreateAPI",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${REVIEW_URLS1.CREATE}`, formdata);
      // console.log(response);
      toast.success('Review created successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to create Review.');
      return rejectWithValue(err);
    }
  }
);



const reviewEcomList = createSlice({
  name: "reviewEcomList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state?.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active : action.payload.active ,
          };
        }
        return item;
      });
      state.data = data;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(reviewListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(reviewListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
      state.page = action?.payload?.pageInfo;
    });
    
    builder.addCase(reviewListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { updateStatus } = reviewEcomList.actions;
export default reviewEcomList.reducer;
