import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

function TimePeriodSelector({ formik, timePeriodType, addressIndex }) {
  const timePeriods = useSelector((state) => state.editProfile.coachingPeriodsEnum);

  const isChecked = (name) => {
    return formik.values.addresses[addressIndex].coachingPeriods[
      timePeriodType
    ].includes(name);
  };

  const addTimePeriod = (period) => {
    let values = [
      ...formik.values.addresses[addressIndex].coachingPeriods[timePeriodType],
    ];
    values.push(period);
    formik.setFieldValue(
      `addresses[${addressIndex}].coachingPeriods.${timePeriodType}`,
      [...values]
    );
  };

  const removeTimePeriod = (period) => {
    let values = [
      ...formik.values.addresses[addressIndex].coachingPeriods[timePeriodType],
    ];
    values = values.filter((p) => p !== period);
    formik.setFieldValue(
      `addresses[${addressIndex}].coachingPeriods.${timePeriodType}`,
      [...values]
    );
  };

  const handleSelectPeriod = (e) => {
    const { name, checked } = e.target;

    if (checked) {
      addTimePeriod(name);
    } else {
      removeTimePeriod(name);
    }
  };

  return (
    <Box
      id="timePeriodSelector"
      sx={{
        display: "none",
        flexDirection: "column",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        maxHeight: "300px",
        overflowY: "scroll",
        backgroundColor: "#fff",
        border: "1px solid rgba(84, 84, 84, 0.19)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        padding: "20px 10px",
        zIndex: 100,
      }}
    >
      <FormGroup>
        {timePeriods[timePeriodType]?.map((timePeriod, i) => (
          <FormControlLabel
            key={i}
            label={timePeriod}
            control={
              <Checkbox
                name={timePeriod}
                checked={isChecked(timePeriod)}
                onChange={handleSelectPeriod}
                sx={{
                  "&.Mui-checked": {
                    color: "#242424",
                  },
                }}
              />
            }
          />
        ))}
      </FormGroup>
    </Box>
  );
}

export default TimePeriodSelector;
