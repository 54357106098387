import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { GENDER_URLS } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  selectedData: [],
};

export const genderListAPI = createAsyncThunk("genderListAPI", async () => {
  try {
    const response = await instance.get(`${GENDER_URLS.LIST}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const genderDeleteAPI = createAsyncThunk(
  "genderDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${GENDER_URLS.LIST}/${data.id}`);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);


export const GenderGetbyId = createAsyncThunk(
  "GenderGetbyId",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(GENDER_URLS.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

export const genderStatusUpdate = createAsyncThunk(
  "genderStatusUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        GENDER_URLS.UPDATE_STATUS(data.id),
        data.body
      );
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


export const GenderEditAPI = createAsyncThunk(
  "genderEdit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        GENDER_URLS.EDIT(data.id),
        data.formdata
      );
      console.log(data.formdata);
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

export const createGenderAPI = createAsyncThunk(
  "createGender",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${GENDER_URLS.CREATE}`, formdata);
      // console.log(response);;
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);




const gender = createSlice({
  name: "gender",
  initialState,
  reducers: {
    addSelectedData: (state, action) => {
      state.selectedData = [...state.selectedData, action.payload];
    },
    deleteSelectedData: (state, action) => {
      state.selectedData = state.selectedData.filter(
        (data) => data === action.payload
      );
    },
    updateStatus: (state, action) => {
      let data = state.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.status,
          };
        }
        return item;
      });
      state.data = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(genderListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(genderListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
    });
    builder.addCase(genderListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});
export default gender.reducer;
export const { addSelectedData, deleteSelectedData, updateStatus } =
  gender.actions;
