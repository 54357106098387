import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

const CustomTextFieldComponent = ({
  variant,
  label,
  placeholder,
  value,
  onChange,
  className,
  error,
  helperText,
  disabled,
  fullWidth,
  inputProps,
  ...props
}) => {
  return (
    <TextField
      variant={variant}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={className}
      error={error}
      helperText={helperText}
      disabled={disabled}
      fullWidth={fullWidth}
      inputProps={inputProps}
      
      {...props}
      sx={{...props.sx , backgroundColor : "white"}}
    />
  );
};

CustomTextFieldComponent.propTypes = {
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.object,
};

CustomTextFieldComponent.defaultProps = {
  variant: "outlined",
  placeholder: "",
  value: "",
  className: "",
  error: false,
  helperText: "",
  disabled: false,
  fullWidth: false,
  inputProps: {},
};

export default CustomTextFieldComponent;
