import {
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { styles } from "../Create/styles";
import { styled } from "@mui/system";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { timeSlotCreateFormSchema } from "../../../../Schemas";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  TimeSlotEditAPI,
  TimeSlotGetById,
} from "../../../../Redux/slice/timeSlotList";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

const RequiredIndicator = styled("span")({
  color: "red",
  marginRight: "2px",
});

const TimeSlotEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [initialValues, setInitialValues] = useState({
    label: "",
    fromslot: null,
    toslot: null,
    active: true,
    priority: 0,
  });
  const [isDataFetched, setIsDataFetched] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const data = {
        label: values.label,
        slot: {
          from: values.fromslot,
          to: values.toslot,
        },
        priority: values.priority,
        active: true,
      };
      const finalResponse = await dispatch(
        TimeSlotEditAPI({ id: searchParams.get("id"), formdata: data })
      );
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(
    initialValues,
    timeSlotCreateFormSchema,
    handleSubmit,
    false
  );

  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(
        TimeSlotGetById({ id: searchParams.get("id") })
      );
      data = data?.payload?.data?.data;
      setInitialValues({
        label: data?.label || "",
        fromslot: data?.slot?.from ? dayjs(data.slot.from, "HH:mm") : null,
        toslot: data?.slot?.to ? dayjs(data.slot.to, "HH:mm") : null,
        priority: data?.priority || 0,
      });
      setIsDataFetched(true);
    };

    fetchData();
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (isDataFetched) {
      formik.setValues(initialValues);
    }
  }, [isDataFetched, initialValues]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Edit Time slot</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.editGenderFormMainBox}>
        <Box sx={{ marginTop: "30px" }}>
          <Typography sx={styles.editGenderFormLabel}>
            <RequiredIndicator>*</RequiredIndicator>
            Label
          </Typography>
          <TextField
            sx={styles.editGenderFormTextField}
            placeholder="Enter Your Label"
            name="label"
            value={formik.values.label}
            onChange={formik.handleChange}
          />
          {formik.errors.label && formik.touched.label ? (
            <Typography
              sx={{ color: "red", fontSize: "12px", paddingX: "2px" }}
            >
              {formik.errors.label}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ marginTop: "30px" }}>
          <Typography sx={styles.editGenderFormLabel}>
            <RequiredIndicator>*</RequiredIndicator>
            Priority
          </Typography>
          <TextField
            sx={styles.editGenderFormTextField}
            placeholder="Enter Your priority"
            name="priority"
            type="number"
            value={formik.values.priority}
            onChange={formik.handleChange}
          />
          {formik.errors.priority && formik.touched.priority ? (
            <Typography
              sx={{ color: "red", fontSize: "12px", paddingX: "2px" }}
            >
              {formik.errors.priority}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ marginTop: "30px" }}>
          <Typography sx={styles.editGenderFormLabel}>
            <RequiredIndicator>*</RequiredIndicator>
            Slot From
          </Typography>
          <TimePicker
            ampm={false}
            value={formik.values.fromslot}
            onChange={(value) => formik.setFieldValue("fromslot", value)}
            renderInput={(params) => <TextField {...params} />}
          />
          {formik.errors.fromslot && formik.touched.fromslot ? (
            <Typography
              sx={{ color: "red", fontSize: "12px", paddingX: "2px" }}
            >
              {formik.errors.fromslot}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ marginTop: "30px" }}>
          <Typography sx={styles.editGenderFormLabel}>
            <RequiredIndicator>*</RequiredIndicator>
            Slot To
          </Typography>
          <TimePicker
            ampm={false}
            value={formik.values.toslot}
            onChange={(value) => formik.setFieldValue("toslot", value)}
            renderInput={(params) => <TextField {...params} />}
          />
          {formik.errors.toslot && formik.touched.toslot ? (
            <Typography
              sx={{ color: "red", fontSize: "12px", paddingX: "2px" }}
            >
              {formik.errors.toslot}
            </Typography>
          ) : null}
        </Box>

        <Box sx={styles.editGenderFormButtonMainBox}>
          <Button
            variant="contained"
            sx={styles.editGenderFormUpdateButton}
            onClick={formik.handleSubmit}
          >
            <BorderColorOutlinedIcon
              sx={styles.editGenderFormUpdateButtonIcon}
            />
            Update
          </Button>
          <Button
            variant="contained"
            sx={styles.editGenderFormResetButton}
            onClick={() => formik.resetForm()}
          >
            <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
            Reset
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default TimeSlotEditPage;
