import { Box } from "@mui/material";
import React from "react";

function SearchSuggestions({
  searchResult,
  index,
  formik,
  formikFieldName,
  clearSearch,
}) {
  const handleChoice = (res) => () => {
    const values = [...formik.values[formikFieldName]];
    values[index] = {
      label: res?.label?.toLowerCase() || res?.name?.toLowerCase(),
      id: res?._id,
    };
    formik.setFieldValue(formikFieldName, [...values]);
    clearSearch();
  };
  return (
    <Box
      sx={{
        width: "100%",
        position: "absolute",
        zIndex: 99,
        backgroundColor: "white",
        border: "1px solid #DADADA",
        borderRadius: "5px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        maxHeight: "200px",
        overflowY: "auto",
      }}
    >
      {searchResult?.map((res, i) => (
        <Box
          key={i}
          onClick={handleChoice(res)}
          sx={{
            display: "flex",
            padding: "8px 10px",
            gap: "10px",
            alignItems: "center",
            height: "60px",
            borderBottom: "1px solid #DADADA",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#F2F2F2",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // width: "30px",
              height: "100%",
              aspectRatio: 1,
              borderRadius: "50%",
              backgroundColor: "#F2F2F2",
            }}
          >
            
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontSize: "16px",
                fontWeight: 800,
                textTransform: "capitalize",
                color: "#242424",
              }}
            >
              {`${res.label?.toLowerCase() || res.name?.toLowerCase()}`}
            </span>
            {res.subLabel && (
              <span
                style={{
                  marginTop: "-5px",
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#858585",
                }}
              >{`${res.subLabel}`}</span>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default SearchSuggestions;
