import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "./styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomPriceSchema} from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch} from "react-redux";
import CreateFormField from "../../CustomFormFields/CreateFormField";
import { priceCreateAPI } from "../../../../Redux/slice/priceEcom";

const PriceEcomCreatePage = () => {
  
  const dispatch = useDispatch();
  const initialValues = {
    listPrice: "",
    sellPrice: "",
    currency: "",
    startDate: "",
    endDate: "",
  };

  const handleSubmit = async (values) => {
    try {
      await formik.validateForm();
      const finalResponse = await dispatch(priceCreateAPI(values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomPriceSchema, handleSubmit);
  

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Price</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <CreateFormField
          label={"List Price"}
          name="listPrice"
          placeholder={"Enter List Price"}
          handleChange={formik.handleChange}
          value={formik.values.listPrice}
          errors={formik.errors.listPrice}
          touched={formik.touched.listPrice}
        />

        <CreateFormField
          label={"Sell Price"}
          name="sellPrice"
          placeholder={"Enter Sell Price"}
          handleChange={formik.handleChange}
          value={formik.values.sellPrice}
          errors={formik.errors.sellPrice}
          touched={formik.touched.sellPrice}
        />

        <CreateFormField
          label={"Currency"}
          name="currency"
          placeholder={"Enter Currency"}
          handleChange={formik.handleChange}
          value={formik.values.currency}
          errors={formik.errors.currency}
          touched={formik.touched.currency}
        />

        <CreateFormField
          label={"Start Date"}
          name="startDate"
          placeholder={"Enter Start Date"}
          handleChange={formik.handleChange}
          value={formik.values.startDate}
          errors={formik.errors.startDate}
          touched={formik.touched.startDate}
          type="date"
        />

        <CreateFormField
          label={"End Date"}
          name="endDate"
          placeholder={"Enter End Date"}
          handleChange={formik.handleChange}
          value={formik.values.endDate}
          errors={formik.errors.endDate}
          touched={formik.touched.endDate}
          type="date"
        />

        <Box sx={{ marginTop: "38px" }}></Box>

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Create
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default PriceEcomCreatePage;
