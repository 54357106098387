import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "./styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomSizeSchema} from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch, useSelector } from "react-redux";
import CreateFormField from "../../CustomFormFields/CreateFormField";
import { sizeCreateAPI } from "../../../../Redux/slice/sizeEcom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { subCategoryAllAPI } from "../../../../Redux/slice/subCategoryEcomList";

const SizeEcomCreatePage = () => {
  
  const [sizes, setSizes] = useState("");
  
  const sizeTypeOptions = ["Regular", "Wide", "Narrow", "Extra Wide"];
  const nameOptions = ["SMALL", "MEDIUM", "LARGE", "EXTRA LARGE", "XXL", "XXXL", "XXXXL", "XXXXXL"];
 

  const dispatch = useDispatch();
  const initialValues = {
    sizeFor: "",
    size: "",
    sizeType: "",
    subcategoryId: "",
    name: "",
    
  };

  const subCatData = useSelector((state)=>state.subCategoryList.data) ;

  const handleSubmit = async (values) => {
    try {
      await formik.validateForm();
      const finalResponse = await dispatch(sizeCreateAPI(values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(subCategoryAllAPI());
  }, [dispatch]);
 

  const formik = CustomFormikHook(initialValues, ecomSizeSchema, handleSubmit);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Size</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <CreateFormField
          label={"Size For"}
          name="sizeFor"
          placeholder={"Enter Size For"}
          handleChange={formik.handleChange}
          value={formik.values.sizeFor}
          errors={formik.errors.sizeFor}
          touched={formik.touched.sizeFor}
        />

        <CreateFormField
          label={"Size"}
          name="size"
          placeholder={"Enter Size"}
          handleChange={formik.handleChange}
          value={formik.values.size}
          errors={formik.errors.size}
          touched={formik.touched.size}
        />

        <Box>
          <Box sx={{ minWidth: 120, marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">SubCategory</InputLabel>
              <Select
                name="subcategoryId"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.subcategoryId}
                label="Sports Category"
                onChange={formik.handleChange}
              >
                {subCatData?.map((category) => (
                  <MenuItem key={category?._id} value={category?._id}>
                    {category?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

<Box>
<Box sx={{ minWidth: 120, marginTop: "20px" }}>
<FormControl fullWidth>
  <InputLabel id="size-type-label">Size Type</InputLabel>
  <Select
    labelId="size-type-label"
    id="size-type"
    name="sizeType"
    value={formik.values.sizeType}
    onChange={formik.handleChange}
    error={formik.touched.sizeType && Boolean(formik.errors.sizeType)}
  >
    {sizeTypeOptions.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </Select>
  {formik.touched.sizeType && formik.errors.sizeType && (
    <Typography color="error">{formik.errors.sizeType}</Typography>
  )}
</FormControl>
</Box>
 </Box>




 <Box>
 <Box sx={{ minWidth: 120, marginTop: "20px" }}>
  <FormControl fullWidth>
  <InputLabel id="name-label">Name</InputLabel>
  
  <Select
    labelId="name-label"
    id="name"
    name="name"
    value={formik.values.name}
    onChange={formik.handleChange}
    error={formik.touched.name && Boolean(formik.errors.name)}
  >
    {nameOptions.map((option) => (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    ))}
  </Select>
  {formik.touched.name && formik.errors.name && (
    <Typography color="error">{formik.errors.name}</Typography>
  )}
</FormControl>
</Box>
</Box>

        <Box sx={{ marginTop: "38px" }}></Box> 

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Create
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default SizeEcomCreatePage;
