import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Box,
} from "@mui/material";

const TableHeadFill = (item) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",

        marginX: "20px",
        alignItems: "center",
      }}
    >
      {item?.map((content, key) => {
        return content;
      })}
    </Box>
  );
};

const TableBodyFill = (item) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {item?.map((content, key) => {
        return content;
      })}
    </Box>
  );
};

const CustomTableComponent = ({
  headingOfTable,
  bodyOfTable,
  headingRowColor,
  tableBodyColor,
  checkData,
  setCheckData,
}) => {
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      let ids = bodyOfTable?.map((data) => {
        let id = data?.id
          ? data?.id[0]?.props?.children
          : data?.slug[0]?.props?.children;
        return String(id);
      });
      setCheckData?.(ids);
    } else {
      setCheckData?.([]);
    }
  };

  const handleRowCheckboxChange = (rowData) => {
    let id = rowData?.id
      ? rowData?.id[0]?.props?.children
      : rowData?.slug[0]?.props?.children;

    setCheckData?.((prevData) =>
      prevData.includes(id)
        ? prevData.filter((data) => data !== id)
        : [...prevData, id]
    );
  };

  const isSelected = (rowData) =>
    checkData?.includes(
      rowData?.id
        ? rowData?.id[0]?.props?.children
        : rowData?.slug[0]?.props?.children
    );
  return (
    
    <TableContainer>
      <Table>
        <TableHead
          sx={{
            backgroundColor: headingRowColor ? headingRowColor : "white",
          }}
        >
          <TableRow>
            <TableCell>
              <Checkbox
                checked={checkData?.length === bodyOfTable?.length}
                onChange={handleSelectAll}
                sx={{
                  color: "white",
                  "&.Mui-checked": {
                    color: "white",
                  },
                  backgroundColor: "transparent",
                }}
              />
            </TableCell>
            {headingOfTable?.map((item) => (
              <TableCell key={item.key}>
                {TableHeadFill(item.heading)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody
          sx={{
            backgroundColor: tableBodyColor ? tableBodyColor : "white",
          }}
        >
          {bodyOfTable?.map((item, rowIndex) => {
            return (
              <TableRow key={rowIndex} hover>
                <TableCell>
                  <Checkbox
                    checked={isSelected(item)}
                    onClick={() => handleRowCheckboxChange(item)}
                  />
                </TableCell>
                {headingOfTable.map((content, colIndex) => (
                  <TableCell key={colIndex}>
                    {TableBodyFill(item[content.key])}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    
  );
};

export default CustomTableComponent;

