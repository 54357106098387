import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  approvalGetByIdAPI,
  ApproveSellerAPI,
  sellerProductsListAPI,
} from "../../../Redux/slice/sellerSlice";
import CustomPagination from "../../../Components/CustomPagination";
import DataInsertIntoTable from "../../../Components/CustomTableComponent/dataInsertIntoTable";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import editIcon from "../../../Assets/Icons/Drawer/editIcon.svg";
import { Box, CircularProgress, Switch } from "@mui/material";
import ProductDetailModal from "./ApprovalModal"; // Import the modal component

const tableHead = [
  {
    heading: [{ type: "text", content: "Actions" }],
    key: "action",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Product Id" }],
    key: "id",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Product Name" }],
    key: "name",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Quantity" }],
    key: "quantity",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Brand Name and info" }],
    key: "brand",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Product Price" }],
    key: "price",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Approval Status" }],
    key: "approval_status",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Buybox eligibility" }],
    key: "buybox",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Type" }],
    key: "type",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Created At" }],
    key: "createdAt",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Updated At" }],
    key: "updatedAt",
    sort: false,
  },
];

function ProductsList() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const sellerProducts = useSelector((state) => state.seller.products);
  const approvalReq = useSelector((state) => state.seller.approvalRequest);
  const [selectedItems, setSelectedItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [keyOfSort, setKeyOfSort] = useState("name");
  const [currentPage, setCurrentPage] = useState(1);

  const [modalOpen, setModalOpen] = useState(false); // Modal state

  const sortedBody = products?.sort((a, b) => {
    const nameA = a[keyOfSort][0]?.content?.toUpperCase();
    const nameB = b[keyOfSort][0]?.content?.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const totalItems = sellerProducts?.pageInfo?.itemCount;
  const totalPages = sellerProducts?.pageInfo?.pageCount;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleModalOpen = (id) => {
    dispatch(approvalGetByIdAPI({ id }));
    setModalOpen(true);
  };

  const handleModalClose = (action, id) => {
    setModalOpen(false);
    if (action === "approve") {
      dispatch(
        ApproveSellerAPI({
          id,
          status: "APPROVED",
        })
      ).then((res) => {
        getData();
      });
    } else if (action === "reject") {
      dispatch(
        ApproveSellerAPI({
          id,
          status: "REJECTED",
        })
      ).then((res) => {
        getData();
      });
    }
  };

  const getData = () => {
    const id = searchParams.get("id");
    if (id) {
      setLoading(true);
      dispatch(sellerProductsListAPI({ id, page: currentPage }));
    }
  };

  useEffect(() => {
    getData();
  }, [searchParams, dispatch, currentPage]);

  useEffect(() => {
    if (sellerProducts) {
      const arr = sellerProducts?.data?.map((product) => {
        return {
          action: [
            {
              content: (
                <VisibilityOutlinedIcon
                  sx={{ color: "primary.main", cursor: "pointer" }}
                  onClick={() => handleModalOpen(product?.approvalReqId)}
                />
              ),
            },
            { type: "ButtonWithImg", content: editIcon },
          ],
          id: [{ content: product?.productId, type: "text" }],
          approval_status: [{ content: product?.approvalStatus, type: "text" }],
          name: [{ content: product?.name, type: "text" }],
          quantity: [{ content: String(product?.quantity), type: "text" }],
          brand: [{ content: product?.brand, type: "text" }],
          price: [{ content: product?.price, type: "text" }],
          buybox: [{ content: product?.buybox, type: "text" }],
          type: [{ content: product?.type, type: "text" }],
          createdAt: [{ content: product?.createdAt, type: "text" }],
          updatedAt: [{ content: product?.updatedAt, type: "text" }],
        };
      });
      setProducts(arr);
      setLoading(false);
    }
  }, [sellerProducts]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          pl: "6px",
          display: "flex",
          overflowX: "auto",
          whiteSpace: "wrap",
        }}
      >
        <DataInsertIntoTable
          checkData={selectedItems}
          setCheckData={setSelectedItems}
          headingOfTable={tableHead}
          bodyOfTable={sortedBody}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>

      {/* Modal for showing product details */}
      {approvalReq && (
        <ProductDetailModal
          open={modalOpen}
          handleClose={handleModalClose}
          approvalReq={approvalReq}
        />
      )}
    </Box>
  );
}

export default ProductsList;
