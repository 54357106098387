import React from "react";
import { Box, Typography } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch } from "react-redux";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ecomSpecificationSchema} from "../../../../Schemas";
import CreateFormField from "../../CustomFormFields/CreateFormField";
import { specCreateAPI } from "../../../../Redux/slice/specificationsEcom.js";
import { styles } from "./styles";

const SpecificationCreatePage = () => {
  const dispatch = useDispatch();

  const initialValues = {
    specKey: "",
    specValue: "",
  };

  const handleSubmit = async (values) => {
    try {
      await formik.validateForm();
      const finalResponse = await dispatch(specCreateAPI(values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ecomSpecificationSchema, handleSubmit);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Specification</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <CreateFormField
          label={"Specification Key"}
          name="specKey"
          placeholder={"Enter Specification Key"}
          handleChange={formik.handleChange}
          value={formik.values.specKey}
          errors={formik.errors.specKey}
          touched={formik.touched.specKey}
        />

        <CreateFormField
          label={"Specification Value"}
          name="specValue"
          placeholder={"Enter Specification Value"}
          handleChange={formik.handleChange}
          value={formik.values.specValue}
          errors={formik.errors.specValue}
          touched={formik.touched.specValue}
        />

        <Box sx={{ marginTop: "38px" }}></Box>

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Create
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default SpecificationCreatePage;
