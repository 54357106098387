import React, { useEffect, useState } from "react";
import SelectCoachingType from "./selectCoachingType";
import SelectTimeSlot from "./selectTimeSlot";
import { coachingTypeListAPI } from "../../Redux/slice/coachingTypeList";
import { useDispatch } from "react-redux";
import { timeSlotListAPI } from "../../Redux/slice/timeSlotList";


function CoachingTypeAndTimeSlot({ formik, addressIndex }) {
  const [coachingTypeList, setCoachingTypeList] = useState([]);
  const [timeSlotList, setTimeSlotList] = useState([]);
  const [bothId, setBothId] = useState("");
  const dispatch = useDispatch();
  
  useEffect(() => {
    async function fetchData() {
      try {
        const coachingTypeResponse = await dispatch(
          coachingTypeListAPI()
        ).unwrap();
        const timeSlotResponse = await dispatch(timeSlotListAPI()).unwrap();
        setCoachingTypeList(coachingTypeResponse.data);
        setTimeSlotList(timeSlotResponse.data);

        let coachingType = coachingTypeResponse.data;
        let both = coachingType?.find((cType) => cType.type === "BOTH")?._id;

        setBothId(both);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [dispatch]);

  return (
    <div id="timings">
      <SelectCoachingType
        formik={formik}
        coachingTypeList={coachingTypeList}
        addressIndex={addressIndex}
      />
      {coachingTypeList?.length > 0 &&
      formik.values.addresses[addressIndex].coachingType === bothId ? (
        <>
          <SelectTimeSlot
            formik={formik}
            heading={"Select Time Slots for Group Coaching"}
            timeSlotList={timeSlotList}
            coachingType={coachingTypeList[0]?._id}
            addressIndex={addressIndex}
          />
          <SelectTimeSlot
            formik={formik}
            heading={"Select Time Slots for Private Coaching"}
            timeSlotList={timeSlotList}
            coachingType={coachingTypeList[1]?._id}
            addressIndex={addressIndex}
          />
        </>
      ) : (
        <SelectTimeSlot
          formik={formik}
          heading={"Select Time Slots"}
          timeSlotList={timeSlotList}
          coachingType={formik.values.addresses[addressIndex].coachingType}
          addressIndex={addressIndex}
        />
      )}
    </div>
  );
}

export default CoachingTypeAndTimeSlot;
