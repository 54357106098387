import React from "react";
import CustomTableComponent from ".";
import { Typography, Box, Avatar, Link, IconButton } from "@mui/material";
import CustomButtonComponent from "../CustomButton";
import Button from "@mui/material/Button";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useNavigate } from "react-router-dom";

const DataInsertIntoTable = ({
  headingOfTable,
  bodyOfTable,
  headingRowColor,
  tableBodyColor,
  headingTextColor,
  tableBodyTextColor,
  setKeyOfSort,
  setHandleOpen,
  setHandleAcademyClick,
  coachHandle,
  coachHaldleOpen,
  modalOpen = false,
  coachList = false,
  academyList = false,
  editLanguage = false,
  ageGroup = false,
  editGender = false,
  timeSlot = false,
  coachingType = false,
  sportsCategory = false,
  sports = false,
  pin = false,
  checkData,
  setCheckData,
  brands = false,
  categories = false,
  sportsEcom = false,
  segments = false,
  groups = false,
  subCategories = false,
  offers = false,
  sizes = false,
  prices = false,
  specs = false,
  colors = false,
  discounts = false,
  shippings = false,
  taxes = false,
  advertisements = false,
  platforms = false,
  reviews = false,
  attributes = false,
  sellerList = false,
}) => {
  const navigate = useNavigate();

  const editCoachProfile = (item) => {
    let info = item.extraInfo[0];

    navigate(`/coaching/editprofile?slug=${info.slug}&id=${info.id}`);
  };

  const editAcademyProfile = (item) => {
    let info = item.extraInfo[0];
    navigate(`/academy/editprofile?slug=${info.slug}&id=${info.id}`);
  };
  const editLanguagePage = (item) => {
    navigate(`/master/language/list/edit?id=${item.id[0]?.content}`);
  };
  const editAgeGroupPage = (item) => {
    navigate(`/master/agegroup/list/edit?id=${item.id[0]?.content}`);
  };
  const editGenderPage = (item) => {
    navigate(`/master/gender/list/edit?id=${item.id[0]?.content}`);
  };
  const editTimeSlotPage = (item) => {
    navigate(`/master/timeslot/list/edit?id=${item.id[0]?.content}`);
  };
  const editCoachingTypePage = (item) => {
    navigate(`/master/coachingtype/list/edit?id=${item.id[0]?.content}`);
  };
  const editSportsCategoryPage = (item) => {
    navigate(`/master/sportcategory/list/edit?id=${item.id[0]?.content}`);
  };
  const editSportsPage = (item) => {
    navigate(`/master/sport/list/edit?id=${item.id[0]?.content}`);
  };

  const editPinCodePage = (item) => {
    navigate(`/master/pincode/list/edit?id=${item.id[0]?.content}`);
  };

  const editBrandPage = (item) => {
    navigate(`/ecom/brand/list/edit?id=${item.id[0]?.content}`);
  };

  const editCategoryPage = (item) => {
    navigate(`/ecom/category/list/edit?id=${item.id[0]?.content}`);
  };

  const editSportsEcomPage = (item) => {
    navigate(`/ecom/sport/list/edit?id=${item.id[0]?.content}`);
  };

  const editSegmentEcomPage = (item) => {
    navigate(`/ecom/segment/list/edit?id=${item.id[0]?.content}`);
  };

  const editGroupEcomPage = (item) => {
    navigate(`/ecom/group/list/edit?id=${item.id[0]?.content}`);
  };

  const editSubCatEcomPage = (item) => {
    navigate(`/ecom/sub-category/list/edit?id=${item.id[0]?.content}`);
  };
  const editOfferEcomPage = (item) => {
    navigate(`/ecom/offer/list/edit?id=${item.id[0]?.content}`);
  };
  const editSizeEcomPage = (item) => {
    navigate(`/ecom/size/list/edit?id=${item.id[0]?.content}`);
  };
  const editPriceEcomPage = (item) => {
    navigate(`/ecom/price/list/edit?id=${item.id[0]?.content}`);
  };
  const editSpecEcomPage = (item) => {
    navigate(`/ecom/specification/list/edit?id=${item.id[0]?.content}`);
  };
  const editColorEcomPage = (item) => {
    navigate(`/ecom/color/list/edit?id=${item.id[0]?.content}`);
  };
  const editDiscountEcomPage = (item) => {
    navigate(`/ecom/discount/list/edit?id=${item.id[0]?.content}`);
  };
  const editShippingEcomPage = (item) => {
    navigate(`/ecom/shipping/list/edit?id=${item.id[0]?.content}`);
  };
  const editTaxEcomPage = (item) => {
    navigate(`/ecom/tax/list/edit?id=${item.id[0]?.content}`);
  };
  const editAdvEcomPage = (item) => {
    navigate(`/ecom/adv/list/edit?id=${item.id[0]?.content}`);
  };
  const editPlatformEcomPage = (item) => {
    navigate(`/ecom/platform/list/edit?id=${item.id[0]?.content}`);
  };
  const editReviewEcomPage = (item) => {
    navigate(`/ecom/review/list/edit?id=${item.id[0]?.content}`);
  };
  const editAttributeEcomPage = (item) => {
    navigate(`/ecom/attribute/list/edit?id=${item.id[0]?.content}`);
  };

  const gotoSellerProfile = (item) => {
    navigate(`/sellers/profile?id=${item.id[0]?.content}`);
  };

  const head2 = headingOfTable?.map((item) => {
    return {
      heading: item.heading.map((innerItem) => {
        switch (innerItem.type) {
          case "text":
            return (
              <Typography
                sx={{
                  fontWeight: "800",
                  fontSize: "14px",
                  color: headingTextColor ? headingTextColor : "#171A1F",
                  fontStyle: "normal",
                  fontFamily: "Avenir",

                  whiteSpace: "nowrap",
                }}
                key={item.key}
              >
                {innerItem?.content}
              </Typography>
            );

          case "image":
            return (
              <>
                <img alt="img_here" src={innerItem?.content} />
              </>
            );

          case "ButtonWithImg":
            return (
              <>
                <CustomButtonComponent
                  variant="text"
                  onClick={() => {
                    if (item.sort) setKeyOfSort(item.key);
                  }}
                  sx={{ padding: "0px", margin: "0px" }}
                  key={item.key}
                >
                  <img alt="img_here" src={innerItem?.content} />
                </CustomButtonComponent>
              </>
            );
          case "ButtonWithText":
            return <Button key={item.key}>{innerItem?.content}</Button>;
          case "icon":
            return <img alt="img_here" src={innerItem?.content} />;
          default:
            return null;
        }
      }),
      key: item.key,
    };
  });

  const body2 = bodyOfTable?.map((item) => {
    const mapInnerItems = (innerItem) => {
      switch (innerItem.type) {
        case "text":
          return (
            <Typography
              sx={{
                fontWeight: "800",
                fontSize: "14px",
                color: tableBodyTextColor ? tableBodyTextColor : "#171A1F",
                fontStyle: "normal",
                fontFamily: "Avenir",
              }}
            >
              {innerItem?.content}
            </Typography>
          );
        case "ButtonWithText":
          return (
            <CustomButtonComponent key={item.key} variant="contained">
              {innerItem?.content}
            </CustomButtonComponent>
          );

        case "image":
          return (
            <>
              <img
                style={{ width: "32px", height: "32px", borderRadius: "20px" }}
                alt="img_here"
                src={innerItem?.content}
              />
            </>
          );

        case "icon":
          return <img alt="img_here" src={innerItem?.content} />;
        case "ButtonWithImg":
          return (
            <IconButton
              sx={{
                marginX: "1px",
              }}
              onClick={() => {
                if (editLanguage) editLanguagePage(item);
                if (ageGroup) editAgeGroupPage(item);
                if (coachingType) editCoachingTypePage(item);
                if (editGender) editGenderPage(item);
                if (timeSlot) editTimeSlotPage(item);
                if (sportsCategory) editSportsCategoryPage(item);
                if (sports) editSportsPage(item);
                if (coachList) editCoachProfile(item);
                if (academyList) editAcademyProfile(item);
                if (pin) editPinCodePage(item);
                if (brands) editBrandPage(item);
                if (categories) editCategoryPage(item);
                if (sportsEcom) editSportsEcomPage(item);
                if (segments) editSegmentEcomPage(item);
                if (groups) editGroupEcomPage(item);
                if (subCategories) editSubCatEcomPage(item);
                if (offers) editOfferEcomPage(item);
                if (sizes) editSizeEcomPage(item);
                if (prices) editPriceEcomPage(item);
                if (specs) editSpecEcomPage(item);
                if (colors) editColorEcomPage(item);
                if (discounts) editDiscountEcomPage(item);
                if (shippings) editShippingEcomPage(item);
                if (taxes) editTaxEcomPage(item);
                if (advertisements) editAdvEcomPage(item);
                if (platforms) editPlatformEcomPage(item);
                if (reviews) editReviewEcomPage(item);
                if (attributes) editAttributeEcomPage(item);
              }}
            >
              <img alt="img_here" src={innerItem?.content} />
            </IconButton>
          );
        case "verifiedDetails":
          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (modalOpen) {
                    setHandleAcademyClick(item);
                    setHandleOpen(true);
                  }
                  if (sellerList) {
                    gotoSellerProfile(item);
                  }
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "175px" }}
                >
                  <Avatar
                    alt="Remy Sharp"
                    sx={{
                      width: "32px",
                      height: "32px",
                      bgcolor: "deepOrange[500]",
                    }}
                  />
                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                      {innerItem?.content.name}
                    </Typography>
                    <Typography sx={{ fontWeight: "100", fontSize: "12px" }}>
                      {innerItem?.content.phoneNumber}
                    </Typography>
                  </Box>
                </Box>
                {innerItem?.content.verified ? (
                  <VerifiedIcon
                    sx={{
                      color: "green",
                      width: "28px",
                      height: "28px",
                      marginLeft: "30px",
                    }}
                  />
                ) : (
                  <Box marginLeft="58px"></Box>
                )}
              </Box>
            </>
          );
        case "email":
          return (
            <Typography sx={{ color: "#1F7BC2" }}>
              <Link
                href={`mailto:${innerItem?.content}`}
                sx={{ color: "#1F7BC2", textDecoration: "underline #1F7BC2" }}
              >
                {innerItem?.content}
              </Link>
            </Typography>
          );
        case "status":
          return (
            <>
              {innerItem?.content?.toLowerCase() === "active" && (
                <Typography sx={{ color: "#1EE1B1" }}>Active</Typography>
              )}
              {innerItem?.content?.toLowerCase() === "pending" && (
                <Typography sx={{ color: " #F8CB0E" }}>Pending</Typography>
              )}
              {innerItem?.content?.toLowerCase() === "inactive" && (
                <Typography sx={{ color: "#EC5347" }}>Inactive</Typography>
              )}
              {innerItem?.content?.toLowerCase() === "deleted" && (
                <Typography sx={{ color: "#EC5347" }}>deleted</Typography>
              )}
            </>
          );
        case "rating":
          return (
            <>
              {innerItem?.content < 4.5 ? (
                <Typography sx={{ color: "#F8CB0E" }}>
                  {innerItem?.content}
                </Typography>
              ) : (
                <Typography sx={{ color: "#1EE1B1" }}>
                  {innerItem?.content}
                </Typography>
              )}
            </>
          );
        default:
          return innerItem?.content;
      }
    };

    return Object.fromEntries(
      Object.entries(item).map(([key, value]) => [
        key,
        value.map(mapInnerItems),
      ])
    );
  });

  return (
    <CustomTableComponent
      checkData={checkData}
      setCheckData={setCheckData}
      headingOfTable={head2}
      bodyOfTable={body2}
      headingRowColor={headingRowColor}
      tableBodyColor={tableBodyColor}
    />
  );
};

export default DataInsertIntoTable;
