import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrowIcon from "../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomPagination from "../../Components/CustomPagination";
import DataInsertIntoTable from "../../Components/CustomTableComponent/dataInsertIntoTable";
import { styles, tableHead } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  coachDeleteAPI,
  coachFilterAPI,
  coachListAPI,
  coachSearchAPI,
  coachStatusUpdateAPI,
  deleteCoach,
  updateCoachStatus,
} from "../../Redux/slice/coachList";
import editIcon from "../../Assets/Icons/Drawer/editIcon.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { downloadCSV } from "../../utils/utils";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSearchParams } from "react-router-dom";
import Filter from "../../Components/Customfilter/Filter";

const CoachingPage = () => {
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [keyOfSort, setKeyOfSort] = useState("priority");
  const [searchParams] = useSearchParams();
  const page = parseInt(searchParams.get("page")) 
  const [currentPage, setCurrentPage] = useState(page||1);
  const [selectedItems, setSelectedItems] = useState([]);


  const coachesState = useSelector((state) => state.coachList.data);
  const [filterOpen, setFilterOpen] = useState(false);

  const totalItems = coachesState?.pageInfo?.itemCount;
  const totalPages = coachesState?.pageInfo?.pageCount;
  const itemsPerPage = coachesState?.pageInfo?.perPage;

  const head = tableHead;

  const handleCoachProfilePage = (slug) => {
    window.open(`${process.env.REACT_APP_COACH_PROFILE_BASE_URL}/${slug}`, "_blank");
  };

  const handleExport = () => {
    const getAddreStr = (address) =>
      `${address?.address} ${address?.city}; ${address?.state}`;

    const csv = coachesState?.data?.map((row) => {
      return {
        id: row?._id,
        slugUser: row?.slug,
        slugCoach: row?.coachProfile?.slug,
        name: row?.fullName,
        phoneNumber: row?.coachProfile?.phoneNumber,
        email: row?.email,
        sports: row.coachProfile?.sports?.map((sport) => sport.name).join("; "),
        totalNoBookings: "NA",
        profileVisits: "NA",
        address: getAddreStr(row?.address),
        addresses: row?.coachProfile?.addresses
          ?.map((address) => getAddreStr(address))
          .join("; "),
        status: row.coachProfile?.status,
        rating: "4.5",
      };
    });
    downloadCSV(csv, "coaches");
  };

  const handleDelete = async () => {
    for (let slug of selectedItems) {
      dispatch(deleteCoach({ slug: slug }));
      dispatch(coachDeleteAPI({ slug: slug }));
    }
  };

  useEffect(() => {
    const handleStatus = (slug) => (event) => {
      dispatch(updateCoachStatus({ slug, status: event?.target?.checked }));
      dispatch(
        coachStatusUpdateAPI({ slug, body: { status: event?.target?.checked } })
      );
    };
    if (coachesState) {
      let arr = coachesState?.data?.map((coach) => {
        return {
          actions: [
            {
              content: (
                <Switch
                  onChange={handleStatus(coach?.coachProfile?.slug)}
                  checked={coach?.coachProfile?.active}
                />
              ),
            },
            {
              content: (
                <VisibilityOutlinedIcon
                  sx={{ color: "primary.main" }}
                  onClick={() =>
                    handleCoachProfilePage(coach?.coachProfile?.slug)
                  }
                />
              ),
            },
            { type: "ButtonWithImg", content: editIcon },
          ],
          coach: [
            {
              type: "verifiedDetails",
              content: {
                img: editIcon,
                name: coach?.fullName,
                phoneNumber: coach?.coachProfile?.phoneNumber,
                verified: true,
              },
            },
          ],
          slug: [{ type: "text", content: coach?.coachProfile?.slug }],
          sports: [
            {
              type: "text",
              content: coach?.coachProfile?.sports[0]?.name || "NA",
            },
          ],
          totalNoBookings: [{ type: "text", content: "NA" }],
          emailID: [{ type: "email", content: coach?.email }],
          profileVisits: [{ type: "text", content: "NA" }],
          address: [{ type: "text", content: coach?.address?.city || "NA" }],
          status: [
            {
              type: "status",
              content: coach?.coachProfile?.deleted
                ? "DELETED"
                : coach?.coachProfile?.active
                ? "ACTIVE"
                : coach?.coachProfile?.status,
            },
          ],
          rating: [{ type: "rating", content: "4.5" }],
          extraInfo: [
            {
              id: coach?._id,
              slug: coach?.coachProfile?.slug,
              userSlug: coach?.slug,
            },
          ],
        };
      });
      setCoaches(arr);
      setLoading(false);
    }
  }, [coachesState, dispatch]);

  useEffect(() => {
    let q = searchParams.get("q");
    console.log("Here is the query", q);
    if (q) {
      dispatch(coachSearchAPI({ search: q, page: currentPage }));
    } else {
      dispatch(coachListAPI({ page: currentPage }));
    }
  }, [dispatch, currentPage, searchParams]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (!coaches || coaches.length === 0) {
    return (
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "red",
          fontFamily: "Avenir",
          fontSize: "40px",
          alignItems: "center",
        }}
      >
        No data available
      </Typography>
    );
  }
  const sortedBody = coaches.slice()?.sort((a, b) => {
    const nameA = a[keyOfSort]?.content;
    const nameB = b[keyOfSort]?.content;

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilter = (filter) => {
    dispatch(coachFilterAPI(filter));
  };

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Coach List</Box>
          <Box sx={styles.headingButtonBox}>
            <Button
              sx={styles.filterButtonBox}
              onClick={() => setFilterOpen(true)}
            >
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox} onClick={handleExport}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.pagesDetailMainBox}>
          <Typography>
            <span style={{ color: "#171A1F", fontWeight: "700" }}>
              {coaches.length}
            </span>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>
              in total
            </span>
          </Typography>

          <Typography sx={{ marginLeft: "70px" }}>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
            <span style={styles.pagesDetailNumber}>
              {(currentPage - 1) * itemsPerPage}
            </span>
            <span style={styles.pagesDetailText}>to</span>
            <span style={styles.pagesDetailNumber}>
              {currentPage * itemsPerPage}
            </span>
            <span style={styles.pagesDetailText}>of</span>
            <span style={styles.pagesDetailNumber}>{totalItems}</span>
            <span style={styles.pagesDetailText}>results</span>
          </Typography>

          <Box mt={2} textAlign="center" ml="auto">
            {selectedItems.length > 0 ? (
              <IconButton
                onClick={handleDelete}
                aria-label="delete"
                sx={{ color: "red" }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          pl: "6px",
          display: "flex",
          //overflowX: "hidden",
          // width : "calc(99vw - 310px)" ,
          overflowX: "auto",
          whiteSpace: "wrap",
        }}
      >
        <DataInsertIntoTable
          checkData={selectedItems}
          setCheckData={setSelectedItems}
          headingOfTable={head}
          bodyOfTable={coaches}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
          coachList={true}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>
      {filterOpen && (
        <Filter
          filterAction={handleFilter}
          onClose={() => setFilterOpen(false)}
        />
      )}
    </>
  );
};

export default CoachingPage;
