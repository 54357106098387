import React from "react";
import { styles } from "./styles";
import { Box, Typography, IconButton, Switch } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import AddIcon from "@mui/icons-material/Add";
import OfferFormField from "./OfferTextformFieldlist";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { offerDetailFormSchema } from "../../../../Schemas";
import { editeOfferAPI } from "../../../../Redux/slice/offerList";
import { useDispatch } from "react-redux";

const CreateOfferDetails = () => {
  const dispatch = useDispatch();
  const initialValues = {
    offerName: "",
    store: "",
    description: "",
    priority: "",
    icon: "",
    offerType: "",
    ctaText: "",
    deepLink: "",
    price: "",
    oldPrice: "",
    discountPercentage: "",
    status: false,
  };

  const formik = CustomFormikHook(initialValues, offerDetailFormSchema);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await formik.validateForm();
      formik.handleSubmit();
      const finalResponse = await dispatch(editeOfferAPI(formik.values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography sx={styles.title}>Edit Offer</Typography>
          <Box sx={styles.buttonContainer}>
            <CustomButtonComponent sx={styles.button}>
              <Typography
                sx={{ textTransform: "capitalize", fontWeight: "500" }}
              >
                Filters
              </Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </CustomButtonComponent>
            <CustomButtonComponent sx={styles.exportButton}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography
                sx={{ textTransform: "capitalize", fontWeight: "500" }}
              >
                Export
              </Typography>
            </CustomButtonComponent>
            <IconButton sx={styles.addButton}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.createFormBox}>
        <OfferFormField
          label={"Offer Name"}
          name={"offerName"}
          placeholder={"Enter Offer Name"}
          handleChange={formik.handleChange}
          value={formik.values.offerName}
          errors={formik.errors.offerName}
          touched={formik.touched.offerName}
        />
        <OfferFormField
          label={"store"}
          name={"store"}
          placeholder={"Enter store"}
          handleChange={formik.handleChange}
          value={formik.values.store}
          errors={formik.errors.store}
          touched={formik.touched.store}
        />
        <OfferFormField
          label={"description"}
          name={"description"}
          placeholder={"Enter description"}
          handleChange={formik.handleChange}
          value={formik.values.description}
          errors={formik.errors.description}
          touched={formik.touched.description}
        />
        <OfferFormField
          label={"Priority"}
          name={"priority"}
          placeholder={"Enter Priority"}
          handleChange={formik.handleChange}
          value={formik.values.priority}
          errors={formik.errors.priority}
          touched={formik.touched.priority}
        />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ width: "95%" }}>
            <OfferFormField
              label={"Icon"}
              name={"icon"}
              placeholder={"eg. http://staticsportkacks.slkahfhqhf73yfhweics9"}
              handleChange={formik.handleChange}
              value={formik.values.icon}
              errors={formik.errors.icon}
              touched={formik.touched.icon}
            />
          </Box>
          <CustomButtonComponent
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
          </CustomButtonComponent>
        </Box>

        <OfferFormField
          label={"Offer Type"}
          name={"offerType"}
          placeholder={"eg. Product"}
          handleChange={formik.handleChange}
          value={formik.values.offerType}
          errors={formik.errors.offerType}
          touched={formik.touched.offerType}
        />

        <OfferFormField
          label={"CTA Text"}
          name={"ctaText"}
          placeholder={"Grab Deal"}
          handleChange={formik.handleChange}
          value={formik.values.ctaText}
          errors={formik.errors.ctaText}
          touched={formik.touched.ctaText}
        />

        <OfferFormField
          label={"deepLink"}
          name={"deepLink"}
          placeholder={"eg. http://lenskart.com/"}
          handleChange={formik.handleChange}
          value={formik.values.deepLink}
          errors={formik.errors.deepLink}
          touched={formik.touched.deepLink}
        />

        <OfferFormField
          label={"Price"}
          name={"price"}
          placeholder={"Enter Price"}
          handleChange={formik.handleChange}
          value={formik.values.price}
          errors={formik.errors.price}
          touched={formik.touched.price}
        />
        <OfferFormField
          label={"Old Price"}
          name={"oldPrice"}
          placeholder={"Enter Old Price"}
          handleChange={formik.handleChange}
          value={formik.values.oldPrice}
          errors={formik.errors.oldPrice}
          touched={formik.touched.oldPrice}
        />
        <OfferFormField
          label={"Discount Percentage"}
          name={"discountPercentage"}
          placeholder={"Enter Discount Percentage"}
          handleChange={formik.handleChange}
          value={formik.values.discountPercentage}
          errors={formik.errors.discountPercentage}
          touched={formik.touched.discountPercentage}
        />

        <Box sx={{ marginTop: "30px" }}>
          <Typography sx={styles.status}>Status</Typography>
          <Switch
            checked={formik.values.status}
            onChange={() =>
              formik.setFieldValue("status", !formik.values.status)
            }
          />
        </Box>

        <Box sx={styles.formButtonMainBox}>
          <CustomButtonComponent
            variant="contained"
            sx={styles.formUpdateButton}
            onClick={handleSubmit}
          >
            <BorderColorOutlinedIcon sx={styles.formUpdateButtonIcon} />
            Update
          </CustomButtonComponent>
          <CustomButtonComponent
            variant="contained"
            sx={styles.formResetButton}
            onClick={() => console.log("Reset Offer by the Users")}
          >
            <RefreshOutlinedIcon sx={styles.formResetButtonIcon} />
            Reset
          </CustomButtonComponent>
        </Box>
      </Box>
    </>
  );
};

export default CreateOfferDetails;
