import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SportsIcon from "@mui/icons-material/Sports";
import StadiumIcon from "@mui/icons-material/Stadium";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

export const subMenuItems = {
    "E-commerce Panel": [
        {
            label: "Store",
            subMenu: [
                {
                    label: "Create",
                    link: "/ecommercepanel/store/editstore",
                    icon: AddIcon,
                },
                {
                    label: "List",
                    link: "/ecommercepanel/store/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Offer",
            subMenu: [
                {
                    label: "Create",
                    link: "/ecommercepanel/offer/create",
                    icon: AddIcon,
                },
                {
                    label: "List",
                    link: "/ecommercepanel/offer/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Category",
            subMenu: [
                {
                    label: "Create",
                    link: "/ecommercepanel/category/editcategory",
                    icon: AddIcon,
                },
                {
                    label: "List",
                    link: "/ecommercepanel/category/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Products",
            subMenu: [
                {
                    label: "List",
                    link: "/ecommercepanel/product/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
    ],
    
    "Ecom-data": [ 
        
        {
        label: "Brand",
        link: "/ecom/brand",
        subMenu: [
            { label: "Create", link: "/ecom/brand/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/brand/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    
    {
        label: "Brand Advertisement",
        link: "/ecom/adv",
        subMenu: [
            { label: "Create", link: "/ecom/adv/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/adv/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Platform",
        link: "/ecom/platform",
        subMenu: [
            { label: "Create", link: "/ecom/platform/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/platform/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },


    {
        label: "Category",
        link: "/ecom/category",
        subMenu: [
            { label: "Create", link: "/ecom/category/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/category/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    
    {
        label: "Sports",
        link: "/ecom/sport",
        subMenu: [
            { label: "Create", link: "/ecom/sport/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/sport/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },


    {
        label: "Sub-Category",
        link: "/ecom/sub-category",
        subMenu: [
            { label: "Create", link: "/ecom/sub-category/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/sub-category/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },
    
    {
        label: "Segments",
        link: "/ecom/segment",
        subMenu: [
            { label: "Create", link: "/ecom/segment/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/segment/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Groups",
        link: "/ecom/group",
        subMenu: [
            { label: "Create", link: "/ecom/group/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/group/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Return Reasons",
        link: "/ecom/return-reasons",
        subMenu: [
            { label: "Create", link: "/ecom/return-reasons/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/return-reasons/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Offers",
        link: "/ecom/offer",
        subMenu: [
            { label: "Create", link: "/ecom/offer/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/offer/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Size",
        link: "/ecom/size",
        subMenu: [
            { label: "Create", link: "/ecom/size/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/size/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Attributes",
        link: "/ecom/attribute",
        subMenu: [
            { label: "Create", link: "/ecom/attribute/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/attribute/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Price",
        link: "/ecom/price",
        subMenu: [
            { label: "Create", link: "/ecom/price/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/price/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Specifications",
        link: "/ecom/specification",
        subMenu: [
            { label: "Create", link: "/ecom/specification/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/specification/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Color",
        link: "/ecom/color",
        subMenu: [
            { label: "Create", link: "/ecom/color/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/color/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Discount",
        link: "/ecom/discount",
        subMenu: [
            { label: "Create", link: "/ecom/discount/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/discount/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Shipping Methods",
        link: "/ecom/shipping",
        subMenu: [
            { label: "Create", link: "/ecom/shipping/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/shipping/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Reviews",
        link: "/ecom/review",
        subMenu: [
            { label: "Create", link: "/ecom/review/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/review/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    
    {
        label: "Product Tax Codes",
        link: "/ecom/tax",
        subMenu: [
            { label: "Create", link: "/ecom/tax/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/tax/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },

    {
        label: "Collection",
        link: "/ecom/collection",
        subMenu: [
            { label: "Create", link: "/ecom/collection/create", icon: AddIcon },
            {
                label: "List",
                link: "/ecom/collection/list",
                icon: FormatListBulletedIcon,
            },
        ],
        icon: HomeIcon,
        moreOptions: true,
    },
    
],
    "website-data": [
        {
            label: "Language",
            link: "/master/language",
            subMenu: [
                { label: "Create", link: "/master/language/create", icon: AddIcon },
                {
                    label: "List",
                    link: "/master/language/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Age Group",
            link: "/master/agegroup",
            subMenu: [
                { label: "Create", link: "/master/agegroup/create", icon: AddIcon },
                {
                    label: "List",
                    link: "/master/agegroup/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Coaching Type",
            link: "/master/coachingtype",
            subMenu: [
                { label: "Create", link: "/master/coachingtype/create", icon: AddIcon },
                {
                    label: "List",
                    link: "/master/coachingtype/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Gender",
            link: "/master/gender",
            subMenu: [
                { label: "Create", link: "/master/gender/create", icon: AddIcon },
                {
                    label: "List",
                    link: "/master/gender/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Time Slot",
            link: "/master/timeslot",
            subMenu: [
                { label: "Create", link: "/master/timeslot/create", icon: AddIcon },
                {
                    label: "List",
                    link: "/master/timeslot/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Sport Category",
            link: "/master/sportcategory",
            subMenu: [
                {
                    label: "Create",
                    link: "/master/sportcategory/create",
                    icon: AddIcon,
                },
                {
                    label: "List",
                    link: "/master/sportcategory/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Sport",
            link: "/master/sport",
            subMenu: [
                {
                    label: "Create",
                    link: "/master/sport/create",
                    icon: AddIcon,
                },
                {
                    label: "List",
                    link: "/master/sport/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "PinCode",
            link: "/master/pincode",
            subMenu: [
                {
                    label: "Create",
                    link: "/master/pincode/create",
                    icon: AddIcon,
                },
                {
                    label: "List",
                    link: "/master/pincode/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
    ],
};
export const menuItems = [
    {
        label: "Dashboard",
        link: "/dashboard",
        path: "/dashboard",
        icon: HomeIcon,
        moreOptions: false,
    },
    {
        label: "E-commerce Panel",
        path: "/ecommercepanel",
        icon: ShoppingCartIcon,
        moreOptions: true,
        subMenu: subMenuItems["E-commerce Panel"],
    },
    {
        label: "Ecom-data",
        path: "/ecom-data",
        icon: ShoppingCartIcon,
        moreOptions: true,
        subMenu: subMenuItems["Master"],
    },
    {
        label: "website-data",
        path: "/master",
        icon: ShoppingCartIcon,
        moreOptions: true,
        subMenu: subMenuItems["Master"],
    },
    {
        label: "sellers",
        link: "/sellers",
        path: "/sellers",
        icon: SportsIcon,
        moreOptions: false,
    },
    {
        label: "Coaching",
        link: "/coaching",
        path: "/coaching",
        icon: SportsIcon,
        moreOptions: false,
    },
    {
        label: "Academy",
        link: "/academy",
        path: "/academy",
        icon: StadiumIcon,
        moreOptions: false,
    },
    {
        label: "Users",
        link: "/users",
        path: "/users",
        icon: PeopleAltIcon,
        moreOptions: false,
    },
    {
        label: "User Profile",
        link: "/userprofile",
        path: "/userprofile",
        icon: AccountCircleIcon,
        moreOptions: false,
    },
    {
        label: "Settings",
        link: "/settings",
        path: "/settings",
        icon: SettingsIcon,
        moreOptions: false,
    },
];