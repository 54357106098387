import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Typography,
    Switch
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
  import AddIcon from "@mui/icons-material/Add";
  import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
  import CustomPagination from "../../../../Components/CustomPagination";
  import DataInsertIntoTable from "../../../../Components/CustomTableComponent/dataInsertIntoTable";
  import { styles, tableHead } from "./styles";
  import { useDispatch, useSelector } from "react-redux";
  import editIcon from "../../../../Assets/Icons/Drawer/editIcon.svg";
  import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
  import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
  import {
    attributeListAPI,
    attributeListDeleteAPI,
    attributeUpdateAPI , 
    updateStatus
  } from "../../../../Redux/slice/attributeEcom";
  import { downloadCSV } from "../../../../utils/utils";
  import moment from "moment"
  import { useSearchParams } from "react-router-dom";

  const AttributeListPage = () => {
    const [attributes, setAttributes] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [keyOfSort, setKeyOfSort] = useState("title");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedItems, setSelectedItems] = useState([]);
    const head = tableHead;
    const [searchParams]= useSearchParams() ;
    const attributeData = useSelector((state) => state.attributeEcomList.data);
    const pageInfo = useSelector((state) => state.attributeEcomList.page);
  
    const totalItems = pageInfo?.itemCount;
    const totalPages = pageInfo?.pageCount;
    const itemsPerPage = pageInfo?.perPage;
  
    const handleExport = () => {
      const csvData = attributeData?.map((row) => ({
        id: row?._id,
        title: row?.title,
        type: row?.type,
        inputType: row?.inputType,
        status: row?.status,
        createdAt: moment(row?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
        updatedAt: moment(row?.updatedAt).format("DD/MM/YYYY HH:mm:ss"),
      }));
      downloadCSV(csvData, "attributeData");
    };
  
    useEffect(() => {

      
    const handleStatus = (attr) => (event) => {
      console.log(event?.target?.checked);
     // dispatch(updateStatus({ id : attr.id , status: event?.target?.checked }));
      dispatch(attributeUpdateAPI({id : attr._id , formData : {name : attr.name , active : event?.target?.checked}})) ;
      dispatch(updateStatus({ id : attr._id , active : event?.target?.checked }));
    };


      if (attributeData) {
        let arr = attributeData?.map((attr) => {
          return {
            actions: [
              {
                content: (
                  <Switch
                    checked={attr?.active}
                    onChange={handleStatus(attr)}
                  />
                ),
              },

              {
                content: (
                  <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />
                ),
              },
              { type: "ButtonWithImg", content: editIcon },
            ],
            id: [{ type: "text", content: attr?._id }],
            title: [{ type: "text", content: attr?.title }],
            type: [{ type: "text", content: attr?.type }],
            inputType: [{ type: "text", content: attr?.inputType }],
            radioOptions: [{ type: "text", content : attr?.radioOptions?.map(v=>v).join(", ")}],
            options: [{ type: "text",  content : attr?.options?.map(v=>v).join(", ")}],
            status: [{ type: "text", content: attr?.status }],
            createdAt: [{ type: "text", content:  moment(attr?.createdAt).format("DD/MM/YYYY  HH:mm:ss") }],
            updatedAt: [{ type: "text", content:  moment(attr?.updatedAt).format("DD/MM/YYYY  HH:mm:ss") }],
          };
        });
        setAttributes(arr);
        setLoading(false);
      }
    }, [attributeData]);

    useEffect(() => {
      let q = searchParams.get("q");
      console.log("Here is the query", q);
        setLoading(true) ;
        dispatch(attributeListAPI({ page: currentPage , search : q}));
    }, [dispatch, currentPage , searchParams]);
  
    if (loading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
  
    if ((!attributes || attributes.length === 0) && !loading) {
      return (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "red",
            fontFamily: "Avenir",
            fontSize: "40px",
            alignItems: "center",
          }}
        >
          No data available
        </Typography>
      );
    }
  
    const sortedBody = attributes.slice().sort((a, b) => {
      const nameA = a[keyOfSort]?.content;
      const nameB = b[keyOfSort]?.content;
  
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
  
      return 0;
    });
  
    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };
  
    const handleDelete = async () => {
      try {
        let arr1 = [...attributes];
        arr1 = arr1.filter(
          (Object) => !selectedItems.includes(String(Object?.id[0].content))
        );
        setAttributes(arr1);
        for (let id of selectedItems) {
          dispatch(attributeListDeleteAPI({ id }));
        }
        setSelectedItems([]);
      } catch (error) {
        console.error("Error deleting:", error);
      }
    };
  
    return (
      <>
        <Box sx={styles.mainBox}>
          <Box sx={styles.headingMainBox}>
            <Box sx={styles.headingTextBox}>Attribute List</Box>
            <Box sx={styles.headingButtonBox}>
              <Button sx={styles.filterButtonBox}>
                <Typography sx={styles.filterTypoText}>Filters</Typography>
                <img src={DownArrowIcon} alt="downArrowIcon" />
              </Button>
              <Button sx={styles.exportButtonBox} onClick={handleExport}>
                <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
                <Typography sx={styles.exportTypoText}>Export</Typography>
              </Button>
              <IconButton sx={styles.addIconButtonBox}>
                <AddIcon
                  sx={{
                    color: "primary.light",
                    padding: "0px",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box sx={styles.pagesDetailMainBox}>
            <Typography>
              <span style={{ color: "#171A1F", fontWeight: "700" }}>
                {attributes.length}
              </span>
              <span style={{ color: "#6E7787", marginLeft: "6px" }}>
                in total
              </span>
            </Typography>
  
            <Typography sx={{ marginLeft: "70px" }}>
              <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
              <span style={styles.pagesDetailNumber}>
                {(currentPage - 1) * itemsPerPage}
              </span>
              <span style={styles.pagesDetailText}>to</span>
              <span style={styles.pagesDetailNumber}>
                {currentPage * itemsPerPage}
              </span>
              <span style={styles.pagesDetailText}>of</span>
              <span style={styles.pagesDetailNumber}>{totalItems}</span>
              <span style={styles.pagesDetailText}>results</span>
            </Typography>
  
            <Box textAlign="center" ml="auto">
              {selectedItems.length > 0 ? (
                <IconButton
                  onClick={handleDelete}
                  aria-label="delete"
                  sx={{ color: "red" }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            pl: "6px",
            overflowX: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <DataInsertIntoTable
            checkData={selectedItems}
            setCheckData={setSelectedItems}
            headingOfTable={head}
            bodyOfTable={attributes}
            headingRowColor={"teal"}
            setKeyOfSort={setKeyOfSort}
            headingTextColor={"#FFFFFF"}
            tableBodyColor={"white"}
            tableBodyTextColor={"#171A1F"}
            attributes={true}
          />
        </Box>
        <Box
          sx={{
            pl: "6px",
            marginTop: "20px",
          }}
        >
          <CustomPagination
            type={"text"}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
          />
        </Box>
      </>
    );
  };
  
  export default AttributeListPage;
  