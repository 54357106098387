import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { CATEGORY_URLS1 } from "../../constants";
import { toast } from 'sonner';

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};



export const ProductCategoryListAPI = createAsyncThunk("ProductCategoryListAPI", async ({page , search}) => {
  try {
    const response = await instance.get(`${CATEGORY_URLS1.LIST(page , search)}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});


export const ProductCategoryAllAPI = createAsyncThunk("ProductCategoryAllAPI", async () => {
  try {
    const response = await instance.get(`${CATEGORY_URLS1.DROP}`);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});


export const ProductCategoryDeleteAPI = createAsyncThunk(
  "ProductCategoryDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${CATEGORY_URLS1.DELETE(data.id)}`);
      toast.success('Category deleted successfully!');
      return response.data;
    } catch (error) {
      console.log("Error", error);
      toast.error('Failed to delete category.');
      return error;
    }
  }
);


export const ProductCategoryGetByIdAPI = createAsyncThunk(
  "ProductCategoryGetByIdAPI",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(CATEGORY_URLS1.EDIT(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);



export const ProductCategoryUpdateAPI = createAsyncThunk(
  "ProductCategoryUpdateAPI",
  async (data, { rejectWithValue }) => {
    console.log(data);
    try {
      const response = await instance.put(
        CATEGORY_URLS1.UPDATE(data.id),
        data.formData

      );
      toast.success('Category updated successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to update category.');
      return rejectWithValue(err);
    }
  }
);


export const ProductCategoryCreateAPI = createAsyncThunk(
  "ProductCategoryCreateAPI",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${CATEGORY_URLS1.CREATE}`, formData);
      // console.log(response);
      toast.success('Category created successfully!');
      return response;
    } catch (err) {
      console.log(err.message);
      toast.error('Failed to create category.');
      return rejectWithValue(err);
    }
  }
);


const ProductCategoryList = createSlice({
  name: "ProductCategoryList",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state?.data?.map((item) => {
        if (String(item._id) === String(action.payload.id)) {
          return {
            ...item,
            active : action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ProductCategoryListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(ProductCategoryListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
      state.page = action?.payload?.pageInfo;
    });
    builder.addCase(ProductCategoryAllAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.data;
    });
    builder.addCase(ProductCategoryListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { updateStatus } = ProductCategoryList.actions;
export default ProductCategoryList.reducer;
