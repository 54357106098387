import React, { useEffect, useState } from "react";
import { Box, Typography, MenuItem, Select, FormControl, InputLabel, TextField } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import { styles } from "./styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { ReviewCreateFormSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch, useSelector } from "react-redux";
import { reviewCreateAPI } from "../../../../Redux/slice/reviewEcom";
import { usersSearchAPI } from "../../../../Redux/slice/usersList";
import { productListAPI } from "../../../../Redux/slice/productEcom";
import CreateFormField from "../../CustomFormFields/CreateFormField";
import { useSearchParams } from "react-router-dom";
// Inside ReviewCreatePage component

import UserSearchDropdown from './UserSearchDropdown'; // Adjust the import path as needed

const ReviewCreatePage = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.productList.data);

  useEffect(() => {
    dispatch(productListAPI());
  }, [dispatch]);

  const initialValues = {
    productId: "",
    userId: "",
    score: "",
    review: "",
    userName : "" ,
  };

  const handleSubmit = async (values) => {
    try {
      await dispatch(reviewCreateAPI(values));
    } catch (error) {
      console.error(error);
    }
  };

  const formik = CustomFormikHook(initialValues, ReviewCreateFormSchema, handleSubmit);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Review</Typography>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
      <Box sx={{ width: "300px" }}>
        <Typography sx={{ fontSize: "14px", lineHeight: "24px" }}>
          Product <span style={{ color: "red" }}>*</span>
        </Typography>
      </Box>
        <FormControl fullWidth sx={{ mb: 2 }}>
          {/* <InputLabel id="product-select-label">Product</InputLabel> */}
          <Select
            labelId="product-select-label"
            id="product-select"
            name="productId"
            value={formik.values.productId}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.productId && formik.touched.productId)}
          >
            {products?.map((product, index) => (
              <MenuItem key={index} value={product._id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <UserSearchDropdown
           formik = {formik}
        />

        <CreateFormField
          label={"Score"}
          name="score"
          placeholder={"Enter score"}
          handleChange={formik.handleChange}
          value={formik.values.score}
          errors={formik.errors.score}
          touched={formik.touched.score}
        />

        <CreateFormField
          label={"Review"}
          name="review"
          placeholder={"Enter review"}
          handleChange={formik.handleChange}
          value={formik.values.review}
          errors={formik.errors.review}
          touched={formik.touched.review}
        />

        <Box sx={{ marginTop: "38px" }}></Box>

        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormUpdateButton}
          onClick={formik.handleSubmit}
        >
          <BorderColorOutlinedIcon sx={styles.editGenderFormUpdateButtonIcon} />
          Create
        </CustomButtonComponent>
        <CustomButtonComponent
          variant="contained"
          sx={styles.editGenderFormResetButton}
          onClick={() => formik.resetForm()}
        >
          <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
          Reset
        </CustomButtonComponent>
      </Box>
    </>
  );
};

export default ReviewCreatePage;
