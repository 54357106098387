import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { sportsListAPI, sportsSearchAPI } from "../../Redux/slice/sportsList";
import { genderListAPI } from "../../Redux/slice/genderList";
import { ageGroupListAPI } from "../../Redux/slice/ageGroupList";
import useDebounce from "../../Hooks/debouncingHook";
import { coachingTypeListAPI } from "../../Redux/slice/coachingTypeList";
import useDetectOutsideClick from "../../Hooks/detectOutsideClickHook";
import { useSearchParams } from "react-router-dom";

const Filter = ({ onClose, filterAction }) => {
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedSports, setSelectedSports] = useState([]);
  const [sportSearch, setSportSearch] = useState("");
  const [selectedAge, setSelectedAge] = useState([]);
  const [selectedCoachingType, setSelectedCoachingType] = useState([]);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const ref = useRef();
  useDetectOutsideClick(ref, onClose);

  const initialState = useRef({
    genders: [],
    sports: [],
    sportSearch: "",
    ageGroups: [],
    coachingTypes: [],
  });

  const genders = useSelector((state) => state?.genderList?.data);
  const ageGroups = useSelector((state) => state?.ageGroupList?.data);
  const sports = useSelector((state) => state?.sportsList?.data?.data);
  const coachingTypes = useSelector((state) => state?.coachingTypeList?.data);

  const q = useDebounce(sportSearch, 300);

  useEffect(() => {
    dispatch(genderListAPI());
    dispatch(ageGroupListAPI());
    dispatch(coachingTypeListAPI());
  }, [dispatch]);

  useEffect(() => {
    dispatch(sportsSearchAPI({ search: q, page: 1 }));
  }, [q]);

  useEffect(() => {
    initialState.current = {
      genders: [],
      sports: [],
      sportSearch: "",
      ageGroups: [],
      coachingTypes: [],
    };
  }, []);

  const isChecked = (name, state) => {
    return state.includes(name);
  };

  const onChangeHandler = (e, setState) => {
    const { name, checked } = e.target;
    if (checked) {
      setState((prev) => [...prev, name]);
    } else {
      setState((prev) => prev.filter((g) => g !== name));
    }
  };

  const handleApply = () => {
    const payload = {
      keyword: searchParams.get("q") || "",
      page: parseInt(searchParams.get("page")) || 1,
      limit: 10,
      genders: selectedGenders,
      sports: selectedSports,
      coachingTypes: selectedCoachingType,
      ageGroups: selectedAge,
    };
    filterAction?.(payload);
  };

  const handleCancel = () => {
    setSelectedGenders(initialState.current.genders);
    setSelectedSports(initialState.current.sports);
    setSportSearch(initialState.current.sportSearch);
    setSelectedAge(initialState.current.ageGroups);
    setSelectedCoachingType(initialState.current.coachingTypes);
    onClose();
  };

  return (
    <Box
      ref={ref}
      sx={{
        overflowY: "scroll",
        padding: "20px",
        paddingY: "5px",
        fontSize: "16px",
        width: "300px",
        backgroundColor: "white",
        position: "fixed",
        right: 0,
        top: 80,
        height: "calc(100vh - 100px)",
        zIndex: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "&::-webkit-scrollbar": {
          width: "0.4em",
          height: "0.4em",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#555",
        },
      }}
    >
      <Box>
        <Typography variant="h6" sx={{ marginBottom: "20px" }}>
          Filters
        </Typography>

        <Box sx={{ marginBottom: "20px" }}>
          <Typography variant="body1">Coaching Type</Typography>
          {coachingTypes?.map((coach, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  name={coach.label}
                  checked={isChecked(coach.label, selectedCoachingType)}
                  onChange={(e) => onChangeHandler(e, setSelectedCoachingType)}
                />
              }
              label={coach.label.toLowerCase()}
            />
          ))}
        </Box>

        <Box sx={{ marginBottom: "20px" }}>
          <Typography variant="body1">Gender</Typography>
          {genders?.map((g, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  name={g.name}
                  checked={isChecked(g.name, selectedGenders)}
                  onChange={(e) => onChangeHandler(e, setSelectedGenders)}
                />
              }
              label={g.name.toLowerCase()}
            />
          ))}
        </Box>

        <Box sx={{ marginBottom: "20px" }}>
          <Typography variant="body1">Age Group</Typography>
          {ageGroups?.map((ag, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  name={ag.label}
                  checked={isChecked(ag.label, selectedAge)}
                  onChange={(e) => onChangeHandler(e, setSelectedAge)}
                />
              }
              label={ag.label.toLowerCase()}
            />
          ))}
        </Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Typography variant="body1">Sports</Typography>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search Sports"
            value={sportSearch}
            onChange={(e) => setSportSearch(e.target.value)}
            sx={{ marginBottom: "10px" }}
          />

          <Box
            sx={{
              maxHeight: "200px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4em",
                height: "0.4em",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#555",
              },
            }}
          >
            {sports?.map((sport, index) => (
              <FormControlLabel
                key={sport.id}
                control={
                  <Checkbox
                    name={sport.name}
                    checked={isChecked(sport.name, selectedSports)}
                    onChange={(e) => onChangeHandler(e, setSelectedSports)}
                  />
                }
                label={sport.name.toLowerCase()}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Button
          variant="contained"
          onClick={handleApply}
          sx={{ marginRight: "10px", color: "white" }}
        >
          Apply
        </Button>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default Filter;
