import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { PIN_URLS} from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

//CREATE
export const pinCreateAPI = createAsyncThunk(
  "pinCreate",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(`${PIN_URLS.CREATE}`, formdata);
      // console.log(response);;
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

// GET  
export const pinGetById = createAsyncThunk(
  "pinGetById",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.get(PIN_URLS.GET(data.id));
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


//UPDATE_STATUS
export const pinStatusUpdate = createAsyncThunk(
  "pinStatusUpdate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        PIN_URLS.UPDATE_STATUS(data.id),
        data.body
      );
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


//EDIT
export const pinEditAPI = createAsyncThunk(
  "pinEdit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await instance.put(
        PIN_URLS.EDIT(data.id),
        data.formdata
      );
      console.log(data.formdata);
      console.log(response);
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);


export const pinListAPI = createAsyncThunk("pinListAPI", async ({page}) => {
  const response = await instance.get(`${PIN_URLS.LIST(page)}`);
  console.log(response.data);
  return response.data;
});

export const pinSearchAPI = createAsyncThunk("pinSearchAPI", async ({search , page}) => {
  const response = await instance.get(`${PIN_URLS.SEARCH(search , page)}`);
  console.log(response.data);
  return response.data;
});

export const pinDeleteAPI = createAsyncThunk(
  "pinDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(`${PIN_URLS.LIST()}/${data.id}`);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);


const pin = createSlice({
  name: "pin",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      let data = state?.data?.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.status,
          };
        }
        return item;
      });
      state.data.data = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pinListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(pinListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload;
      console.log(action.payload);
    });
    builder.addCase(pinListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    builder.addCase(pinSearchAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload;
      console.log(action.payload);
    });
  },
});
export const { updateStatus } = pin.actions;
export default pin.reducer;
